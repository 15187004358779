<template>
    <div id="moat" class="rank-body pl-4">
      <div class="tier-labels">
            <div>High Performance</div>
            <div>Low Performance</div>
        </div>
        <div class="tier-bar">
            <div v-for="(tierInstance, idxTiers) in tiers" :key="idxTiers" :class="getTierClass(tierInstance)">
                <span>{{ tierInstance.name }}</span>
            </div>
        </div>            
        <div :id="valueGapRankChartDisplayIdName" class="rank-chart-display">
            <div></div>
            <div class="rank-chart-line-container">
              <div id="rank-chart-x-container">
                <img src="../../../assets/img/wRatings-X.png" alt="wRatings Admin" width="20" height="20" />
              </div>
              <div class="rank-chart-line"></div>
            </div>
        </div>
        <div class="rank-display">
            <div class="rank-display-title">{{ titleHtml }}</div>
            <div v-if="score != 0" class="score">{{ score }}</div>
            <div v-if="message != ''" class="rank-position">{{ message }}</div>
        </div>      
</div>
</template>

<script>
import RankBodyMixins from "../../../mixins/RankBodyMixins.vue";
import $ from "jquery";

export default {
  name: "MoatScoreRankBody",
  mixins: [RankBodyMixins],
  components: {},
  data() {
    return {
      title: "Value Gap",
    };
  },
  props: {
    moatRankChartDisplayIdName: {
      type: String,
      default: "value-gap-chart-display"
    },
  },
  computed: {
    valueGapRankChartDisplayContainer: function(){
      return $("#"+this.valueGapRankChartDisplayIdName);
    },
    valueGapRankChartDisplayIdName: function(){
      if (this.isModal){
        return "value-gap-rank-chart-display-container-modal";
      } else {
        return "value-gap-rank-chart-display-container";
      }
    },        
  },
  mounted() {
    //console.log("MoatScoreRankBody mounted");
    this.calculateHeight();
  },
  created() {},
  methods: {
    calculateHeight: function() {
      let heightPercent = (1 - this.score);
      let height = this.baseHeight * heightPercent;
      //console.log("calculateHeight score: " + this.score + " baseHeight: " + this.baseHeight + " height: " + height);       
      let rankLine = this.valueGapRankChartDisplayContainer.find(".rank-chart-line-container .rank-chart-line");
      rankLine.css("height", height + "px");
    },    
    getTierClass: function(tierInstance) {
      let className = "tier-block";
      if (tierInstance.name == this.tier){
        className += " selected"
      } 
      return className;
    },    
  },
  watch: {
    score: function() {
//      console.log("score " + this.score);
      this.calculateHeight();
    }    
  }
};
</script>
