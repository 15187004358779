<template>
  <div></div>
</template>
<script>
import $ from "jquery";

import axios from "axios";
import ChartMenu from "../views/menu/ChartMenu.vue";
import ChartDataTable from "../components/chart/ChartDataTable.vue";
import ChartCriteria from "../components/chart/ChartCriteria.vue";
import ChartCriteriaResults from "../components/chart/ChartCriteriaResults.vue";
import ViewMixins from "./ViewMixins.vue";
import Insights from "../modals/Insights.vue";

import AdminService from "../services/admin.service";
import ContentService from "../services/content.service";
import ChartService from "../services/chart.service";
import UserService from "../services/user.service";
import ErrorService from "../services/error.service";
import SurveyService from "../services/survey.service";
import ClientService from "../services/client.service";
import GoogleService from "../services/google.service";
import ReportService from "../services/report.service";
import Winners from "../services/chart/winners.service";
import PowerPointService from "../services/power.point.service";

import authHeader from "../services/auth-header";

import ContentMixins from "./ContentMixins.vue";
import GlobalMixins from "./GlobalMixins.vue";

import PrintAndCopyService from "../services/print.and.copy.service";
import html2canvas from "html2canvas";

import { reactive, toRaw } from 'vue'
export default {
  name: "ChartCompanyMixins",
  mixins: [ContentMixins, ViewMixins,GlobalMixins],
  components: {
    /* eslint-disable vue/no-unused-components */
    ChartMenu,
    ChartDataTable,
    ChartCriteria,
    ChartCriteriaResults,
    Insights
  },
  computed: {
    benchmarkResults: function() {
      return this.criteriaResultsCompanyList.find(x => x.type === "benchmark");
    },
    competitorResults: function() {
      return this.criteriaResultsCompanyList.find(x => x.type === "competitor");
    },
    clientResults: function() {
      return this.criteriaResultsCompanyList.find(x => x.type === "client");
    },
    chartType: function() {
      return ChartService.findChartTypeByName(this.chart.typeName);
    },
    disableSaveAsCardButton: function() {
      return this.isW5OUser || this.isInsiderUser;
    },
    disableAutoCreateGroupsOfTiersButton: function() {
      return this.isW5OUser || this.isInsiderUser;
    },
    
  },
  methods: {
    resetChartCriteria: function() {
      this.$refs.chartCriteria.resetChartCriteria();
    },
    updateClientCharts: function(value, message) {
      this.clientObject.clientCharts = [...value];
      ClientService.saveClient(this.clientObject);
      this.$root.setClientObject(this.clientObject);
      AdminService.displaySuccessMessage({ text: message });
      if (this.$refs['save-chart-confirmation'] != null) {
        this.$refs['save-chart-confirmation'].visible = false;
      }
    },
    processSavedChartCriteria(criteria) {
      let criteriaParameters = criteria.split("=");
      let defaultParameter = criteriaParameters[0];
      let defaultValue = criteriaParameters[1];
      // Process Custom Time Period Dates
      if (defaultParameter == "eChartType"){
        let eChartType = "";
        try {
          eChartType = JSON.parse(defaultValue);
        } catch (e) {
          eChartType = decodeURIComponent(defaultValue).replace(/\+/g, " ");
        }     
        this.$refs.chartCriteria.$data.eChartCriteria.eChartType = eChartType;   
        this.eChartCriteria.eChartType = eChartType;
      }      
      if (defaultParameter == "showLegend"){
        let showLegend = "";
        try {
          showLegend = JSON.parse(defaultValue);
        } catch (e) {
          showLegend = decodeURIComponent(defaultValue).replace(/\+/g, " ");
        }   
        this.$refs.chartCriteria.$data.eChartCriteria.showLegend = showLegend || (showLegend == "true");   
        this.eChartCriteria.showLegend = this.$refs.chartCriteria.$data.eChartCriteria.showLegend;
      }
      if (defaultParameter == "showLabels"){
        let showLabels = "";
        try {
          showLabels = JSON.parse(defaultValue);
        } catch (e) {
          showLabels = decodeURIComponent(defaultValue).replace(/\+/g, " ");
        }   
        this.$refs.chartCriteria.$data.eChartCriteria.showLabels = showLabels || (showLabels == "true");   
        this.eChartCriteria.showLabels = this.$refs.chartCriteria.$data.eChartCriteria.showLabels;
      }
      if (defaultParameter == "displayNumberOfResponses"){
        let displayNumberOfResponses = "";
        try {
          displayNumberOfResponses = JSON.parse(defaultValue);
        } catch (e) {
          displayNumberOfResponses = decodeURIComponent(defaultValue).replace(/\+/g, " ");
        }   
        this.$refs.chartCriteria.$data.eChartCriteria.displayNumberOfResponses = displayNumberOfResponses || (displayNumberOfResponses == "false");   
        this.eChartCriteria.displayNumberOfResponses = this.$refs.chartCriteria.$data.eChartCriteria.displayNumberOfResponses;
      }
      if (defaultParameter == "displayNumberOfResponsesForSecondChart"){
        let displayNumberOfResponsesForSecondChart = "";
        try {
          displayNumberOfResponsesForSecondChart = JSON.parse(defaultValue);
        } catch (e) {
          displayNumberOfResponsesForSecondChart = decodeURIComponent(defaultValue).replace(/\+/g, " ");
        }   
        this.$refs.chartCriteria.$data.eChartCriteria.displayNumberOfResponsesForSecondChart = displayNumberOfResponsesForSecondChart || (displayNumberOfResponsesForSecondChart == "false");   
        this.eChartCriteria.displayNumberOfResponsesForSecondChart = this.$refs.chartCriteria.$data.eChartCriteria.displayNumberOfResponsesForSecondChart;
      }
      if (defaultParameter == "angleText"){
        let angleText = "";
        try {
          angleText = JSON.parse(defaultValue);
        } catch (e) {
          angleText = decodeURIComponent(defaultValue).replace(/\+/g, " ");
        }   
        this.$refs.chartCriteria.$data.eChartCriteria.angleText = angleText || (angleText == "false");   
        this.eChartCriteria.angleText = this.$refs.chartCriteria.$data.eChartCriteria.angleText;
      }
      if (defaultParameter == "showGoalLine"){
        let showGoalLine = "";
        try {
          showGoalLine = JSON.parse(defaultValue);
        } catch (e) {
          showGoalLine = decodeURIComponent(defaultValue).replace(/\+/g, " ");
        }true
        this.$refs.chartCriteria.$data.eChartCriteria.showGoalLine = showGoalLine || (showGoalLine == "false");   
        this.eChartCriteria.showGoalLine = this.$refs.chartCriteria.$data.eChartCriteria.showGoalLine;
      }
      if (defaultParameter == "colorPaletteId"){
        let colorPaletteId = "";
        try {
          colorPaletteId = JSON.parse(defaultValue);
        } catch (e) {
          colorPaletteId = decodeURIComponent(defaultValue).replace(/\+/g, " ");
        }        
        if (!isNaN(colorPaletteId)) {
          this.$refs.chartCriteria.$data.eChartCriteria.colorPaletteId = Number(colorPaletteId);
          this.eChartCriteria.colorPaletteId = this.$refs.chartCriteria.$data.eChartCriteria.colorPaletteId;
        }        
      }
      if (defaultParameter == "custom_start_date") {
        try {
          let dateString = decodeURIComponent(defaultValue).replace(/\+/g, " ");      
          this.$refs.chartCriteria.$data.customStartDate = new Date(dateString).toLocaleDateString("en-US");
        } catch (e) {
          this.$refs.chartCriteria.$data.customStartDate = new Date().toLocaleDateString("en-US");
        }
      }
      if (defaultParameter == "custom_end_date") {
        try {
          let dateString = decodeURIComponent(defaultValue).replace(/\+/g, " ");      
          this.$refs.chartCriteria.$data.customEndDate = new Date(dateString).toLocaleDateString("en-US");
        } catch (e) {
          this.$refs.chartCriteria.$data.customEndDate = new Date().toLocaleDateString("en-US");
        }
      }
      // Processed Saved Cards
      if (defaultParameter == "comparative_saved_card") {
        let savedCard = this.$refs.chartCriteria.$data.saved_card_options.find(x => x.clientChartId == defaultValue);
        if (typeof savedCard != "undefined"){
            savedCard.checked = true;
            this.$refs.chartCriteria.$data.comparativeSavedCards.push(savedCard);
        }
      }
      // Process Saved Comparative Periods
      if (defaultParameter == "comparative_period") {
        let comparativePeriod = this.$refs.chartCriteria.$data.period_options.find(x => x.value == defaultValue);
        comparativePeriod.checked = true;
        this.$refs.chartCriteria.$data.comparativePeriods.push(comparativePeriod);
      }
      // Process Saved Comparative Surveys
      if (defaultParameter == "comparative_survey") {
        let comparativeSurvey = this.$refs.chartCriteria.$data.survey_options.find(x => x.value == defaultValue);
        comparativeSurvey.checked = true;
        this.$refs.chartCriteria.$data.comparativeSurveys.push(comparativeSurvey);
      }
      // Process Show Needs
      if (defaultParameter == "show_needs") {
        let showNeeds = this.$refs.chartCriteria.$data.show_needs_options.find(x => x.value == defaultValue);
        showNeeds.checked = true;
        this.$refs.chartCriteria.$data.showNeeds.push(showNeeds);
      }
      // Process Show Metrics
      if (defaultParameter == "show_metrics") {
        let showMetrics = this.$refs.chartCriteria.$data.show_metrics_options.find(x => x.value == defaultValue);
        showMetrics.checked = true;
        this.$refs.chartCriteria.$data.showMetrics.push(showMetrics);
      }
      
      // Process Saved Comparative Survey Groups
      if (defaultParameter == "comparative_survey_group") {
        let comparativeSurveyGroup = this.$refs.chartCriteria.$data.survey_group_options.find(x => x.value == defaultValue);
        if (typeof comparativeSurveyGroup != "undefined") {
          comparativeSurveyGroup.checked = true;
          this.$refs.chartCriteria.$data.comparativeSurveyGroups.push(comparativeSurveyGroup);
        }
      }
      // Process Saved Survey Locations
      if (defaultParameter == "surveyLocation") {
        let savedSurveyLocations;
        try {
          savedSurveyLocations = JSON.parse(defaultValue);
        } catch (e) {
          var decodedSurveyLocations = decodeURIComponent(defaultValue).replace(/\+/g, " ");
          savedSurveyLocations = decodedSurveyLocations.split(',');
        }        
        this.$refs.chartCriteria.$data.surveyLocation = [...savedSurveyLocations];
      }
      if (defaultParameter == "winnerTypeSelected") {
        let winnerType;
        try {
          JSON.parse(defaultValue);
        } catch (e) {
          winnerType = decodeURIComponent(defaultValue).replace(/\+/g, " ");
        }        
        this.$refs.chartCriteria.$data.winnerType = winnerType;
      }
      // Process Words
      if (defaultParameter == "words") {
        let savedWords;
        try {
          savedWords = JSON.parse(defaultValue);
        } catch (e) {
          var decodedSavedWords = decodeURIComponent(defaultValue).replace(/\+/g, " ");
           savedWords = decodedSavedWords.split(',');
        }        
        this.$refs.chartCriteria.$data.words = [...savedWords];
      }
      if (defaultParameter == "segments") {
        let currentSegments = this.$refs.chartCriteria.$data.segments;
        let savedSegments;
        try {
          savedSegments = JSON.parse(defaultValue);
        } catch (e) {
          var decodedDefaultValue = decodeURIComponent(defaultValue).replace(/\+/g, " ");
          savedSegments = $.parseJSON(decodedDefaultValue);
        }
        for (let currentSegmentsIndex = 0; currentSegmentsIndex < currentSegments.length; currentSegmentsIndex++) {
          let currentSegment = currentSegments[currentSegmentsIndex];
          for (let savedSegmentsIndex = 0; savedSegmentsIndex < savedSegments.length; savedSegmentsIndex++) {
            let savedSegment = savedSegments[savedSegmentsIndex];
            if (currentSegment.name == savedSegment.name) {
              currentSegment.values = savedSegment.values;
            }
          }
        }
      }
      let defaultCriteria = this.$refs.chartCriteria.findCriteriaByParameter(defaultParameter);
      if (typeof defaultCriteria != "undefined") {
        let isTimePeriod = defaultCriteria.parameterName == "period" ? true : false;
        let isDisplayType = defaultCriteria.parameterName == "display_type" ? true : false;
        let isMatchType = defaultCriteria.parameterName == "match_type" ? true : false;
        let isIncludePartialResponses = defaultCriteria.parameterName == "include_partial_responses" ? true : false;
        let isCustomTimePeriod = defaultCriteria.parameterName == "custom_time_period" ? true : false;
        let isShowPublicGroups = defaultCriteria.parameterName == "show_public_groups" ? true : false;
        let isUseEqualWeight = defaultCriteria.parameterName == "use_equal_weight" ? true : false;
        let isUseCompetitorExpecations = defaultCriteria.parameterName == "show_competitor_expectations" ? true : false;
        let isUsePricingPower = defaultCriteria.parameterName == "show_pricing_power" ? true : false;
        let isMetric = defaultCriteria.parameterName == "metric" ? true : false;
        let isRankingTier = defaultCriteria.parameterName == "ranking_tier" ? true : false;
        let isTextDirection = defaultCriteria.parameterName == "text_direction" ? true : false;
        let isRankCalculationType = defaultCriteria.parameterName == "rank_calculation_type" ? true : false;
        
        let isSurveyGroup = defaultCriteria.parameterName == "survey_group" ? true : false;
        let updateValue = isTimePeriod || isIncludePartialResponses;
        if (isTimePeriod) {
          this.$refs.chartCriteria.$data.period = defaultValue;
        }
        if (isMetric) {
          this.$refs.chartCriteria.$data.metric = defaultValue;
        }
        if (isRankingTier) {
          this.$refs.chartCriteria.$data.ranking_tier = defaultValue;
        }
        if (isTextDirection){
          this.$refs.chartCriteria.$data.textDirection = defaultValue;
        }
        if (isDisplayType) {
          this.$refs.chartCriteria.$data.displayType = defaultValue;
        }
        if (isMatchType) {
          this.$refs.chartCriteria.$data.matchType = defaultValue;
        }
        if (isCustomTimePeriod) {
          this.$refs.chartCriteria.$data.customTimePeriod = defaultValue;
        }
        if (isUseEqualWeight) {
          this.$refs.chartCriteria.$data.useEqualWeight = defaultValue;
        }
        if (isShowPublicGroups) {
          this.$refs.chartCriteria.$data.showPublicGroups = defaultValue;
          this.$refs.chartCriteria.$data.survey_group_options = this.$refs.chartCriteria.getSurveyGroupOptions();
        }
        if (isIncludePartialResponses) {
          this.$refs.chartCriteria.$data.includePartialResponses = defaultValue;
        }
        if (isUseCompetitorExpecations) {
          this.$refs.chartCriteria.$data.showCompetitorExpectations = defaultValue;
        }
        if (isUsePricingPower) {
          this.$refs.chartCriteria.$data.showPricingPower = defaultValue;
        }
        if (isRankCalculationType){
          this.$refs.chartCriteria.$data.rank_calculation_type = defaultValue;
        }
        if (isSurveyGroup) {
          this.$refs.chartCriteria.$data.surveyGroup = defaultValue;
        }

        this.$refs.chartCriteria.saveResultValue(defaultValue, defaultCriteria.name, defaultCriteria.parameterName, updateValue);
      }
    },
    processAutoGenerateChart() {
      if (this.autoGenerateChartRun) {
        if (typeof this.clientChart != "undefined") {
          if (typeof this.clientChart.criteria != "undefined" && this.clientChart.criteria != "") {
            let criteriaParameters = this.clientChart.criteria.split("&");
            for (let criteriaIndex = 0; criteriaIndex < criteriaParameters.length; criteriaIndex++) {
              let criteria = criteriaParameters[criteriaIndex];
              this.processSavedChartCriteria(criteria);
            }
          }
          this.$refs.chartCriteria.setSurveyCode();          
          this.displayMainChart();
        }
      }
    },
    hideChart() {
      var charts = $(".chart");
      charts.html("");
      charts.hide();
      if (charts.length >= 1 && charts.siblings().length >= 1) {
        charts.siblings().hide();
      }
      $(".chart-data").show();
      this.redrawChart = false;
    },
    showChart() {
      var charts = $(".chart");
      if (charts.length >= 1 && charts.siblings().length >= 1) {
        charts.siblings().show();
      }
      charts.show();
      this.redrawChart = true;
    },
    getChartCriteriaParams(chart, chartCriteria) {
      let params = this.isSavedCard() ? new URLSearchParams(chart.criteria) : chartCriteria.buildUrlParams(chart.clientId);
      
      // below 3 params added for Download Verbatims testing -- to be removed later -- once API encoporates ChartCriteriaParams
      params.append("surveyId", SurveyService.getSurveyId());
      params.append("userId", UserService.getUserId());
      params.append("clientTimePeriodId", chartCriteria.$data.period);
      
      return params; 
    },
    isSavedCard(){
      let useSaveParameters = typeof this.clientChart.criteria != undefined && this.clientChart.criteria != "" && this.autoGenerateChartRun;
      return useSaveParameters;
    },
    async displayChart(client_id) {
      if (client_id > 0) {
        let url = this.baseUrl + this.chart.dataSourceUrl;
        this.$root.processServerRequest();
        this.generatingChart = true;
        this.clientChart.chartTypeId = this.chartType.chartTypeId;
        this.clientChart.clientId = this.clientId;

        const params = this.getChartCriteriaParams(this.clientChart, this.$refs.chartCriteria);
        this.clientChart.criteria = JSON.stringify(params);
        
        if (typeof this.clientChart.clientTimePeriodId == "undefined" && typeof this.$refs.chartCriteria.$data.period != "undefined") {
          this.clientChart.clientTimePeriodId = this.$refs.chartCriteria.$data.period;
        } 

        if (typeof this.clientChart.displayType != undefined) {
          this.clientChart.displayType = this.$refs.chartCriteria.$data.displayType;
        } else {
          this.clientChart.displayType = "";
        }
        if (typeof this.saveClientChartObject.name == "undefined" || this.saveClientChartObject.name == "") {
          let chartTitle = ChartService.createDefaultSaveChartName(this.chart.title, this.clientChart.displayType);
          this.clientChart.name = chartTitle + " " + this.$refs.chartCriteria.getPeriodName(this.clientChart.clientTimePeriodId);
        }
        if (typeof this.saveClientChartObject != "undefined" && this.saveClientChartObject.displayType == "winners") {
          params.append("clientChartId", this.saveClientChartObject.clientChartId);
        }       
        if (this.isSavedCard()) {
          if (!params.toString().includes('show_pricing_power')){
            params.append("show_pricing_power", "Y");
          }
          this.$refs.chartCriteria.saveResultValue(this.$refs.chartCriteria.showPricingPower, "Show Willingness to Pay", "show_pricing_power", true);
          this.$refs.chartCriteria.saveResultValue(this.$refs.chartCriteria.customStartDate, "Custom Start Date", "custom_start_date", true);
          this.$refs.chartCriteria.saveResultValue(this.$refs.chartCriteria.customEndDate, "Custom End Date", "custom_end_date", true);
          params.append("isSavedChart", true);
          if (this.saveClientChartObject.chartTypeId == 3){
            let userParameter = params.get("user_id");
            if (typeof userParameter != "undefined" && userParameter != null && userParameter != "") {
              params.delete("user_id");
              params.append("user_id", UserService.getUserId());
            }
          }
        }
        this.autoGenerateChartRun = false;
        let nextClientChartLinkTimeout = Number(process.env.VUE_APP_NEXT_CLIENT_CHART_LINK_TIMEOUT);
        // eslint-disable-next-line no-console
        //console.log("nextClientChartLinkTimeout: " + nextClientChartLinkTimeout);
        await axios
          .post(url, params, { headers: await authHeader() })
          .then(response => this.displayChartCallback(response))
          .catch(error => {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("ChartCompanyMixins.displayChart", error, "chart", this);
          });
        let vm = this;
        setTimeout(() => {
          vm.generateNextClientChartLink();
        }, nextClientChartLinkTimeout);
      }
    },
    displayMainChart() {
      if (!this.$refs.chartCriteria.$data.chartForm.valid()) {
        ChartService.scrollToTop();
        return false;
      } else if (
        this.$refs.chartCriteria.$data.surveyGroup.length == 0 &&
        this.chart.typeName == "competitive-trends" &&
        this.$refs.chartCriteria.$data.displayType == "w-scores"
      ) {
        ChartService.scrollToTop();
        AdminService.displayErrorMessage({ text: "Please select at least one group", duration: -1 });
        return false;
      } else if (
        this.$refs.chartCriteria.$data.comparativePeriods.length == 0 &&
        this.chart.typeName == "profile-match" &&
        this.$refs.chartCriteria.$data.displayType == "time-period"
      ) {
        ChartService.scrollToTop();
        AdminService.displayErrorMessage({ text: "Please select at least one time period", duration: -1 });
        return false;
      } else if (
        this.$refs.chartCriteria.$data.comparativeSurveys.length == 0 &&
        this.chart.typeName == "profile-match" &&
        this.$refs.chartCriteria.$data.displayType == "survey"
      ) {
        ChartService.scrollToTop();
        AdminService.displayErrorMessage({ text: "Please select at least one survey", duration: -1 });
        return false;
      } else {
        GoogleService.logGenerateChart(this.chart.title, this.$refs.chartCriteria.$data.displayType, this.survey.name, this.autoGenerateChartRun, this);
        this.displayChart(this.clientId);
      }
    },
    setChartResults(companyResults) {
      this.benchmarkResults.list = [];
      this.competitorResults.list = [];
      this.clientResults.list = [];

      if (Object.prototype.hasOwnProperty.call(companyResults, "competitor")) {
        var companyList = companyResults.competitor;
        for (var i = 0; i < companyList.length; i++) {
          this.competitorResults.list.push(companyList[i]);
          let competitorCompany = companyList[i];
          var match = this.$refs.chartCriteria.$data.competitors.find(x => Number(x.companyId) === Number(competitorCompany.companyId));
          if (typeof match == "undefined") {
            var competitorOptionMatch = this.$refs.chartCriteria.$data.competitor_options.find(x => Number(x.companyId) === Number(competitorCompany.companyId));
            if (typeof competitorOptionMatch != "undefined") {
              competitorOptionMatch.checked = true;
            }

            this.$refs.chartCriteria.$data.competitors.push(competitorCompany);
            let value = JSON.parse(JSON.stringify(competitorCompany));
            value.checked = true;
            value.clientCompetitorsId = 0;
            value.clientId = this.clientId;
            delete value["active"];
            delete value["companyCode"];
            delete value["dateCreated"];
            delete value["dateModified"];
            delete value["sectorId"];
            delete value["sectorName"];
            delete value["numberOfResults"];
            delete value["isTopCompany"];

            this.$refs.chartCriteria.$data.values.push(value);
          }
        }
      }
      if (Object.prototype.hasOwnProperty.call(companyResults, "benchmark")) {
        var companyListBenchmark = companyResults.benchmark;
        for (var companyListIndex = 0; companyListIndex < companyListBenchmark.length; companyListIndex++) {
          this.benchmarkResults.list.push(companyListBenchmark[companyListIndex]);
          this.$refs.chartCriteria.addCompany(companyListBenchmark[companyListIndex]);
          $("#" + companyListBenchmark[companyListIndex].companyId).prop("checked", true);
        }
      }
      if (Object.prototype.hasOwnProperty.call(companyResults, "client")) {
        var companyListClient = companyResults.client;
        for (var clientCompanyListIndex = 0; clientCompanyListIndex < companyListClient.length; clientCompanyListIndex++) {
          this.clientResults.list.push(companyListClient[clientCompanyListIndex]);
        }
      }
    },
    drawSingleEmotionalNeedsName(needsData) {
      for (let indexRows = 0; indexRows < needsData.rows.length; indexRows++) {
        let chartDataRow = needsData.rows[indexRows].c;
        let value = chartDataRow[1].v.split(",");
        if (typeof value != "undefined") {
          chartDataRow[1].v = value[0];
        }
      }
      return needsData;
    },
    getReportPagesCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.reportPagesList = response.data.data;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    saveReportPageCallback(response) {},
    saveReportPageFileCallback(response) {},
    saveReportTableFileCallback(response) {},
    async saveReportPage(reportPageContent, reportTypeName, clientChartParam) {
      await ReportService.saveReportPage(reportPageContent, reportTypeName, clientChartParam, this.saveReportPageCallback, this);
    },
    updateInsights() {},
    getReportTableId(containerId) {
      return "dataTableVertical_" + containerId + "_wrapper";
    },
    getReportTableContent(containerId) {
      let reportTableId = this.getReportTableId(containerId);
      return this.getReportTableContentByTable(reportTableId);
    },
    getReportTableContentByTable(reportTableId) {
      let copyOfTable = $("#" + reportTableId).clone();
      copyOfTable.find(".title-and-table-left").remove();
      return copyOfTable
        .wrapAll("<div>")
        .parent()
        .html();
    },
    saveClientChart(value, message) {
      this.clientChart = JSON.parse(JSON.stringify(value));
      this.updateClientCharts(value, message);
    },
    updateReportPages(value) {
      this.reportPagesList.push(value);
    },
    removeFromAutoGeneratedReportClientChartsList() {
      ReportService.removeReportClientChart(this.clientChart);
    },
    isGenerateReportRunning(){
      return ReportService.getReportAutoChartGenerateStatus() == "generate";
    },
    generateNextClientChartLink() {
      let reportClientCharts = ReportService.getReportClientCharts();
      if (reportClientCharts.length > 0) {
        let clientChart = reportClientCharts[0];
        let link = ReportService.getClientChartLink(clientChart, this);
        window.location.href = link.href;
      } else if (ReportService.getReportAutoChartGenerateStatus() == "generate") {
        ReportService.clearReportAutoChartGenerate();
        window.close();
      } else if (ReportService.getReportAutoChartGenerateStatus() == "preview") {
        ReportService.clearReportAutoChartGenerate();
      }
    },
    setEmotionDriverName(value, indexRows){
        let name = "";
        if (value == "Stable") {
          name = "<div>Assurance</div>";
        } else if (value == "Imaginative") {
          name = "<div>Curiosity</div>";
        } else if (value == "Precise") {
          name = "<div>Strictness</div>";
        } else if (value == "Like-Minded") {
          name = "<div>Trust</div>";
        } else if (value == "Connected") {
          name = "<div>Activity</div>";
        }
        if (indexRows <= 4  && name != "") {
          this.emotionalDrivers[indexRows].name = name;
        }
    },    
    getPageContentByReportCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.pageContentForReport = response.data.data;
      }
    },
    async createTableImage(app, tableContainerId, includeRemoveBox = true, width = 500, scale = 10){
      await PrintAndCopyService.setTableImageUrl(tableContainerId, app, includeRemoveBox, scale);
      let myImage = $('<img/>');
      myImage.attr('src', app.tableImageUrl);
//      myImage.attr('width', width+'px');
      myImage.attr('class', 'pdf-content-image');

      let imageReportPageChartContent = myImage
          .wrapAll("<div>")
          .parent()
          .html();
          scale = 2;
      return imageReportPageChartContent;
    },
    async createChartImage(chartContainer, keyOnly = false, includeRemoveBox = true, scale = 10, imageClass = "pdf-content-image-width"){
      let vm = this;
      chartContainer = PrintAndCopyService.getChartContainer(chartContainer, keyOnly, includeRemoveBox);
      
      await html2canvas(chartContainer, PrintAndCopyService.getHTML2CanvasOptions(false,scale)).then(async function (canvas) {
        vm.imageUrl = canvas.toDataURL();
      });
      let myImage = $('<img/>');
      myImage.attr('src', this.imageUrl);
//      myImage.attr('width', width + 'px');
      myImage.attr('class', imageClass);
      
      let imageReportPageChartContent = myImage
          .wrapAll("<div>")
          .parent()
          .html();

      return imageReportPageChartContent;
    },
    async createChartImageMap(chartContainer, keyOnly = false, includeRemoveBox = true, scale = 10, imageClass = "pdf-content-image-width"){
      let returnData = {};
      let vm = this;
      chartContainer = PrintAndCopyService.getChartContainer(chartContainer, keyOnly, includeRemoveBox);
      
      await html2canvas(chartContainer, PrintAndCopyService.getHTML2CanvasOptions(false,scale)).then(async function (canvas) {
        vm.imageUrl = canvas.toDataURL();
        returnData.url = canvas.toDataURL();
      });
      let myImage = $('<img/>');
      myImage.attr('src', this.imageUrl);
//      myImage.attr('width', width + 'px');
      myImage.attr('class', imageClass);

      let imageReportPageChartContent = myImage
          .wrapAll("<div>")
          .parent()
          .html();

      returnData.content = imageReportPageChartContent;
      return returnData;
    },       
    async downloadPowerPoint(containerId) {
        //let reportPageChart = $("#" + containerId);
        let reportPageChart = $("#"+containerId);
        reportPageChart.find(".chart-header").css("display", "block");
        let reportPageChartObject = reportPageChart[ 0 ];        
        let imageReportPageChartContent = await this.createChartImage(reportPageChartObject, false, true); 
        PowerPointService.createChart(this.imageUrl);
    },  
/* New eChart buttons */
    setDisplayNumberOfResponses(inputDisplayNumberOfResponses){
      this.eChartCriteria.displayNumberOfResponses = inputDisplayNumberOfResponses;
      this.$parent.$refs.chartCriteria.eChartCriteria.displayNumberOfResponses = inputDisplayNumberOfResponses;
      this.$refs.eChart.processChart(this.getDisplayView(),this.displayType,this.eChartData,this.eChartCriteria,this.displayPricingPower);
    },
    setChartType(inputEChartType, inputRecalculate){
      this.eChartCriteria.eChartType = inputEChartType;
      this.$parent.$refs.chartCriteria.eChartCriteria.eChartType = inputEChartType;
      this.$refs.eChart.processChart(this.getDisplayView(),this.displayType,this.eChartData,this.eChartCriteria,this.displayPricingPower);
    },
    setAngleText(inputAngleText){
      this.eChartCriteria.angleText = inputAngleText;
      this.$parent.$refs.chartCriteria.eChartCriteria.angleText = inputAngleText;
      this.$refs.eChart.processChart(this.getDisplayView(),this.displayType,this.eChartData,this.eChartCriteria,this.displayPricingPower);
    },
    setShowGoalLine(inputShowGoalLine){
      this.eChartCriteria.showGoalLine = inputShowGoalLine;
      this.$parent.$refs.chartCriteria.eChartCriteria.showGoalLine = inputShowGoalLine;
      this.$refs.eChart.processChart(this.getDisplayView(),this.displayType,this.eChartData,this.eChartCriteria,this.displayPricingPower);
    },
    setShowLegend(inputShowLegend){
      this.eChartCriteria.showLegend = inputShowLegend;
      this.$parent.$refs.chartCriteria.eChartCriteria.showLegend = inputShowLegend;
      this.$refs.eChart.processChart(this.getDisplayView(),this.displayType,this.eChartData,this.eChartCriteria,this.displayPricingPower);
    },
    setShowLabels(inputShowLabels){
      this.eChartCriteria.showLabels = inputShowLabels;
      this.$parent.$refs.chartCriteria.eChartCriteria.showLabels = inputShowLabels;
      this.$refs.eChart.processChart(this.getDisplayView(),this.displayType,this.eChartData,this.eChartCriteria,this.displayPricingPower);
    },
    buildClientChart: function(){
      this.rebuildClientChart(this.clientId );
    },
    rebuildClientChart(client_id) {
      let useSaveParameters = typeof this.$parent.clientChart.criteria != undefined && this.$parent.clientChart.criteria != "" && this.$parent.autoGenerateChartRun;
      const params = useSaveParameters ? new URLSearchParams(this.$parent.clientChart.criteria) : this.$parent.$refs.chartCriteria.buildUrlParams(client_id);
      this.$parent.clientChart.criteria = params.toString();
    },
    resetClientChart: function(){
      this.clientChart = {
        clientChartId: 0
      };
      this.saveClientChartObject.name = "";
    },
    setEChartCriteria: function(inputEChartCriteria = {},buildTheClientChart = false){
      this.eChartCriteria = JSON.parse(JSON.stringify(inputEChartCriteria));
      if (!Object.prototype.hasOwnProperty.call(this.eChartCriteria, "eChartType")) {
		    this.eChartCriteria.eChartType = "stacked-bar-vertical";
	    }
      if (!Object.prototype.hasOwnProperty.call(this.eChartCriteria, "displayNumberOfResponsesForSecondChart")) {
        this.eChartCriteria.displayNumberOfResponsesForSecondChart = false;  
      }	        
      if (!Object.prototype.hasOwnProperty.call(this.eChartCriteria, "displayNumberOfResponses")) {
        this.eChartCriteria.displayNumberOfResponses = false;  
      }	        
      if (!Object.prototype.hasOwnProperty.call(this.eChartCriteria, "angleText")) {
        this.eChartCriteria.angleText = true;  
      }	        
      if (!Object.prototype.hasOwnProperty.call(this.eChartCriteria, "showGoalLine")) {
        this.eChartCriteria.showGoalLine = true;
      }	        
      if (!Object.prototype.hasOwnProperty.call(this.eChartCriteria, "showLegend")) {
		    this.eChartCriteria.showLegend = true;
	    }
      if (!Object.prototype.hasOwnProperty.call(this.eChartCriteria, "showLabels")) {
        this.eChartCriteria.showLabels = true;  
      }	        
      if (!Object.prototype.hasOwnProperty.call(this.eChartCriteria, "colorPaletteId")) {
        this.eChartCriteria.colorPaletteId = 0;
      }	        
      if (buildTheClientChart){
        this.buildClientChart();
      }
    },     
  },
  data: function() {
    return {
      imageUrl: "",
      tableImageUrl: "",
      error: false,
      redrawChart: false,
      generatingChart: false,
      autoGenerateChartRun: false,
      saveClientChartObject: {clientChartId: 0},
      survey: {},
      chart: {},
      chartsLib: {},
      clientChart: {
        clientChartId: 0
      },
      displayType: "",
      eChartData: {},
      eChartCriteria: {},
      snapChartTooltipConfig: {
        content: "Click to take snapshot of the chart. ",
        trigger: "hover",
        delay: { show: 500, hide: 500 }
      },
      criteriaResultsCompanyList: [
        { type: "benchmark", label: "Benchmark Companies", list: [] },
        { type: "competitor", label: "Competitors", list: [] },
        { type: "client", label: "Client", list: [] }
      ],
      criteriaResultsList: [],
      reportPagesList: [],
      table: {
        id: "",
        data: {}
      },
      pageContentForReport: [],
      tooltipConfigShow: ContentService.getWizardTooltip(),     
      snapModalContent: PrintAndCopyService.getSnapMessage()
    };
  },
  watch: {
    clientId: function() {
      this.setDisplayView(false);
    },
    surveyCode: function() {
      this.survey = SurveyService.getSurvey();
      if (this.autoGenerateChartRun != true) {
        this.resetClientChart();
      }
    },
    clientChart: function() {
      if (typeof this.clientChart != "undefined" && this.clientChart.clientChartId != 0) {
        ReportService.getReportPages(this.clientChart.clientChartId, this.getReportPagesCallback, this);
      } else {
        this.reportPagesList = [];
      }
    }
  },
  created() {
    this.survey = SurveyService.getSurvey();
    ContentService.getPageContent("Report", this.getPageContentByReportCallBack, this);
  },
  mounted() {
    let match = this.clientObject.clientCharts.find(x => x.clientChartId == this.saveClientChartId);
    if (typeof match !== "undefined") {
      match = toRaw(match);
      this.saveClientChartObject = JSON.parse(JSON.stringify(match));
    }    
    this.clientChart = JSON.parse(JSON.stringify(this.saveClientChartObject));
    let reportAutoGenerateStatus = ReportService.getReportAutoChartGenerateStatus();
    if ((reportAutoGenerateStatus == "preview" || reportAutoGenerateStatus == "generate") && !$.isEmptyObject(this.chart)) {
      let reportClientChart = ReportService.getReportClientChartByChartType(this.chartType);
      if (typeof reportClientChart != "undefined") {
        this.autoGenerateChartRun = true;
        this.clientChart = JSON.parse(JSON.stringify(reportClientChart));
        this.removeFromAutoGeneratedReportClientChartsList();
      }
    } else {
      this.autoGenerateChartRun = this.autoGenerateChart;
    }
    
    this.$root.setShowMenuIcon(true);
  },
  props: {
    autoGenerateChart: {
      type: Boolean,
      default: false
    },
    saveClientChartId: {
      type: Number,
      default: 0
    },
    inputEChartCriteria: {
        type: Object,
        default: function() {
            return {};
        }
    },     
    baseUrl: {
      type: String,
      default: AdminService.getBaseUrl()
    }
  }
};
</script>
