import $ from "jquery";
import BaseChartService from "./base.chart.service";

class LineVerticalService extends BaseChartService {
    constructor() {
        super();
    }
    getSeries() {
        let labelTextStyle = this.getDefaultLabelTextStyle();
        let baseSeriesForWave = {
            type: 'line',
            lineStyle: {
                width: 5
            },            
            label: {
                show: this.getShowLabel(),
                formatter: "{c}%",
                textStyle: this.getDefaultLabelTextStyle()
            },
        };
        let labelTextStyleForResponse = {
            color: "#fff",
            fontSize: 16,
            backgroundColor: "#C04F15",
            padding: 5,
        };
        $.extend(true, labelTextStyle, labelTextStyleForResponse);
        let baseSeriesForResponse = {
            type: "bar",
            barWidth: 2,
            label: {
              show: this.getShowLabel(),
              position: 'top',
              formatter: "{c}%",
              textStyle: labelTextStyle
            },
        };
        return this.getSeriesData().map((seriesData, sid) => {
            let series = {
                name: seriesData.name,
                data: seriesData.data
            };
            
            if (this.getDataSource() == "wave") {
                $.extend(true, series, baseSeriesForWave);
            } else {
                $.extend(true, series, baseSeriesForResponse);
            }
            return series;
        });        
    }
    setData(inputTimePeriods,selectedQuestion) {
        let dataSource = this.getDataSource();
        let seriesData = [];
        let legendData = [];
        let axisData = {
            name: "",
            data: []
        };          
        for (let i = 0; i < selectedQuestion.options.length; i++) {
            if (dataSource == "wave") {
                axisData = {
                    name: "",
                    data: []
                };  
            }
            let option = selectedQuestion.options[i];
            for (let j = 0; j < inputTimePeriods.length; j++) {
                let selectedTimePeriod = inputTimePeriods[j];
                let countItem = option.counts.find(item => item.timePeriod === selectedTimePeriod);
                if (typeof countItem != 'undefined' && countItem.count != null) {
                    axisData.data.push(countItem.count);
                }
            }
            if (dataSource == "wave") {
                axisData.name = option.label;
                seriesData.push(axisData);
            }
            legendData.push(option.label);
        }
        if (dataSource == "response") {
            seriesData.push(axisData);
        }
        this.setSeriesData(seriesData);
        this.setLegendData(legendData);
    }
    getOptions(disableSelectedMode = false, isSingleWave = false,app,modalId,reportPageTypeName,inputDownloadFileName) {
        let legend;
        if (disableSelectedMode == true){
            legend = {selectedMode: false};
            $.extend(true, legend, this.getDefaultLegendResponseRatesChart());        
        } else {    
            legend = this.getDefaultLegendResponseRatesChart();
            let data = {
                data: this.getLegendData()
            }
            $.extend(true, legend, data);        
        }
        let xAxisLabel = {
            overflow: "truncate",
            interval: 0,
            ellipsis: '...'
        };
        if (isSingleWave == true){
            $.extend(true, xAxisLabel, {rotate: 15,hideOverlap: true});
        }
        let yAxisLabel = {
            formatter: '{value}%'
        };
        $.extend(true, xAxisLabel, this.getDefaultAxisTextStyle());                       
        $.extend(true, yAxisLabel, this.getDefaultAxisTextStyle());                       
        let tooltip = {
            formatter: this.toolTipForLineChart,
            textStyle: this.getDefaultLabelTextStyle()
        };
        $.extend(true, tooltip, this.getDefaultToolTip('item'));                       

        let option = {
            legend: legend,
            toolbox: this.getDefaultToolBox(app,modalId,reportPageTypeName,inputDownloadFileName),
            grid: this.getShowLegend() ? this.getGrid() : this.getGridBackground(),
            tooltip: tooltip,            
            yAxis: {
                type: 'value',
                axisLabel: yAxisLabel,
                splitLine: {
                    lineStyle: {
                      color: this.getBaseLineColor(),
                      width: this.getBaseLineWidth()
                    }
                },               
            },
            xAxis: {
                type: 'category',
                data: this.getXAxisData(),
                axisLabel: xAxisLabel,
                axisTick:{
                    show: false,
                },                                                         
},
            series: this.getSeries(),
            color: this.getColorPalette()
            
        };
        return option;
    }
}
export default new LineVerticalService() ;
