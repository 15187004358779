<template>
    <div class="col-10 no-gutters container">
        <div class="e-chart-container">
            <div class="title-and-chart">
                <div :id="eChartId" class="e-chart">
                    <chart :options="chart.options"></chart>
                </div>
                <div :id="eChartMiniId">
                    <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
                </div>             
            </div>
        </div>
    </div>
</template>

<script>
import BarVerticalService from "../../services/echarts/bar.vertical.service";
import BarHorizontalService from "../../services/echarts/bar.horizontal.service";
import ReportService from "../../services/report.service";

import EChartsMixins from "../../mixins/EChartsMixins";

import $, { isEmptyObject } from "jquery";

export default {
  name: "NPSChart",
  mixins: [EChartsMixins],
  components: {},
  data() {
    return {
        companies: [],
        detractorsSeries: {
            name: 'Detractors',
            data: []
        },
        passivesSeries: {
            name: 'Passives',
            data: []
        },
        promotersSeries: {
            name: 'Promoters',
            data: []
        },        
        legendData : ['Promoters','Passives','Detractors'],
        addWrapper: false,
        inputDownloadFileName: "Net Promoter Score"
    };
  },
  props: {
    isMatch: {
      type: Boolean,
      default: true
    },
    displayTitle: {
      type: Boolean,
      default: true
    },
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    setData: function(npsChartData){
        this.companies = [];
        if (typeof npsChartData != "undefined" && !$.isEmptyObject(npsChartData)) {
            this.companies = JSON.parse(JSON.stringify(npsChartData));
        }
    },
    getChartOptions: function(){
        let options = {};
        let showToolbox =  !(ReportService.getReportAutoChartGenerateStatus() == "generate");
        let colorPaletteId = this.eChartCriteria.colorPaletteId;
        let showLegend = this.eChartCriteria.showLegend;
        let showLabel = this.eChartCriteria.showLabels;
        let angleText = this.eChartCriteria.angleText;
        let showGoalLine = this.eChartCriteria.showGoalLine;
        let isMultipleWaves = this.companies.length > 1;
        let displayNumberOfRespondents = this.eChartCriteria.displayNumberOfResponses;
        let isStacked = true;
   //     let grid = {top: 40,containLabel: true}

        let grid = {top: 40,width:670,containLabel: true}
        let titleConfiguration = {
                text: this.eChartCriteria.periodName + " NPS",
                left: 'center'
//                left: 400
        };      
        if (isMultipleWaves && this.isMatch == true) {
            titleConfiguration.text = "NPS Trend";
        }   
        this.detractorsSeries.data = [];
        this.passivesSeries.data = [];
        this.promotersSeries.data = [];
/* Start of function that should go into helper service */
        let axisData = [];
        let seriesData = [];
        if (this.eChartCriteria.eChartType == "stacked-bar-horizontal"){
            this.companies.reverse();
        } 
        for (let i = 0; i < this.companies.length; i++) {
            let companyProperties = this.companies[i];
            if (companyProperties.length >= 4) {
                let name = companyProperties[0];
                if (displayNumberOfRespondents){
                    axisData.push(name);    
                } else {
                    name = name.split('(')[0];
                    axisData.push(name);
                }                
                let detractorScore = companyProperties[1];
                let passiveScore = companyProperties[2];
                let promoterScore = companyProperties[3];
                if (typeof detractorScore != 'undefined' && detractorScore != null ) {
                    if (detractorScore < 0){
                        detractorScore = 0;
                    }
                    this.detractorsSeries.data.push(detractorScore);
                } 
                if (typeof passiveScore != 'undefined' && passiveScore != null) {
                    if (passiveScore < 0){
                        passiveScore = 0;
                    }
                    this.passivesSeries.data.push(passiveScore);
                }
                if (typeof promoterScore != 'undefined' && promoterScore != null) {
                    if (promoterScore < 0){
                        promoterScore = 0;
                    }
                    this.promotersSeries.data.push(promoterScore);
                }
            }
        }
        seriesData.push(this.detractorsSeries);      
        seriesData.push(this.passivesSeries);      
        seriesData.push(this.promotersSeries);      

/* End of function that should go into helper service */
        if (this.eChartCriteria.eChartType == "stacked-bar-vertical"){
            BarVerticalService.setShowSnap(this.isSavedCard);
            BarVerticalService.setShowToolbox(showToolbox);
            BarVerticalService.setColorPaletteId(colorPaletteId);
            BarVerticalService.setShowLabel(showLabel);
            BarVerticalService.setShowLegend(showLegend);
            BarVerticalService.setIsMultiWave(isMultipleWaves);
            BarVerticalService.setChartType(this.eChartCriteria.eChartType);
            BarVerticalService.setLegendData(this.legendData);
       
            BarVerticalService.setSeriesData(seriesData);
            BarVerticalService.setXAxisData(axisData);            

            options = BarVerticalService.getOptions(this.recalculate == true,isStacked,this,this.modalId, this.inputReportTypeName,this.companies.length,5,this.inputDownloadFileName);
            options.yAxis.axisLabel.show = false;
            options.yAxis.splitLine.show = true;
            $.extend(true, options.yAxis, {axisTick: {show: false}}); 
            if (angleText == true){           
                $.extend(true, options.xAxis.axisLabel, {rotate: 25,hideOverlap: true});
            }
            if (showGoalLine == true){
                options.series.push(BarVerticalService.getMarkLine(false));
            }
            options.yAxis.max = 100;    
            let breakPointList = [];
            let numberOfWaves = this.companies.length;
            if (numberOfWaves > 4){
                let numberOfBreakPoints = Math.floor(numberOfWaves / 4);
                for (let i = 1; i <= numberOfBreakPoints; i++) {
                    breakPointList.push(i * 4);
                }
                let splitLine = {
                    show: true,
                    interval: function(index, value) {
                        return breakPointList.includes(index);              
                    },             
                };
                $.extend(true, options.xAxis, {splitLine: splitLine});
            }
        } else if (this.eChartCriteria.eChartType == "stacked-bar-horizontal"){
            let isStacked = true;
            BarHorizontalService.setBarWidth(40);
            BarHorizontalService.setShowSnap(this.isSavedCard);
            BarHorizontalService.setShowToolbox(showToolbox);
            BarHorizontalService.setColorPaletteId(colorPaletteId);
            BarHorizontalService.setShowLabel(showLabel);
            BarHorizontalService.setShowLegend(showLegend);
            BarHorizontalService.setIsMultiWave(isMultipleWaves);
            BarHorizontalService.setChartType(this.eChartCriteria.eChartType);
            BarHorizontalService.setLegendData(this.legendData);
       
            BarHorizontalService.setSeriesData(seriesData);
            BarHorizontalService.setYAxisData(axisData);            
            options = BarHorizontalService.getOptions(this.recalculate == true,isStacked,this,this.modalId, this.inputReportTypeName,this.companies.length,this.inputDownloadFileName);
            options.xAxis.axisLabel.show = false;
            options.xAxis.splitLine.show = false;
            $.extend(true, options.xAxis, {axisTick: {show: false}});         
            if (showGoalLine == true){
                options.series.push(BarVerticalService.getMarkLine(true));
            }
            options.xAxis.max = 100;    
        }
        if (!$.isEmptyObject(options)) { 
            //override normal options
            $.extend(true, options.grid, grid);     
            if (this.displayTitle == true){       
                options.title = titleConfiguration;
            }
            options.legend.borderWidth = 0;
//            options.legend.top = 45;
            options.legend.top = 40;            
            options.color = ["#c00000","#E6AF00","#379660"];
            options.series[1].label.color = "#000000";
        }
        return options;           
    },
    processChart: function(runChart = false,npsChartData,eChartCriteria){
        if (runChart == true) {
            this.setData(npsChartData);
            if (this.companies.length > 0) {
                this.eChartCriteria = JSON.parse(JSON.stringify(eChartCriteria));
                this.initChart();
                this.$emit('save-criteria',true);
                this.$emit('save-table');
            }
        }
    },
  },
  watch: {},
};
</script>
