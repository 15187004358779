import BaseChartService from "./base.chart.service";
import $ from "jquery";

class DoughnutService extends BaseChartService {
    constructor() {
        super();
    }    
    getBaseSeries(numberOfDataSets) {
        let label = {
            formatter: this.labelFormatter,
            show: this.getShowLabel()
        };
        $.extend(true, label, this.getDefaultLabelTextStyle()); 
        return {
            type: 'pie',
            radius: this.getInnerAndOuterRadius(numberOfDataSets),
            avoidLabelOverlap: true,
            label: label,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            },            
            labelLine: {
                show: true,
                length: 60,
                length2: 5
            },
        };
    }
    setData(selectedQuestion,inputTimePeriods,setDataSets) {
        let numberOfDataSets = setDataSets.length;
        this.setSeries([]);
        let centerOptions = this.getCenterOptions(numberOfDataSets);
        let source = [];
        let sourceName = 'wave'; 
        // create array for wave
        let waveSource = [];
        waveSource.push(sourceName);
        for (let j = 0; j < inputTimePeriods.length; j++) {
            let selectedTimePeriod = inputTimePeriods[j];
            // add the time period to the wave array
            waveSource.push(selectedTimePeriod);
            let series = {
                name: selectedTimePeriod,
                center: centerOptions[j] 
            };
            let encode = {
                encode: {
                    itemName: sourceName,
                    value: selectedTimePeriod
                }
            };   
            $.extend(true, series, this.getBaseSeries(numberOfDataSets));
            $.extend(true, series, encode);
            this.addSeries(series);
        }
        source.push(waveSource);    
        for (let i = 0; i < selectedQuestion.options.length; i++) {
            // create new array for each option
            let optionSource = [];
            let option = selectedQuestion.options[i];
            optionSource.push(option.label);
            // loop through each time period to get the count
            for (let j = 0; j < inputTimePeriods.length; j++) {
                let selectedTimePeriod = inputTimePeriods[j];
                // add the count to the array
                let countItem = option.counts.find(item => item.timePeriod === selectedTimePeriod);
                if (typeof countItem != 'undefined' && countItem.count != null && countItem.count != 0) {
                    optionSource.push(countItem.count);
                } 
            }
            source.push(optionSource);     
        }
        let dataSetSource = {
            source: source
        };
        this.setDataSet(dataSetSource);
        this.setTitleConfiguration(setDataSets);

    }
    getOptions(app,modalId,reportPageTypeName,inputDownloadFileName) {
        let tooltip = {
            formatter: this.toolTipForPieChart,
            textStyle: this.getDefaultLabelTextStyle()
        };
        $.extend(true, tooltip, this.getDefaultToolTip('item'));           
        let option = {
            title: this.getTitleConfiguration(),
            tooltip: tooltip,
            grid: this.getShowLegend() ? this.getGrid(false) : {},
            toolbox: this.getDefaultToolBox(app,modalId,reportPageTypeName,inputDownloadFileName),
            legend: this.getDefaultLegendResponseRatesChart(),
            series: this.getSeries(),
            dataset: this.getDataSet(),
            color: this.getColorPalette()
        };
        return option;
    }
}
export default new DoughnutService();
