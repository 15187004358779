<template>
  <div :class="getClass(dashboardLayoutCard.dashboardCardTypeCode, dashboardLayoutCard.sequenceNumber)">
    <div class="dashboard-header">
      <div class="dashboard-title">
        <div class="dashboard-title-details">
          <a href="/analytics/trends" @click="$parent.logViewDetailsEvent(title)">            
            {{ title }}
          </a>
          <smart-center-link v-if="displayDashboard" page="Competitive Dashboard" location="Competitive Trends" />
        </div>
        <div class="dashboard-save-dashboard-card"  @click="$modal.show('competitive-trends-save-chart-confirmation')"> 
            Save for Report
            <span class="m-1">
              <font-awesome-icon :icon="['fa', 'floppy-disk']" />
            </span>
        </div>
      </div>
      <div class="dashboard-widgets dashboard-competitive-trends">
        <dashboard-card-dropdown label="Competitor Set" :read-only="readOnly" :options="rival_set_options" :default-value="competitorSet" @change="saveCompetitorSet"></dashboard-card-dropdown>
        <dashboard-card-dropdown label="  Rank By" :read-only="readOnly" :options="rank_options" :default-value="rankBy" @change="saveRankBy"></dashboard-card-dropdown>
      </div>
      <dashboard-card-expand :dashboard-id="dashboardId" />
    </div>
    <div class="dashboard-divider"></div>
    <dashboard-card-skeleton v-if="!displayDashboard" />
    <div class="dashboard-card-body">
      <div class="competitve-trends-dashboard-chart" :style="dashboardChartStyle">
        <chart :options="options"></chart>
      </div>
      <div id="competitive-trends-mini">
        <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
      </div> 
    </div>
    <!-- :updatedModalChartOptions.sync="options.series[0].data" --> 
    <dashboard-modal :modal-id="dashboardId" :chart="this.chart" :client-chart="this.clientChart" :dashboard-card-report-page-type-name="reportTypeName" :modal-title="title" :modal-chart-options="options" />
    <vue-modal :ref="modalName" :name="modalName" :width="350" :height="200">
      <save-chart :modal-name="modalName" button-label="Save for Report" :chart-title="this.chartTitle" :initial-client-chart="this.clientChart" @save="this.updateClientCharts" @closeModal="closeModal(this.modalName)"></save-chart>
    </vue-modal>
  </div>
</template>

<script>
import DashboardMixins from "../../mixins/DashboardMixins.vue";
import DashboardService from "../../services/dashboard.service";
import AdminService from "../../services/admin.service";
import DashboardCardDropdown from "./DashboardCardDropdown";
import SaveChart from "../chart/SaveChart.vue";
import DashboardCardSkeleton from "../../components/skeleton/DashboardCardSkeleton.vue";

import Vue from "vue";
import echarts from "echarts";
import $ from "jquery";
import { markRaw } from 'vue'; 
export default {
  name: "DashboardCompetitiveTrends",
  mixins: [DashboardMixins],
  components: { DashboardCardDropdown,SaveChart,DashboardCardSkeleton },
  data() {
    return {
      title: "Rank Trends",
      modalName: "competitive-trends-save-chart-confirmation",
      rank_options: [
        { name: "NPS", value: "NPS" },
        { name: "Satisfaction", value: "Satisfaction" },
        { name: "Satisfaction Gaps", value: "Satisfaction Gaps" },
        { name: "Emotions Gaps", value: "Emotions Gaps" },
        { name: "Willingness to Pay", value: "Willingness to Pay" },
        { name: "Value Gap", value: "Value Gap" }
      ],
      rankBy: "Satisfaction",
      competitorSet: "survey"
    };
  },
  props: {},
  computed: {},
  mounted() {
    // eslint-disable-next-line no-console
    //console.log("DashboardCompetitiveTrends::mounted");
    this.chartTitle = "Rank Trends";
    this.chartTypeName = "dashboard-competitive-trends";
    this.containerId = "competitive-trends-mini"
	  this.reportTypeName = "Dashboard - Competitive Trends"
    this.resetChart();
  },
  created() {},
  methods: {
    processSavedChartCriteria(criteria) {
      let criteriaParameters = criteria.split("=");
      let isCompetitorSet = criteriaParameters[0] == "competitorSet" ? true : false;
  		if (isCompetitorSet){
        this.competitorSet = criteriaParameters[1];
  		}
      let isRankBy = criteriaParameters[0] == "rankBy" ? true : false;
  		if (isRankBy){
        this.rankBy = criteriaParameters[1];
  		}
      let isStartTimePeriodId = criteriaParameters[0] == "startTimePeriodId" ? true : false;
  		if (isStartTimePeriodId){
        this.startTimePeriodId = criteriaParameters[1];
  		}
    },
    buildUrlParams(client_id) {
      let params = this.buildBaseUrlParams();
      params.append("competitorSet",this.competitorSet);
      params.append("rankBy",this.rankBy);
      params.append("startTimePeriodId",this.startTimePeriodId);
      return params;
    },      
    initChart: function(dynamicOptions) {
      //https://echarts.apache.org/examples/en/editor.html?c=line-step
      let chartDiv = $(".competitve-trends-dashboard-chart")[0];
      this.chart = markRaw(echarts.init(chartDiv));
      let vm = this;
      this.chart.on('finished', () => {
        vm.saveImage();
      });
      this.options = DashboardService.getCompetitiveTrendsOptions(this.competitorSet, this.rankCompanyCount);
      var key = "yAxis";
      delete dynamicOptions[key];

      $.extend(true, this.options, dynamicOptions);
      $.extend(true, this.options.series[0], { lineStyle: { width: 4 } });
      if (this.autoGenerateChartRun == true){
        this.options.legend.top = 0;
        this.options.legend.show = true;
        this.options.grid.top = 25;
      }
      this.chart.setOption(this.options, true);
      //this.printOptions();
    },
    loadData: function() {},
    initialLoadData: async function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardCompetitiveTrends::initialLoadData autoGenerateChartRun " + this.autoGenerateChartRun );  
      this.displayDashboard = false;
      await DashboardService.getCompetitorSet(this.getCompetitorSetCallback, this);
      if (this.autoGenerateChartRun){
        this.processAutoGenerateChart();
      }     
      this.updateCard();
    },
    saveRankBy: function(value) {
      this.rankBy = value;
      DashboardService.setDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_COMPETITIVE_TRENDS_RANK_BY_SETTING_TYPE, this.rankBy);
      this.updateCard();
    },
    saveCompetitorSet: function(value) {
      this.competitorSet = value;
      DashboardService.setDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_COMPETITIVE_TRENDS_COMPETITOR_SET_SETTING_TYPE, this.competitorSet);
      this.updateCard();
    },
    updateCard: function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardCompetitiveTrends::updateCard");
      this.resetChart();
      this.displayDashboard = false;
      let vm = this;
      setTimeout(function() {
        if (!vm.autoGenerateChartRun){
          vm.rankBy = DashboardService.getDashboardSetting(vm.$parent.defaultDashboardValues, DashboardService.DASHBOARD_COMPETITIVE_TRENDS_RANK_BY_SETTING_TYPE, vm.rankBy, vm.rank_options);
          vm.competitorSet = DashboardService.getDashboardSetting(vm.$parent.defaultDashboardValues, DashboardService.DASHBOARD_COMPETITIVE_TRENDS_COMPETITOR_SET_SETTING_TYPE, vm.competitorSet, vm.rival_set_options);
        }
        DashboardService.getCompetitiveTrends(vm.rankBy, vm.competitorSet, vm.startTimePeriodId, vm.getCompetitiveTrendsCallback, vm);
      }, 300);
    },
    getCompetitiveTrendsCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.displayDashboard = true;
        let dynamicOptions = response.data.data.options;
        this.rankCompanyCount = response.data.data.rankCompanyCount;        
        this.initChart(dynamicOptions);
        if (!this.autoGenerateChartRun){
          this.setupClientChart();
        }      
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    }
  },
  watch: {
    entrySurveyCode: function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardPerformanceTrends::entrySurveyCode");
      this.resetChart();
      this.competitorSet = "survey";      
    },
  }
};
</script>
