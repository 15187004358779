import $ from "jquery";
import jQuery from "jquery";
import { isConsole } from "mobile-device-detect";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AlertService from "./alert.service";

import axios from "axios";

const BASE_URL = process.env.VUE_APP_BASE_URL;
const API_URL = BASE_URL + "/api/v1/admin/";
const PERFORMANCE_API_URL = BASE_URL + "/api/v1/performance/";

class AdminService {
  displayInfoMessage(message) {
    if (Object.prototype.hasOwnProperty.call(message, "text")) {          
      AlertService.showDisplayInfoMessage(message.text);
    }
  }
  displaySuccessMessage(message, displayMessage = false) {
  if (Object.prototype.hasOwnProperty.call(message, "text")) {          
    AlertService.showDisplaySuccessMessage(message.text);
    }
  }
  displayErrorMessage(message = ErrorService.getDefaultErrorMessage(), timer = 15000) {
    if (Object.prototype.hasOwnProperty.call(message, "text")) {          
      AlertService.showDisplayErrorMessage(message.text,timer);
    }
  }
  getBaseUrl() {
    return BASE_URL;
  }
  removeByName(list, name) {
    let removedItem = list.find(x => x.name == name);
    if (typeof removedItem !== "undefined") {
      list.splice($.inArray(removedItem, list), 1);
    }
    return list;
  }
  getActiveOptions() {
    return [
      { name: "Active", value: "Y" },
      { name: "Inactive", value: "N" }  
    ];
  }
  getCurrencyOptions() {
    return [
      { name: "US Dollars", currency: "USD", localString: "en-US" },
      { name: "Argentine Peso", currency: "ARS", localString: "es-AR" },
      { name: "Australia", currency: "AUD", localString: "en-AU" },
      { name: "Brazilian Real", currency: "BRL", localString: "pt-BR" },
      { name: "British Pound", currency: "GBP", localString: "en-GB" },
      { name: "Canada", currency: "CAD", localString: "fr-CA" },
      { name: "China Yuan", currency: "CNY", localString: "zh-CN" },
      { name: "Euro", currency: "EUR", localString: "de-DE" },
      { name: "Indian Rupee", currency: "IDR", localString: "ta-IN" },
      { name: "Japanese Yen", currency: "JPY", localString: "ja-JP" },
      { name: "Russian Ruble", currency: "RUB", localString: "ru-RU" },
      { name: "Swiss Franc", currency: "CHF", localString: "de-CH" }
    ];
  }
  getFinancialFormatOptions() {
    return [
      { value: "Actuals", name: "Actuals" },
      { value: "Thousands", name: "In 1,000s (thousands)" },
      { value: "Millions", name: "In 1,000,000s (millions)" }
    ];
  }
  getCalculateGrowthRateOptions() {
    return [
      { value: "YoY", name: "Year-Over-Year (YOY) %" },
      { value: "YoY-Value", name: "Year-Over-Year (YOY) Value" },
      { value: "Sequentially", name: "Sequential %" },
      { value: "Sequentially-Value", name: "Sequential Value" },
      { value: "None", name: "None" }
    ];
  }
  isOnScreen(elem) {
    // if the element doesn't exist, abort
    if (elem.length == 0) {
      return;
    }
    var $window = jQuery(window);
    var viewport_top = $window.scrollTop();
    var viewport_height = $window.height();
    var viewport_bottom = viewport_top + viewport_height;
    var $elem = jQuery(elem);
    var top = $elem.offset().top;
    var height = $elem.height();
    var bottom = top + height;

    return (
      (top >= viewport_top && top < viewport_bottom) ||
      (bottom > viewport_top && bottom <= viewport_bottom) ||
      (height > viewport_height && top <= viewport_top && bottom >= viewport_bottom)
    );
  }
  async getResponseMessageList(startDate, endDate, status, duration, response_message_title, response_message_user_id, callback, adminApp) {
    const params = new URLSearchParams();
    params.append("startDate", startDate);
    params.append("endDate", endDate);
    params.append("duration", duration);
    params.append("status", status);
    params.append("responseMessageTitle", response_message_title);
    params.append("responseMessageUserId", response_message_user_id);
    params.append("userId", this.getUserId());

    await axios
      .post(API_URL + "get-response-message-list", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdminService.getResponseMessageList", error, "admin", adminApp);
      });
  }
  async getApplicationErrorList(startDate, endDate, applicationErrorClientId, callback, adminApp) {
    const params = new URLSearchParams();
    params.append("startDate", startDate);
    params.append("endDate", endDate);
    params.append("applicationErrorClientId", applicationErrorClientId);
    params.append("userId", this.getUserId());

    await axios
      .post(API_URL + "get-application-error-list", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdminService.getApplicationErrorList", error, "admin", adminApp);
      });
  }
  async getJobsSummary(callback, adminApp) {
    const params = new URLSearchParams();
    await axios
      .post(API_URL + "get-jobs-summary", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdminService.getJobsSummary", error, "admin", adminApp);
      });
  }
  async getJobExecutionLogList(startDate, endDate, status, jobName, callback, adminApp) {
    const params = new URLSearchParams();
    params.append("startDate", startDate);
    params.append("endDate", endDate);
    params.append("jobName", jobName);
    params.append("status", status);
    params.append("userId", this.getUserId());
    await axios
      .post(API_URL + "get-jobs-execution-log", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdminService.getJobExecutionLogList", error, "admin", adminApp);
      });
  }
  async getPerformanceSummaryList(startDate, endDate, environmentType, callback, adminApp) {
    const params = new URLSearchParams();
    params.append("startDate", startDate);
    params.append("endDate", endDate);
    params.append("environmentType", environmentType);
    params.append("userId", this.getUserId());

    await axios
      .post(PERFORMANCE_API_URL + "get-performance-statistics", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdminService.getPerformanceSummaryList", error, "admin", adminApp);
      });
  }
  async getCacheStatistics(callback, adminApp) {
    const params = new URLSearchParams();
    params.append("userId", this.getUserId());

    await axios
      .post(PERFORMANCE_API_URL + "get-cache-statistics", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdminService.getCacheStatistics", error, "admin", adminApp);
      });
  }
  async warmCache(callback, adminApp) {
    const params = new URLSearchParams();
    params.append("userId", this.getUserId());

    await axios
      .post(PERFORMANCE_API_URL + "warm-cache", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdminService.warmCache", error, "admin", adminApp);
      });
  }

  async clearCache(callback, adminApp) {
    const params = new URLSearchParams();
    params.append("userId", this.getUserId());

    await axios
      .post(PERFORMANCE_API_URL + "clear-cache", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdminService.clearCache", error, "admin", adminApp);
      });
  }
  async clearCacheByName(name, callback, adminApp) {
    const params = new URLSearchParams();
    params.append("userId", this.getUserId());
    params.append("name", name);

    await axios
      .post(PERFORMANCE_API_URL + "clear-cache-by-name", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdminService.clearCache", error, "admin", adminApp);
      });
  }

  getUserId() {
    if (!jQuery.isEmptyObject(localStorage.getItem("user"))) {
      return JSON.parse(localStorage.getItem("user")).id;
    }
  }

  async getImportType(callback, adminApp) {
    const params = new URLSearchParams();
    await axios
      .post(API_URL + "get-import-type", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdminService.getImportType", error, "admin", adminApp);
      });
  }
  async getImportLogList(startDate, endDate, status, importTypeName, callback, adminApp) {
    const params = new URLSearchParams();
    params.append("startDate", startDate);
    params.append("endDate", endDate);
    params.append("importTypeName", importTypeName);
    params.append("status", status);
    params.append("userId", this.getUserId());
    await axios
      .post(API_URL + "get-imports-log", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdminService.getImportLogList", error, "admin", adminApp);
      });
  }
}
export default new AdminService();
