import $ from "jquery";
export default class BaseChartService {
    constructor() {}
    colorPalette = [
        {label: "Office", type: "office", colors: ['#156082','#E97132','#196B24','#0F9ED5','#A02B93','#4EA72E','#0B3041','#80350E'], index: 0},
        {label: "Color Blind Safe", type: "color-blind", colors:  ['#074650','#009292','#C83D95','#FE6DB6','#480091','#B66DFF','#3049AD','#6DB6FF'], index: 1},
        {label: "Categorical", type: "categorical", colors:  ['#e53935','#5e35b1','#039be5','#43a047','#fdd835','#f4511e','#546e7a','#d81b60'], index: 2},
        {label: "Black-Grey Sequential", type: "black-grey", colors:  ['#000000','#252525','#525252','#737373','#969696','#bdbdbd','#d9d9d9','#f0f0f0'], index: 3},
        {label: "Blue 1", type: "blue-1", colors:  ['#081d58','#253494','#225ea8','#1d91c0','#41b6c4','#7fcdbb','#8BD264','#ADCC12'], index: 4}, 
        {label: "Blue 2", type: "blue-2", colors:  ['#0F243E','#16365C','#1F497D','#538DD5','#8DB4E2','#C1CAF1','#B2BDEC','#8D9DE3'], index: 5}, 
        {label: "Blue 3", type: "blue-3", colors:  ['#244062','#366092','#4F81BD','#95B3D7','#AEC5E0','#C0DDE2','#AED4DA','#8BC2CB'], index: 6}, 
        {label: "Brown", type: "brown", colors:  ['#1D1B10','#494529','#948A54','#B9B085','#D5D0B5','#D6C5B4','#C9B49F','#B89B7E'], index: 7}, 
        {label: "Green 1", type: "green-1", colors:  ['#004529','#006837','#238443','#41ab5d','#78c679','#A8DB89','#C7EA7A','#BFCD09'], index: 8}, 
        {label: "Green 2", type: "green-2", colors:  ['#4F6228','#76933C','#9BBB59','#B9CF87','#CADBA5','#BBE2B8','#AFDDAB','#93D28E'], index: 9}, 
        {label: "Maroon", type: "maroon", colors:  ['#632523','#963634','#C0504D','#D68C8A','#E1AAA9','#F8B6C1','#F69CAB','#F06C82'], index: 10}, 
        {label: "Orange 1", type: "orange-1", colors:  ['#662506','#993404','#cc4c02','#ec7014','#FDA901','#FEC34C','#C8B000','#A89400'], index: 11}, 
        {label: "Orange-Green", type: "orange-green", colors:  ['#A9261F','#E5400D','#FC8208','#FCBB02','#9FC31B','#77B32F','#40923E','#126837'], index: 12}, 
        {label: "Orange-Blue", type: "orange-blue", colors:  ['#A9261F','#E5400D','#FC8208','#FCBB02','#1d91c0','#225ea8','#253494','#081d58'], index: 14}, 
        {label: "Orange-Gray", type: "orange-gray", colors:  ['#974706','#E26B0A','#F79646','#F9B073','#FBC69B','#CBCBCB','#B0B0B0','#808080'], index: 15}, 
        {label: "Purple", type: "purple", colors:  ['#403151','#60497A','#8064A2','#A693BF','#C0B2D2','#DBBFCE','#D3B1C3','#C89CB4'], index: 16}, 
        {label: "Turquoise", type: "turquoise", colors:  ['#215967','#31869B','#4BACC6','#7AC1D4','#A2D4E2','#AAF0DF','#88ECD4','#5DE5C5'], index: 17}, 
        {label: "Red to Green", type: "red-to-green", colors:  ['#607731','#77933C','#BDBDBD','#FF7171','#FF0000'], index: 18}, 
    ];
    backupColorPalette = [
        '#525252','#737373','#969696','#bdbdbd'
    ];
    colorPaletteId = 0;
    dataSource = "";
    xAxisData = [];
    yAxisData = [];
    legendData = [];
    series = [];
    seriesData = [];
    chartType = "";
    isMultiWave = false;
    showLabel = true;
    showLegend = true;
    dataSet = {};
    showToolbox = true;
    showSnap = false;
    titleConfiguration = [];
    getTitleConfiguration() {
        return this.titleConfiguration;
    }
    setTitleConfiguration(inputTimePeriods) {
        this.titleConfiguration = [];
        let locationConfigurationOptions = this.getSubTitleLocationConfiguration(inputTimePeriods.length);
        for (let j = 0; j < inputTimePeriods.length; j++) {
            let configuration = this.getBaseSubTitleConfiguration(inputTimePeriods[j]);
            $.extend(true, configuration, locationConfigurationOptions[j]);
            this.titleConfiguration.push(configuration);
        }        
    }
    getShowSnap() {
        return this.showSnap;
    }
    setShowSnap(showSnap) {
        this.showSnap = showSnap;
    }
    getShowToolbox() {
        return this.showToolbox;
    }
    setShowToolbox(showToolbox) {
        this.showToolbox = showToolbox;
    }
    getShowLegend() {
        return this.showLegend;
    }
    setShowLegend(showLegend) {
        this.showLegend = showLegend;
    }
    getShowLabel() {
        return this.showLabel;
    }
    setShowLabel(showLabel) {
        this.showLabel = showLabel;
    }
    setDataSource(data) {
        this.dataSource = data;
    }
    getDataSource() {
        return this.dataSource;
    }    
    addSeries(inputSeries) {
        this.series.push(inputSeries);
    }
    getSeries() {
        return this.series;        
    }
    setSeries(data){
        this.series = data;
    }
    getDataSet() {
        return this.dataSet;
    }
    setDataSet(data){
        this.dataSet = JSON.parse(JSON.stringify(data));
    }     
    setChartType(chartType) {
        this.chartType = chartType;
    }
    getChartType() {
        return this.chartType;
    }    
    setIsMultiWave(isMultiWave) {
        this.isMultiWave = isMultiWave;
    }
    getisMultiWave() {
        return this.isMultiWave;
    }
    setLegendData(data) {
        this.legendData = data;
    }
    getLegendData() {
        return this.legendData;
    }
    setXAxisData(data) {
        this.xAxisData = data;
    }
    getXAxisData() {
        return this.xAxisData;
    }
    setYAxisData(data) {
        this.yAxisData = data;
    }
    getYAxisData() {
        return this.yAxisData
    }
    setSeriesData(data) {
        this.seriesData = data;
    }    
    getSeriesData() {
        return this.seriesData;
    }

    setColorPaletteId(colorPaletteId) {
         this.colorPaletteId = colorPaletteId; 
    }
    getColorPaletteList() {
        return this.colorPalette;
    }
    getColorPalette() {
        let defaultColors = [...this.colorPalette[0].colors, ...this.backupColorPalette];
        if (this.colorPalette.length >= this.colorPaletteId){
            let colorPalette = this.colorPalette.find(item => item.index === this.colorPaletteId);
            if (typeof colorPalette != 'undefined'  && colorPalette != null){
                return [...colorPalette.colors, ...this.backupColorPalette];
            }
        }
        return defaultColors;
    }
    getRadius(numberOfCharts) {
        if (numberOfCharts === 1) {
            return '50%';
        } else if (numberOfCharts == 2) {
            return '38%';         
        } else if (numberOfCharts == 5 || numberOfCharts == 6) {
            return '15%'
        }       
        return '28%';     
    }
    getInnerAndOuterRadius(numberOfCharts) {
        if (numberOfCharts === 1) {
            return  ["25%","50%"];
        } else if (numberOfCharts == 2){
            return ["38%","24%"]
        }
        return  ["28%","14%"];
    }
    getCenterOptions(numberOfCharts) {
        let centerOptions = [];
        if (numberOfCharts === 1) {
            centerOptions.push(['50%', '50%']);
        } else if (numberOfCharts === 2) {
            centerOptions.push(['20%', '40%']);
            centerOptions.push(['60%', '40%']);
        } else if (numberOfCharts === 3 || numberOfCharts === 4) {
            centerOptions.push(['20%', '25%']);
            centerOptions.push(['55%', '25%']);
            centerOptions.push(['20%', '65%']);
            centerOptions.push(['55%', '65%']);
        }  
        while(centerOptions.length < numberOfCharts){
            centerOptions.push(['75%', '75%']);
        }
        return centerOptions;
    }
    getSubTitleLocationConfiguration(numberOfCharts) {
        let locationConfigurationOptions = [];
        if (numberOfCharts === 1) {
            locationConfigurationOptions.push({left: "50%", top: "10%"});
        } else if (numberOfCharts === 2) {
            locationConfigurationOptions.push({left: "20%", top: "12%"});
            locationConfigurationOptions.push({left: "60%", top: "12%"});
        } else if (numberOfCharts === 3 || numberOfCharts === 4) {
            locationConfigurationOptions.push({left: "20%", top: "1%"});
            locationConfigurationOptions.push({left: "55%", top: "1%"});
            locationConfigurationOptions.push({left: "20%", top: "41%"});
            locationConfigurationOptions.push({left: "55%", top: "41%"});
        }
        return locationConfigurationOptions;
    }
    getBaseSubTitleConfiguration(subtext = ''){
        return{
            subtext: subtext,
            textAlign: 'center',
            subtextStyle: {
              fontWeight: "bolder",
              color: "#000",
              fontSize: 16,
              fontFamily: "Franklin Gothic Demi Cond",
            }
        }
    }    
    getDefaultAxisTextStyle() {
        return {
            color: "#000",
            fontSize: 10.5,
            fontFamily: "Franklin Gothic Demi Cond",
        }
    }
    useBlackTextForStackedBar() {
        return false;
    }
    getDefaultLabelTextStyle() {
        return {
            color: "#000",
            fontSize: 11,
            fontFamily: "Franklin Gothic Medium Cond"
        };
    }
    getDefaultLegendTextStyle() {
        return {
            color: "#000",
            fontSize: 11,
            fontFamily: "Franklin Gothic Demi Cond",
        };
    }    
    getDefaultLegendResponseRatesChart(){
        let legendTextStyle = { 
            color: "#000",
        };

        $.extend(true, legendTextStyle, this.getDefaultLegendTextStyle());
        return {
            show: this.getShowLegend(),
            textStyle: legendTextStyle,              
            borderColor: '#BFBFBF',
            width:"100%",
            orient: 'vertical',
            left: 780,
            top:40, 
            borderWidth: 1,
        }
    }    
    labelFormatter = (value, index) => {
        if (value.percent == 0){
            return '';
        }
        let percent = value.percent.toFixed(1);
        return  percent + " %";
    }
    barConfiguration(chartType,isMultiWave){
        if (chartType === "bar-horizontal"){
            if (isMultiWave == false) {
                return {
                    barGap: "0%",
                }
            }
            return {
                barGap: "0%",
            }
        } else if (chartType === "bar-vertical"){
            if (isMultiWave == false) {
                return {
                    barGap: "0%",
                }
            }
            return {
                barGap: "0%",
            }
        }
        return {
            barGap: "0%",
        }
    }
    stackedBarConfiguration(seriesCount,defaultBarWidth = 80,seriesCountMax = 4){
        if (seriesCount <= seriesCountMax){
            return {
                barGap: "0%",
                barWidth: defaultBarWidth,
            }
        }
        return {
            barGap: "0%",
        }
    }    
    toolTipForStackedAreaChart = (params) => {
        let returnValue = "";
        for (let i = 0; i < params.length; ++i) {
            let valueToDisplay = params[i].value;
            returnValue += params[i].marker += " ";
            returnValue += params[i].seriesName += " ";
            returnValue += "<span style='text-align:right'><b>" 
            if (valueToDisplay > 0){
                returnValue += valueToDisplay;
                returnValue += "%";
            } else {
                returnValue += "--";
            }
            returnValue += "</b></span>";
            returnValue += "<br>";
        }
        return returnValue;
    }       
    toolTipForLineChart = (params) => {
        let valueToDisplay = params.value;
        let returnValue = "";
        returnValue += params.marker += " ";
        returnValue += params.seriesName += " ";
        returnValue += "<br>";
        returnValue += "<span style='text-align:right'><b>" 
        if (valueToDisplay > 0){
            returnValue += valueToDisplay;
            returnValue += "%";
        } else {
            returnValue += "--";
        }        
        returnValue += "</b></span>";
        returnValue += "<br>";
        return returnValue;
    };  
    toolTipForPieChart = (params) => {
        let valueToDisplay = params.percent;
        let returnValue = "";
        returnValue += params.marker += " ";
        returnValue += params.name += " ";
        returnValue += "<br>";
        returnValue += "<span style='text-align:right'><b>" 
        if (valueToDisplay > 0){
            returnValue += valueToDisplay;
            returnValue += "%";
        } else {
            returnValue += "--";
        }        
        returnValue += "</b></span>";
        returnValue += "<br>";
        return returnValue;
    };  
    getDefaultToolTip(trigger = 'item'){
        return {
            trigger: trigger,
            backgroundColor: "#f2f2f2",
        }
    }
    getDefaultToolBox(app,modalId,reportPageTypeName,filename){
        let wRatings = app;
        let snapImage = 'image://'+window.location.origin + '/img/chart-headers/camera-duotone.png';
        let saveAsImage = 'image://'+window.location.origin + '/img/chart-headers/download-duotone.png';
        let thoughtImage = 'image://'+window.location.origin + '/img/chart-headers/thought-bubble-duotone.svg';

        let baseFeature = {
            myCustBut2: {
                name: 'snap',
                title: 'Snap',
                type: 'svg',
                icon: snapImage,
                onclick: function (wRatings) {
                    app.executeSnap();
                }
            },              
              saveAsImage: {
              title: 'Save as Image',
              icon: saveAsImage,
              pixelRatio: 2,
              name: filename
            }
          }
        let insightsConfiguration = {
            myCustBut1: {
                name: 'insights',
                title: 'Analysis',
                icon: thoughtImage,
                onclick: function (wRatings) {
                    app.$parent.displayInsights(modalId, reportPageTypeName);
                }
            }              
        };
        if (this.getShowSnap() == true){
            $.extend(true, baseFeature, insightsConfiguration);
        }
        return {
            show: this.getShowToolbox(),
            width: 100,
            height: 100,
            itemGap: 20,
            left: 0,
            //orient: "vertical",
            //right: 1,
            //top: 90,            
            feature: baseFeature
        }
/*           
        let dataView = {};   
            dataView:{
              title: 'Data View',
              readOnly: false,
              lang: ['Data View', 'Close', 'Refresh'],
              buttonColor: '#2f5eb3',
              optionToContent: function(opt) {
                  var axisData = opt.xAxis[0].data;
                  var series = opt.series;
                  var table = '<table ><tbody><tr>'
                               + '<td>Time:</td>'
                               + '<td>' + series[0].name + '</td>'
                               + '<td>' + series[1].name + '</td>'
                               + '</tr>';
                  for (var i = 0, l = axisData.length; i < l; i++) {
                      table += '<tr>'
                               + '<td>' + axisData[i] + '</td>'
                               + '<td>' + series[0].data[i] + '</td>'
                               + '<td>' + series[1].data[i] + '</td>'
                               + '</tr>';
                  }
                  table += '</tbody></table>';
                  return table;
              }                 
            },
            magicType: {
              title: {
                  line:"Line Chart"
              },
              type: [
                  "line","stack"
              ]
            },
*/            

    }   
    getGrid(includeBackgroud = true) {
        let baseGrid = {
            //left: 150,
            //right: 50,
            top: 40,
            width: 640,
            containLabel: true,
            //bottom: 50
        };
        if (includeBackgroud == true){
            $.extend(true, baseGrid, this.getGridBackground());
        }
        return baseGrid;
    }    
    getGridBackground() {
        return {
            backgroundColor: this.getBaseGridBackgroundColor(),
            show: true
        }
    }
    getMarkLine(isXAxis,coordinate = 50){
        let markLine = {
            type:'line',
            markLine: {
                lineStyle: {
                    type: 'solid',
                    color: "#000",
                },
                symbol: 'none',
                animation: false,
                label: {
                    show: false
                },
                data: [],
            }
        };
        let xAxisLine = {
            xAxis: coordinate
        };
        let yAxisLine = {
            yAxis: coordinate
        };
        if (isXAxis == true){
            markLine.markLine.data.push(xAxisLine);
        } else {
            markLine.markLine.data.push(yAxisLine);
        }
        return markLine;
    }
    getBaseGridBackgroundColor() {
        return "#F8F8F8";
    }
    getBaseLineWidth() {
        return 0.25;
    }
    getBaseLineColor() {
        return "#C8C8C8";
    }      
}