<template>
  <div class="map" :style="mapHeight">
    <chart :options="options"></chart>
  </div>
</template>
<script>
import Vue from "vue";
import $ from "jquery";

import Echarts from "vue-echarts";
import "echarts/lib/chart/map";
import usaJSON from "../assets/data/USA.json";
import canadaJSON from "../assets/data/canada.geo.json";
import worldJSON from "../assets/data/world.geo.json";
import afganistanJSON from "../assets/data/afganistan.geo.json";
import argentinaJSON from "../assets/data/argentina.geo.json";
import australiaJSON from "../assets/data/australia.geo.json";
import chinaJSON from "../assets/data/china.geo.json";
import columbiaJSON from "../assets/data/columbia.geo.json";
import franceJSON from "../assets/data/france.geo.json";
import germanyJSON from "../assets/data/germany.geo.json";
import indiaJSON from "../assets/data/india.geo.json";
import indonesiaJSON from "../assets/data/indonesia.geo.json";
import mexicoJSON from "../assets/data/mexico.geo.json";
import portugalJSON from "../assets/data/portugal.geo.json";
import spainJSON from "../assets/data/spain.geo.json";
import romaniaJSON from "../assets/data/romania.geo.json";
import thailandJSON from "../assets/data/thailand.geo.json";
import ukJSON from "../assets/data/uk.geo.json";

import brazilJSON from "../assets/data/brazil.geo.json";
import italyJSON from "../assets/data/italy.geo.json";
import peruJSON from "../assets/data/peru.geo.json";



// to generate JSON https://gadm.org/download_country.html
// use this to resize maps https://mapshaper.org/
// rename NAME_1 to name
import echarts from "echarts";
import { markRaw } from 'vue'; 
export default {
  name: "RespondentMap",
  components: {},
  computed: {
    mapHeight: function() {
      return "min-height: " + this.minHeight + "px";
    }
  },
  props: {
    minHeight: {
      type: String,
      default: "100"
    },
    mapType: {
      type: String,
      default: "USA"
    },
    mapData: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      options: {
        title: {
          text: "",
          subtext: "",
          sublink: "",
          left: "right"
        },
        tooltip: {
          trigger: "item",
          showDelay: 0,
          transitionDuration: 0.2,
          formatter: function(params) {
            //console.log(JSON.stringify(params));
            if (params.value != "null") {
              var value = (params.value + "").split(".");
              if (isNaN(value)) {
                value = 0;
              } else {
                value = value[0].replace(/(\d{1,3})(?=(?:\d{3})+(?!\d))/g, "$1,");
              }
              return params.seriesName + "<br/>" + params.name + ": " + value;
              //params.seriesName + "<br/>" + params.name.replace(/([a-z])([A-Z])/g, '$1 $2') + ": " + value;
            } else {
              return "";
            }
          }
        },
        visualMap: {
          show: false,
          dimension: 0,
          min: 0,
          max: 100,
          range: [0, 100],
          inRange: {
            color: "#8FAADC"
          },
          outOfRange: {
            color: "red"
          }
        },
        toolbox: {
          show: false,
          left: "left",
          top: "top"
        },
        series: [
          {
            name: "Respondents",
            type: "map",
            roam: false,
            map: "respondent-map",
            emphasis: {
              label: {
                show: true
              }
            },
            textFixed: {
              Alaska: [-131, 25, 15],
              Hawaii: [-110, 28, 5]
            },
            data: []
          }
        ]
      }
    };
  },
  watch: {
    mapData: function() {
      this.initCharts();
    },
    mapType: function() {
      this.initCharts();
    }
  },
  methods: {
    initCharts() {
      let chartDiv = $(".map")[0];
      this.chart = markRaw(echarts.init(chartDiv));
      if (this.mapType == "Canada") {
        echarts.registerMap("respondent-map", canadaJSON);
      } else if (this.mapType == "World") {
        echarts.registerMap("respondent-map", worldJSON);
      } else if (this.mapType == "Brazil") {
        echarts.registerMap("respondent-map", brazilJSON);
      } else if (this.mapType == "Peru") {
        echarts.registerMap("respondent-map", peruJSON);
      } else if (this.mapType == "Italy") {
        echarts.registerMap("respondent-map", italyJSON);
      } else if (this.mapType == "China") {
        echarts.registerMap("respondent-map", chinaJSON);
      } else if (this.mapType == "Afganistan") {
        echarts.registerMap("respondent-map", afganistanJSON);
      } else if (this.mapType == "Argentina") {
        echarts.registerMap("respondent-map", argentinaJSON);
      } else if (this.mapType == "Australia") {
        echarts.registerMap("respondent-map", australiaJSON);
      } else if (this.mapType == "Columbia") {
        echarts.registerMap("respondent-map", columbiaJSON);
      } else if (this.mapType == "France") {
        echarts.registerMap("respondent-map", franceJSON);
      } else if (this.mapType == "Germany") {
        echarts.registerMap("respondent-map", germanyJSON);
      } else if (this.mapType == "India") {
        echarts.registerMap("respondent-map", indiaJSON);
      } else if (this.mapType == "Indonesia") {
        echarts.registerMap("respondent-map", indonesiaJSON);
      } else if (this.mapType == "Mexico") {
        echarts.registerMap("respondent-map", mexicoJSON);
      } else if (this.mapType == "Portugal") {
        echarts.registerMap("respondent-map", portugalJSON);
      } else if (this.mapType == "Romania") {
        echarts.registerMap("respondent-map", romaniaJSON);
      } else if (this.mapType == "Spain") {
        echarts.registerMap("respondent-map", spainJSON);
      } else if (this.mapType == "Thailand") {
        echarts.registerMap("respondent-map", thailandJSON);
      } else if (this.mapType == "UK") {
        echarts.registerMap("respondent-map", ukJSON);
      } else {
        echarts.registerMap("respondent-map", usaJSON);
      }
      this.options.series[0].data = this.mapData;
      this.chart.setOption(this.options, true);
      let imageUrl =  this.chart.getDataURL({
        pixelRatio: 2,
        backgroundColor: '#fff'
      });     
      this.$emit('save-image',imageUrl);
    }
  },
  mounted() {
    this.initCharts();
  }
};
</script>
