import $ from "jquery";
import axios from "axios";
import moment from "moment";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import ClientService from "./client.service";
import AdminService from "./admin.service";
import UserService from "./user.service";
import SurveyService from "./survey.service";
import TableService from "./table.service";

const BASE_URL = AdminService.getBaseUrl();
const API_URL = BASE_URL + "/api/v1/dashboard";
const DEFAULT_FONT_SIZE = 10;

const response = {
  data: {
    statusCode: 200,
    message: "placeholder message"
  }
};
function wordBreakFormatter(name) {
  if (typeof name != "undefined") {
    return name.replace('-',' ').split(" ").join("\n")
  }
}
function timePeriodFormatter(name) {
  if (typeof name != "undefined" && name.includes("P")) {
    if (name.endsWith("P1")) {
      let index = name.indexOf("-");
      let year = name.substr(0, index - 1);
      let abbreviatedYear = name.substr(2, 3);
      abbreviatedYear = "'" + abbreviatedYear;
      return abbreviatedYear;
    } else {
      return "";
    }
  }
  return name;
}
function getCompetititveYAxis(max = 5,splitLineColor = "#fff") {
  return {
    type: "value",
    data: [],
    min: 0,
    max: max,
    minInterval: 1,
    boundaryGap: true,
    inverse: true,
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      fontSize: DEFAULT_FONT_SIZE,
      showMinLabel: false,
      showMaxLabel: false,
      fontFamily: "Franklin Gothic Demi Cond"
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: splitLineColor,
        width: 0.25
      }
    }
  };
}
function getCompetitiveXAxis(categories) {
  let configuration = {
    type: "category",
    data: categories,
    boundaryGap: false,
    splitLine: {
      show: true,
      lineStyle: {
        color: "#C8C8C8",
        width: 0.25
      }
    },
    axisTick: {
      show: false
    },
    axisLine: {
      show: false
    },
    axisLabel: {
      rotate: 25,
      fontFamily: "Franklin Gothic Demi Cond",
      fontSize: DEFAULT_FONT_SIZE
    }
  };

  if (typeof categories != "undefined") {
    $.extend(true, configuration, { data: categories });
  }
  return configuration;
}
class DashboardService {
	DASHBOARD_PERIOD_SETTING_TYPE= "dashboard-period";
  DASHBOARD_START_PERIOD_SETTING_TYPE= "dashboard-start-period";
	DASHBOARD_RESPONDENTS_MAP_VIEW_SETTING_TYPE= "dashboard-respondents-map-view-by";
	DASHBOARD_PERFORMANCE_TRENDS_PERFORMANCE_SETTING_TYPE= "dashboard-performance-trends-performance";
  DASHBOARD_METRIC_CONTRIBUTIONS_METRIC_SETTING_TYPE= "dashboard-metric-contributions-metric";
	DASHBOARD_PERFORMANCE_TRENDS_METRIC_SETTING_TYPE= "dashboard-performance-trends-metric";
	DASHBOARD_COMPETITIVE_TRENDS_COMPETITOR_SET_SETTING_TYPE= "dashboard-competitive-trends-competitor-set";
	DASHBOARD_COMPETITIVE_TRENDS_RANK_BY_SETTING_TYPE= "dashboard-competitive-trends-rank-by";
	DASHBOARD_EXPECTATIONS_MAP_BY_SETTING_TYPE= "dashboard-expectations-map-by";
	DASHBOARD_COMPETITIVE_RANK_COMPETITOR_SET_SETTING_TYPE= "dashboard-competitive-rank-competitor-set";

  DASHBOARD_NEEDS_PROFILE_COMPETITOR_SETTING_TYPE= "dashboard-needs-profile-competitor";
  DASHBOARD_GROWTH_SEQUENCE_VIEW_COMPETITOR_TYPE= "dashboard-growth-sequence-competitor";
  DASHBOARD_WINNERS_MAP_VIEW_COMPETITOR_TYPE= "dashboard-winners-map-competitor";

	DASHBOARD_NEEDS_PROFILE_VIEW_SETTING_TYPE= "dashboard-needs-profile-view";
  DASHBOARD_GROWTH_SEQUENCE_VIEW_SETTING_TYPE= "dashboard-growth-sequence-view";
  DASHBOARD_WINNERS_MAP_VIEW_SETTING_TYPE= "dashboard-winners-map-view";

  DASHBOARD_NEEDS_PROFILE_COMPETITOR_SETTING_TYPE= "dashboard-needs-profile-competitor";
  DASHBOARD_GROWTH_SEQUENCE_COMPETITOR_SETTING_TYPE= "dashboard-growth-sequence-competitor";
  DASHBOARD_WINNERS_MAP_COMPETITOR_SETTING_TYPE= "dashboard-winners-map-competitor";

  DASHBOARD_NPS_RANK_GROUP_SETTING_TYPE = "dashboard-nps-rank-group";
  DASHBOARD_NPS_RANK_NUMBER_OF_TIERS_SETTING_TYPE = "dashboard-nps-rank-number-of-tiers";
  DASHBOARD_MOAT_SCORE_RANK_GROUP_SETTING_TYPE = "dashboard-moat-score-rank-group";
  DASHBOARD_MOAT_SCORE_RANK_NUMBER_OF_TIERS_SETTING_TYPE = "dashboard-moat-score-rank-number-of-tiers";
  DASHBOARD_W_SCORE_RANK_GROUP_SETTING_TYPE = "dashboard-w-score-rank-group";
  DASHBOARD_W_SCORE_RANK_NUMBER_OF_TIERS_SETTING_TYPE = "dashboard-w-score-rank-number-of-tiers";  
  DASHBOARD_MOAT_COMPS_COMPANY_SETTING_TYPE = "dashboard-moat-comps-company";
  DASHBOARD_MOAT_COMPS_GROUP_SETTING_TYPE = "dashboard-moat-comps-group";
  DASHBOARD_MONEYBALL_STICK_STICK_TYPE_SETTING_TYPE = "dashboard-moneyball-stick-stick-type";
  DASHBOARD_MONEYBALL_STICK_BY_TYPE_SETTING_TYPE = "dashboard-moneyball-stick-by-type";

  setDashboardSetting(defaultDashboardValues, userSettingTypeName, value){
    if (typeof defaultDashboardValues != "undefined") {
      let defaultDashboardValue = defaultDashboardValues.find(x => x.userSettingTypeName == userSettingTypeName);
      if (typeof defaultDashboardValue != "undefined") {
        defaultDashboardValue.userSettingValue = value;
      }
    }
    return defaultDashboardValues;
  }
  getDashboardSetting(defaultDashboardValues, userSettingTypeName, defaultValue, options = []){
    if (Array.isArray(defaultDashboardValues)){
      let defaultDashboardValue = defaultDashboardValues.find(x => x.userSettingTypeName == userSettingTypeName);
      if (typeof defaultDashboardValue != "undefined") {
        let returnValue = defaultDashboardValue.userSettingValue;
        let optionValue = options.find(x => x.value == returnValue);
        if (typeof optionValue != "undefined") {
          return returnValue;
        }
      }
      return defaultValue;    
    }
  }
  setW30AsDefaultSurveyGroup(surveyGroupOptions, inputSurveyGroupId){
    let w30IndexSurveyGroup = surveyGroupOptions.find(x => x.name == "W-30 Index");
    if (typeof w30IndexSurveyGroup != "undefined") {
      return w30IndexSurveyGroup.value;
    }
    return inputSurveyGroupId
  }  
  displayDashboardCard(layoutCards, code){
    var match = layoutCards.find((x) => x.dashboardCardTypeCode == code);
    return typeof match != "undefined";
  }  
  getDashboardLayoutCard(layoutCards, code){
    var match = layoutCards.find((x) => x.dashboardCardTypeCode == code);
    if (typeof match != "undefined") {
      return match;
    } else {
      return {
        dashboardCardTypeCode: "",
        sequenceNumber: 100,
        dashboardCardId: 0,
        id: 0
      };
    }
  }
  getParameters(clientCode, clientTimePeriodId) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("clientTimePeriodId", clientTimePeriodId);
    params.append("userId", UserService.getUserId());
    params.append("surveyId", SurveyService.getSurveyId());
    return params;
  }
  async getDashboardCardDataList(callback, chartApp) {
    await axios
    .get(API_URL + "/get-dashboard-card-data", { headers: await authHeader() })
    .then(response => callback(response))
    .catch(error => {
      error.message += ErrorService.mergeError(error.response);
      ErrorService.processError("DashboardService.getBenchmarkSummaryDataList", error, "admin", chartApp);
    });    
  }
  async importDashboardCardData(file, callback, chartApp) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", UserService.getUserId());

    let headers = { "Content-Type": "multipart/form-data" };
    $.extend(headers, await authHeader());

    await axios
    .post(API_URL + "/import-dashboard-card-data", formData, { headers: headers })
    .then(response => callback(response))
    .catch(error => {
      error.message += ErrorService.mergeError(error.response);
      ErrorService.processError("DashboardService.importDashboardCardData", error, "admin", chartApp);
    });  

  }
  getTableColumnData() {
    return [
      { title: "User Id", data: "userId", name: "userId", class: "text-left", width: "4rem" },
      { title: "Username", data: "userName", name: "userName", width: "10rem" },
      {
        title: "Dashboard Card Id",
        data: "dashboardCardId",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
        className: "",
        name: "dashboardCardId",
        width: "6rem"
      },
      { title: "Dashboard Card Type Id", data: "dashboardCardTypeId", name: "dashboardCardTypeId", class: "text-left", width: "10rem" },
      { title: "Dashboard Card Name", data: "dashboardCardTypeName", name: "dashboardCardTypeName", width: "10rem" },
      { title: "Dashboard Card Code", data: "dashboardCardTypeCode", name: "dashboardCardTypeCode", width: "8rem" },
      { title: "Sequence Number", data: "sequenceNumber", name: "sequenceNumber", class: "text-left", width: "5rem" },
      {
        title: "Date Created",
        data: "createdDate",
        type: "date",
        name: "createdDate",
        class: "text-left",
        width: "5rem",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      },
      {
        title: "Date Updated",
        data: "updatedDate",
        type: "date",
        name: "updatedDate",
        class: "text-left",
        width: "5rem",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      } 
    ];
  }

  async getWinnersMap(value, clientCompetitorId, view, callback, chartApp) {
    let clientTimePeriodId = value;
    let clientId = ClientService.getClientId();
    let surveyId = SurveyService.getSurveyId();
    if (surveyId == 0){
      chartApp.displayDashboard = true;
    }    
    if (clientId != 0 && surveyId != 0) {
      const params = new URLSearchParams();
      params.append("clientCode", ClientService.getClientCode());
      params.append("clientTimePeriodId", clientTimePeriodId);
      params.append("userId", UserService.getUserId());
      params.append("view", view);
      params.append("clientCompetitorId", clientCompetitorId);
      params.append("surveyId", surveyId);
      await axios
        .post(API_URL + "/get-winners-map", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          if (!error.message.includes("getAttribute")) {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("DashboardService.getNeedsProfile", error, "dashboard", chartApp);
          }
        });
    }
  }
  async getGrowthSequence(value, clientCompetitorId, view, callback, chartApp) {
    let clientTimePeriodId = value;
    let clientId = ClientService.getClientId();
    let surveyId = SurveyService.getSurveyId();
    if (surveyId == 0){
      chartApp.displayDashboard = true;
    }    
    if (clientId != 0 && surveyId != 0) {
      const params = new URLSearchParams();
      params.append("clientCode", ClientService.getClientCode());
      params.append("clientTimePeriodId", clientTimePeriodId);
      params.append("view", view);
      params.append("userId", UserService.getUserId());
      params.append("clientCompetitorId", clientCompetitorId);
      params.append("surveyId", surveyId);
      await axios
        .post(API_URL + "/get-growth-sequence", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          if (!error.message.includes("getAttribute")) {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("DashboardService.getGrowthSequence", error, "dashboard", chartApp);
          }
        });
    }
  }
  async getNeedsProfile(value, clientCompetitorId, view, callback, chartApp) {
    let clientTimePeriodId = value;
    let clientId = ClientService.getClientId();
    let surveyId = SurveyService.getSurveyId();
    if (surveyId == 0){
      chartApp.displayDashboard = true;
    }    
    if (clientId != 0 && surveyId != 0) {
      const params = new URLSearchParams();
      params.append("clientCode", ClientService.getClientCode());
      params.append("clientTimePeriodId", clientTimePeriodId);
      params.append("view", view);
      params.append("userId", UserService.getUserId());
      params.append("clientCompetitorId", clientCompetitorId);
      params.append("surveyId", surveyId);
      await axios
        .post(API_URL + "/get-needs-profile", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          if (!error.message.includes("getAttribute")) {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("DashboardService.getNeedsProfile", error, "dashboard", chartApp);
          }
        });
    }
  }
  async getCompetitiveRank(value, competitorSet, callback, chartApp) {
    let clientTimePeriodId = value;
    let clientId = ClientService.getClientId();
    let surveyId = SurveyService.getSurveyId();
    if (surveyId == 0){
      chartApp.displayDashboard = true;
    }    
    if (clientId != 0 && surveyId != 0) {
      const params = new URLSearchParams();
      params.append("clientCode", ClientService.getClientCode());
      params.append("clientTimePeriodId", clientTimePeriodId);
      params.append("userId", UserService.getUserId());
      params.append("competitorSet", competitorSet);
      params.append("surveyId", surveyId);
      await axios
        .post(API_URL + "/get-competitive-rank", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          if (!error.message.includes("getAttribute")) {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("DashboardService.getCompetitiveRank", error, "dashboard", chartApp);
          }
        });
    }
  }
  async getMoneyballStick(period, stickType, stickByOptions,callback, chartApp) {
    let clientId = ClientService.getClientId();
    let surveyId = SurveyService.getSurveyId();
    if (surveyId == 0){
      chartApp.displayDashboard = true;
    }
    if (clientId != 0 && surveyId != 0) {
      const params = new URLSearchParams();
      params.append("clientCode", ClientService.getClientCode());
      params.append("surveyId", surveyId);
      params.append("stickType", stickType);
      params.append("stickByOptions", stickByOptions);
      params.append("userId", UserService.getUserId());
      params.append("period", period);
      await axios
        .post(API_URL + "/get-moneyball-stick", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          if (!error.message.includes("getAttribute")) {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("DashboardService.getMoneyballStick", error, "survey-designer", chartApp);
          }
        });
    }
  }
  async getMoatComps(period, surveyGroupId, competitor,callback, chartApp) {
    let clientId = ClientService.getClientId();
    let surveyId = SurveyService.getSurveyId();
    if (surveyId == 0){
      chartApp.displayDashboard = true;
    }
    if (clientId != 0 && surveyId != 0) {
      const params = new URLSearchParams();
      params.append("clientCode", ClientService.getClientCode());
      params.append("surveyId", surveyId);
      params.append("surveyGroupId", surveyGroupId);
      params.append("competitorId", competitor);
      params.append("userId", UserService.getUserId());
      params.append("period", period);
      await axios
        .post(API_URL + "/get-moat-comps", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          if (!error.message.includes("getAttribute")) {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("DashboardService.clientId", error, "survey-designer", chartApp);
          }
        });
    }
  }
  async getPredictiveAnalytics(metricGroupId, period, useMetricGrowth, saveMetric, callback, chartApp) {
    let clientId = ClientService.getClientId();
    let surveyId = SurveyService.getSurveyId();
    if (surveyId == 0){
      chartApp.displayDashboard = true;
    }
    if (clientId != 0 && surveyId != 0) {
      const params = new URLSearchParams();
      params.append("clientCode", ClientService.getClientCode());
      params.append("surveyId", surveyId);
      params.append("userId", UserService.getUserId());
      params.append("period", period);
      params.append("saveMetric", saveMetric);
      params.append("metricGroupId", metricGroupId);
      params.append("useMetricGrowth", useMetricGrowth);
      await axios
        .post(API_URL + "/get-predictive-analytics", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          if (!error.message.includes("getAttribute")) {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("DashboardService.getPredictiveAnalytics", error, "survey-designer", chartApp);
          }
        });
    }
  }
  async getExpectationsMap(value, expectationsBy, callback, chartApp) {
    let clientTimePeriodId = value;
    let clientId = ClientService.getClientId();
    let surveyId = SurveyService.getSurveyId();
    if (surveyId == 0){
      chartApp.displayDashboard = true;
    }    
    if (clientId != 0 && surveyId != 0) {
      const params = new URLSearchParams();
      params.append("clientCode", ClientService.getClientCode());
      params.append("expectationsBy", expectationsBy);
      params.append("clientTimePeriodId", clientTimePeriodId);
      params.append("userId", UserService.getUserId());
      params.append("surveyId", surveyId);
      await axios
        .post(API_URL + "/get-expectations-map", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          if (!error.message.includes("getAttribute")) {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("DashboardService.getExpectationsMap", error, "dashboard", chartApp);
          }
        });
    }
  }
  async getCompetitiveTrends(rankBy, competitorSet, startTimePeriodId, callback, chartApp) {
    if (!UserService.isLoggedIn()) {
      return;
    }
    let clientId = ClientService.getClientId();
    let surveyId = SurveyService.getSurveyId();
    if (surveyId == 0){
      chartApp.displayDashboard = true;
    }
    if (clientId != 0 && surveyId != 0) {
      const params = new URLSearchParams();
      params.append("clientCode", ClientService.getClientCode());
      params.append("rankBy", rankBy);
      params.append("competitorSet", competitorSet);
      params.append("userId", UserService.getUserId());
      params.append("surveyId", surveyId);
      params.append("startTimePeriodId", startTimePeriodId);
      await axios
        .post(API_URL + "/get-competitive-trends", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          if (!error.message.includes("getAttribute")) {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("DashboardService.getCompetitiveTrends", error, "dashboard", chartApp);
          }
        });
    }
  }
  async getRanks(numberOfTiers, surveyGroupId, rankType, period, surveyCode, callback, chartApp) {
    if (!UserService.isLoggedIn()) {
      return;
    }
    if (surveyGroupId == ""){
      surveyGroupId = 0;
    }
    const params = new URLSearchParams();
    params.append("clientCode", ClientService.getClientCode());
    params.append("numberOfTiers", numberOfTiers);
    params.append("surveyGroupId", surveyGroupId);
    params.append("rankType", rankType);
    params.append("period", period);
    params.append("surveyCode", surveyCode);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_URL + "/get-ranks", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        if (!error.message.includes("getAttribute")) {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("DashboardService.getRanks", error, "survey-designer", chartApp);
        }
      });
  }

  async getPerformanceTrends(performance, metric, useMetricGrowth, startTimePeriodId, callback, chartApp) {
    if (!UserService.isLoggedIn()) {
      return;
    }
    let clientId = ClientService.getClientId();
    let surveyId = SurveyService.getSurveyId();
    if (surveyId == 0){
      chartApp.displayDashboard = true;
    }
    if (clientId != 0 && surveyId != 0) {
      const params = new URLSearchParams();
      params.append("clientCode", ClientService.getClientCode());
      params.append("performance", performance);
      params.append("metric", metric);
      params.append("useMetricGrowth", useMetricGrowth);
      params.append("userId", UserService.getUserId());
      params.append("startTimePeriodId", startTimePeriodId);
      params.append("surveyId", surveyId);
      await axios
        .post(API_URL + "/get-performance-trends", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          if (!error.message.includes("getAttribute")) {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("DashboardService.getPerformanceTrends", error, "survey-designer", chartApp);
          }
        });
    }
  }
  async getNPSTrends(value, startTimePeriodId, callback, chartApp) {
    let clientTimePeriodId = value;
    let clientId = ClientService.getClientId();
    let surveyId = SurveyService.getSurveyId();
    if (surveyId == 0){
      chartApp.displayDashboard = true;
    }    
    if (clientId != 0 && surveyId != 0) {
      const params = new URLSearchParams();
      params.append("clientCode", ClientService.getClientCode());
      params.append("clientTimePeriodId", clientTimePeriodId);
      params.append("userId", UserService.getUserId());
      params.append("surveyId", surveyId);
      params.append("startTimePeriodId", startTimePeriodId);
      await axios
        .post(API_URL + "/get-nps-trends", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          if (!error.message.includes("getAttribute")) {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("DashboardService.getNPSTrends", error, "dashboard", chartApp);
          }
        });
    }
  }
  async getRespondents(ctpId, viewBy, callback, chartApp) {
    let clientTimePeriodId = ctpId;
    let clientId = ClientService.getClientId();
    let surveyId = SurveyService.getSurveyId();
    if (surveyId == 0){
      chartApp.displayDashboard = true;
    }
    if (clientId != 0 && surveyId != 0) {
      const params = new URLSearchParams();
      params.append("clientCode", ClientService.getClientCode());
      params.append("clientTimePeriodId", clientTimePeriodId);
      params.append("userId", UserService.getUserId());
      params.append("surveyId", surveyId);
      params.append("viewBy", viewBy);
      await axios
        .post(API_URL + "/get-respondents", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          if (!error.message.includes("getAttribute")) {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("DashboardService.getNumberOfRepondents", error, "survey-designer", chartApp);
          }
        });
    }
  }
  async getCompetitorSet(callback, chartApp) {
    if (!UserService.isLoggedIn()) {
      return;
    }
    let surveyId = SurveyService.getSurveyId();
    if (surveyId == 0){
      chartApp.displayDashboard = true;
    } else {
      const params = new URLSearchParams();
      params.append("userId", UserService.getUserId());
      params.append("surveyId", surveyId);

      await axios
        .post(API_URL + "/get-competitor-set-by-survey-id", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("DashboardService.getCompetitorSet", error, "admin", chartApp);
        });
    }
  }
  async getDashboardCardTypes(callback, chartApp) {
    if (!UserService.isLoggedIn()) {
      return;
    }
    await axios
      .get(API_URL + "/get-dashboard-card-types", { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("DashboardService.getDashboardCardTypes", error, "admin", chartApp);
      });
  }
  async saveAllDashboardCardTypesAsCards(dashboardCardTypes) {
    localStorage.setItem("dashboard-layout-cards", JSON.stringify(dashboardCardTypes));
  }
  async getAllDashboardCardTypesAsCards() {
    if (localStorage.getItem("dashboard-layout-cards") != null) {
      return JSON.parse(localStorage.getItem("dashboard-layout-cards"));
    } 
    return [];
  }
  async getAllDashboardCardTypesAsCardsFromDatabase(callback, chartApp) {
    if (!UserService.isLoggedIn()) {
      return;
    }
    const params = new URLSearchParams();
    params.append("userId", UserService.getUserId());

    await axios
      .post(API_URL + "/get-all-dashboard-card-types-as-cards", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("DashboardService.getDashboardCards", error, "admin", chartApp);
      });
  }
  async getDashboardCards(userId, callback, chartApp) {
    if (!UserService.isLoggedIn()) {
      return;
    }
    const params = new URLSearchParams();
    params.append("userId", userId);

    await axios
      .post(API_URL + "/get-dashboard-cards", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("DashboardService.getDashboardCards", error, "admin", chartApp);
      });
  }
  async saveDashboardCards(userId, dashboardCards, callback, chartApp) {
    if (!UserService.isLoggedIn()) {
      return;
    }
    const params = new URLSearchParams();
    params.append("dashboardCards", JSON.stringify(dashboardCards));
    params.append("userId", userId);

    await axios
      .post(API_URL + "/save-dashboard-cards", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("DashboardService.getDashboardCards", error, "admin", chartApp);
      });
  }
  getRivalOptions() {
    return [
      { name: "Survey", value: "survey" },
      { name: "Organization (Level)", value: "org" },
      { name: "Benchmark", value: "w30" }
    ];
  }
  setSeries(baseSeries, finalSeries, dynamicOptions) {
    if (typeof dynamicOptions != "undefined" && typeof dynamicOptions.series != "undefined" && typeof finalSeries != "undefined") {
      finalSeries = [];
      for (var seriesIndex = 0; seriesIndex < dynamicOptions.series.length; seriesIndex++) {
        let newSeries = {};
        $.extend(true, newSeries, baseSeries);
        $.extend(true, newSeries, dynamicOptions.series[seriesIndex]);
        finalSeries.push(newSeries);
      }
    }
    return finalSeries;
  }
  setIndividualSeries(baseSeries, seriesIndex, dynamicOptions) {
    let newSeries = {};
    $.extend(true, newSeries, baseSeries);
    if (typeof dynamicOptions != "undefined" && typeof dynamicOptions.series != "undefined" && typeof dynamicOptions.series[seriesIndex] != "undefined") {
      $.extend(true, newSeries, dynamicOptions.series[seriesIndex]);
      dynamicOptions.series[seriesIndex] = JSON.parse(JSON.stringify(newSeries));
    }
  }
  setLegend(finalLegend, dynamicOptions) {
    if (
      typeof dynamicOptions != "undefined" &&
      typeof dynamicOptions.legend != "undefined" &&
      typeof dynamicOptions.legend.data != "undefined" &&
      typeof finalLegend != "undefined"
    ) {
      finalLegend = dynamicOptions.legend.data;
    }
    return finalLegend;
  }

  getExpectationsMapOptions(echarts) {
    var formatUtil = echarts.format;
    let option = {
      title: {
        show: false
      },
      tooltip: {
        formatter: function(info) {
          var value = info.value;
          var treePathInfo = info.treePathInfo;
          var treePath = [];

          for (var i = 1; i < treePathInfo.length; i++) {
            treePath.push(treePathInfo[i].name);
          }

          return ['<div class="tooltip-title">' + formatUtil.encodeHTML(treePath.join(" / ")) + "</div>"].join("");
        }
      },
      textStyle: {
        fontFamily: "Franklin Gothic Medium Cond",
        fontSize: 12
      },
      series: {}
    };
    return option;
  }
  getCompetitiveRankOptions(competitorSet, max) {
    let categories = ["", "Willingness to Pay", "Satisfaction", "Satisfaction Gaps", "Emotions Gaps", "Value Gap", "NPS", ""];
    let option = {
      title: {
        text: ""
      },
      grid: {
        top: 10,
        left: "8%",
        right: "6%",
        bottom: "20%",
        show: true,
        backgroundColor: this.getBaseGridBackgroundColor()
      },
      legend: {
        data: [],
        textStyle: {
          fontSize: DEFAULT_FONT_SIZE,
          fontFamily: "Franklin Gothic Demi Cond",
            color: "#000"
        },
        show: false
      },
      tooltip: {
        position: "top",
        formatter: function(params) {
          let returnValue = params.value[2] + " ranks #" + params.value[1] + " out of " + params.value[3];
          returnValue += " <br> ";
          returnValue += params.value[4];
          return returnValue;
        }
      },
      xAxis: getCompetitiveXAxis(categories),
      yAxis: getCompetititveYAxis(max),
      series: []
    };
    return option;
  }
  getPredictiveAnalyticsOptions() {
    var hours = ["Performance", "Gap", "Willingness to Pay", "Predictive"];
    var days = ["Product Quality", "Safe to Use", "Innovation", "Product Usefulness", "Brand Reputation"];
    var data = [];

    data = data.map(function(item) {
      return [item[1], item[0], item[2] || "-"];
    });
    let option = {
      tooltip: {
        position: "top"
      },
      animation: false,
      grid: {
        height: "50%",
        top: 10,
        left: "150px"
      },
      xAxis: {
        type: "category",
        data: hours,
        splitArea: {
          show: true
        }
      },
      yAxis: {
        type: "category",
        data: days,
        splitArea: {
          show: true
        }
      },
      legend: {
        textStyle: {
          fontSize: DEFAULT_FONT_SIZE,
          fontFamily: "Franklin Gothic Demi Cond",
        },
        show: false
      },      
      visualMap: {
        min: 0,
        max: 10,
        calculable: true,
        orient: "horizontal",
        left: "center",
        bottom: "15%"
      },
      series: [
        {
          name: "Punch Card",
          type: "heatmap",
          data: data,
          label: {
            normal: {
              show: true
            }
          },
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          },
          width: "95%",
          height: "100%"
        }
      ]
    };
    return option;
  }
  getCompetitiveTrendsOptions(competitorSet, max) {
    let splitLineColor = this.getBaseLineColor();
    return {
      title: {
        text: ""
      },
      tooltip: {
        trigger: "axis",

        formatter: function(params) {
          // 2019 - Q4,2,RoadRunner,5,2019 - Q4: ,Challenger,1,RoadRunner,2
          if (typeof params[0].value != "undefined") {
            let mainCompanyRankingObject = params[0].value;
            let timePeriod = mainCompanyRankingObject[0];
            let mainRanking = mainCompanyRankingObject[1];
            let mainCompany = mainCompanyRankingObject[2];
            let timePeriodDisplayName = mainCompanyRankingObject[4];

            let returnValue = timePeriodDisplayName;
            returnValue += " <br> ";
            returnValue += mainCompany + " : " + mainRanking;

            if (typeof mainCompanyRankingObject[5] != "undefined") {
              let otherRankings = mainCompanyRankingObject[5];
              $.each(otherRankings, function(i, item) {
                returnValue += " <br> ";
                returnValue += item.name + " : " + item.ranking;
              });
            }
            return returnValue;
          }
        }
      },
      grid: {
        top: 10,
        left: "3%",
        right: "4%",
        bottom: "3%",
        backgroundColor: this.getBaseGridBackgroundColor(),
        containLabel: true,
        show: true
      },
      legend: {
        textStyle: {
          fontSize: DEFAULT_FONT_SIZE,
          fontFamily: "Franklin Gothic Demi Cond",
        },
        show: false
      },
      yAxis: getCompetititveYAxis(max,splitLineColor),
      xAxis: {
        type: "category",
        data: [],
        boundaryGap: true,
        splitLine: {
          show: false,
          lineStyle: {
            color: this.getBaseLineColor(),
            width: this.getBaseLineWidth()
          }
        },
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisLabel: {
          fontSize: DEFAULT_FONT_SIZE,
          fontFamily: "Franklin Gothic Demi Cond",
          formatter: function(name) {
            return timePeriodFormatter(name);
          }
        }
      }
    };
  }
  getNeedsProfileOptions(echarts) {
    let option = {
      legend: {
        left: 0,
        type: 'scroll',
        textStyle: {
          fontSize: DEFAULT_FONT_SIZE,
          fontFamily: "Franklin Gothic Demi Cond",
        },
        itemWidth:15,
        itemStyle: {
          decal: {
            symbolSize: 0,
            symbolKeepAspect: true
          }
        },        
        show: true
      },
      grid: {
        top: 10,
        left: "10%",
        right: "12%",
        show: true,
        backgroundColor: this.getBaseGridBackgroundColor()
      },       
      xAxis: {
        show: true,
        axisLabel: {
          fontSize: DEFAULT_FONT_SIZE,
          fontFamily: "Franklin Gothic Demi Cond",
          formatter: function(name) {
            return wordBreakFormatter(name);
          },
          interval: 0
        },    
        
        axisTick: {
          show: false
        },            
        type: 'category',
        data: []
      },
      yAxis: [
        {
          "boundaryGap": true,
          "type": "value",
          "axisTick": {
            show: false
          },
          "axisLabel": {
            "fontSize": 10,
            "fontFamily": "Franklin Gothic Demi Cond",
            color: "#1663E0",
            "formatter": "{value}"
          },
          "splitLine": {
            show: true,
            lineStyle: {
              color: this.getBaseLineColor(),
              width: this.getBaseLineWidth()
            }
          },
          min: 1,
          max: 10,
          splitNumber: 10,
          axisLine: {
            show: false,
            lineStyle: {
              color: "#000"
            }
          }
        },
        {
          type: "value",
          boundaryGap: true,
          splitLine: {
            show: false,
            color: this.getBaseLineColor(),
            width: this.getBaseLineWidth()
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#000"
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: "#339966",
            fontFamily: "Franklin Gothic Demi Cond",
            fontSize: 10,
            formatter: "{value} %"
          },
          min: 0,
          splitNumber: 5,
        }
          ],
      series: [ {},{},{},{} ],
      tooltip: {
        trigger: "axis",
      },

    };
    return option;
  }
  getPerformanceTrendsOptions(echarts,clientColor = "#1663E0") {
    let option = {
      backgroundColor: "#fff",
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        },
        formatter: function(params) {
          let returnValue = "";
          if (params.length > 0 && typeof params[0].value != "undefined") {
            let metricObject = params[0].value;
            
            let metricValue = metricObject[1];
            let timePeriodDisplayName = metricObject[2];
            let metricName = metricObject[3];
            let prefix = metricObject[4];
            let suffix = metricObject[5];

            returnValue = timePeriodDisplayName;
            returnValue += " <br> ";
            returnValue += metricName + " : " + prefix + " " + metricValue + suffix;
          }
          if (params.length > 1 && typeof params[1].value != "undefined") {
            let performanceObject = params[1].value;
            let performanceValue = performanceObject[1];
            let performanceName = performanceObject[3];
            let performancePrefix = performanceObject[4];
            let performanceSuffix = performanceObject[5];
            let performanceDisplay = performanceObject[6];

            returnValue += " <br> ";
            returnValue += performanceName + " : " + performanceDisplay;
          }
          return returnValue;
        }        
      },
      legend: {
        data: ["Revenue", "Satisfaction"],
        show: false,
        textStyle: {
          fontSize: DEFAULT_FONT_SIZE,
          fontFamily: "Franklin Gothic Demi Cond",
          color: "#000"
        }
      },
      grid: {
        top: 10,
        left: "13%",
        right: "16%",
        bottom: "12%",
        show: true,
        backgroundColor: this.getBaseGridBackgroundColor()
      },
      xAxis: {
        data: [],
        axisTick: {
          show: false
        },
        axisLabel: {
          fontSize: DEFAULT_FONT_SIZE,
          fontFamily: "Franklin Gothic Demi Cond",
          formatter: function(name) {
            return timePeriodFormatter(name);
          }
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: "#000"
          }
        },
        splitLine: {
          show: true,
          interval: 3,
          lineStyle: {
            color: "#fff",
          }
        }
      },
      yAxis: [
        {
          boundaryGap: true,
          type: "value",
          axisTick: {
            show: false
          },
          axisLabel: {
            fontSize: DEFAULT_FONT_SIZE,
            fontFamily: "Franklin Gothic Demi Cond",
            color: clientColor
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: this.getBaseLineColor(),
              width: this.getBaseLineWidth()
            }
          },
          min: 1,
          max: 10,
          splitNumber: 10,
          axisLine: {
            show: false,
            lineStyle: {
              color: "#000"
            }
          }
        },
        {
          type: "value",
          boundaryGap: true,
          splitLine: { show: false },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#000",
              width: this.getBaseLineWidth()
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: "#339966",
            fontFamily: "Franklin Gothic Demi Cond",
            fontSize: DEFAULT_FONT_SIZE,
            showMinLabel: false,
            showMaxLabel: false
          }
        }
      ],
      series: [
        {
          name: "Satisfaction"
        },
        {
          name: "Revenue"
        }
      ]
    };
    return option;
  }
  getNPSTrendsOptions() {
    let option = {
      scales: {
        yAxes: [{
            ticks: {
                beginAtZero:true
            }
        }]
      },      
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        textStyle: {
          fontSize: DEFAULT_FONT_SIZE,
          fontFamily: "Franklin Gothic Demi Cond",
        },
        show: false
      },
      grid: {
        top: 10,
        left: "7%",
        right: "1%",
        bottom: "12%",
        show: true,
        backgroundColor: this.getBaseGridBackgroundColor()
      },
      yAxis: {
        type: 'value',
        show: false,
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          fontSize: DEFAULT_FONT_SIZE,
          fontFamily: "Franklin Gothic Demi Cond",
          formatter: function(name) {
            return timePeriodFormatter(name);
          }
        },    
        axisTick: {
          show: false
        },            
        data: []
      },
      series: []
    };
    return option;
  }
  getRespondentMapOptions() {
    let options = {
      title: {
        text: "",
        subtext: "",
        sublink: "",
        left: "right"
      },
      tooltip: {
        trigger: "item",
        showDelay: 0,
        transitionDuration: 0.2,
        formatter: function(params) {
          if (params.value != "null") {
            var value = (params.value + "").split(".");
            if (isNaN(value)) {
              value = 0;
            } else {
              value = value[0].replace(/(\d{1,3})(?=(?:\d{3})+(?!\d))/g, "$1,");
            }
            return params.seriesName + "<br/>" + params.name + ": " + value;
          } else {
            return "";
          }
        }
      },
      visualMap: {
        show: false,
        dimension: 0,
        min: 0,
        max: 100,
        range: [0, 100],
        inRange: {
          color: "#8FAADC"
        },
        outOfRange: {
          color: "red"
        }
      },
      toolbox: {
        show: false,
        left: "left",
        top: "top"
      },
      series: [
        {
          name: "Respondents",
          type: "map",
          roam: true,
          map: "respondent-map",
          emphasis: {
            label: {
              show: true
            }
          },
          textFixed: {
            Alaska: [-131, 25, 15],
            Hawaii: [-110, 28, 5]
          },
          data: []
        }
      ]
    };
    return options;
  }
  getFilterPredictiveAnalyticsColumns() {
    return [
      TableService.getOrderSequence(),
      {
        targets: [0],
        visible: true,
        className: "predictive-text-column predictive-border-column",
        createdCell: function(td, cellData, rowData, row, col) {
          let baseClass = "predictive-text-column predictive-border-column";
          if (cellData == "Customer NPS") {
            $(td).addClass(baseClass + " customer-nps-column ");
          } else if (
            cellData == "Connected" ||
            cellData == "Precise" ||
            cellData == "Like-Minded" ||
            cellData == "Stable" ||
            cellData == "Imaginative" ||
            cellData == "Tough-Minded" ||
            cellData == "Flexible" ||
            cellData == "Detached" ||
            cellData == "Practical" ||
            cellData == "Assurance" ||
            cellData == "Curiosity" ||
            cellData == "Strictness" ||
            cellData == "Trust" ||
            cellData == "Activity" ||
            cellData == "Anxious"
          ) {
            $(td).addClass(baseClass + " emotional-needs-column ");
          }
        }
      },
      {
        targets: [1],
        createdCell: function(td, cellData, rowData, row, col) {
          if (cellData >= 0.8 && cellData < 1.0) {
            $(td).addClass("very-strong-correlation");
          } else if (cellData >= 0.5 && cellData < 0.8) {
            $(td).addClass("strong-correlation");
          } else if (cellData >= 0.3 && cellData < 0.5) {
            $(td).addClass("mild-correlation");
          }
        },
        render(v) {
          if (v != null && !isNaN(v) && v != 0) {
            return Number(v).toFixed(2);
          }
          return "--";
        }
      },
      TableService.getNumericTableAttribute([3], "", 50, 2),
      TableService.getPercentTableAttribute([4], "", 50, 2)
    ];
  }
  getDashboardPredictiveAnalyticsConfiguration(numberOfRows = 100) {
    return {
      columnDefs: this.getFilterPredictiveAnalyticsColumns(),
      buttons: [],
      ordering: true,
      rowCallback: function(row, data, displayNum, displayIndex, dataIndex) {
        if (displayIndex > numberOfRows) {
          $(row).hide();
        } else {
          $(row).show();
        }
      },
      searching: false,
      paging: false,
      pageLength: 15,
      select: true,
      processing: false,
      responsive: true,
      destroy: true,
      info: false,
      keys: true,
      sort: false,
      bSortable: false,
      bSort: false,
    };
  }
  getBaseGridBackgroundColor() {
    return "#F8F8F8";
  }
  getBaseLineWidth() {
    return 0.25;
  }
  getBaseLineColor() {
    return "#C8C8C8";
  }  
}
export default new DashboardService();
