<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      {{ title }}
      <div class="ml-auto">
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <company-menu></company-menu>
    <div class="edit-metrics">
      <form accept-charset="UTF-8" enctype="multipart/form-data" id="MetricGroupForm" autocomplete="off" class="form" action="#" method="post" novalidate="novalidate">
        <div class="row no-gutters pb-4">
          <div class="pb-2">
            <a href="#" @click="goToMetricList()">
              <button type="button" class="btn btn-success" id="ReturnToMetricButton">Return to Metrics</button>
            </a>
          </div>
        </div>
        <div class="row" style="border-bottom: 1px solid #e9ecef">
          <div class="col-2"></div>
          <div class="col-4">
              <div class="form-group form-inline">
                <label for="metricGroupName" class="col-4 mr-1">Metric Group Name</label>
                <input v-model="metricGroup.name" id="metricGroupName" name="metricGroupName" type="text" class="form-control required w-50" />
              </div>
              <div class="form-group form-inline">
                <label for="metricDisplayType" class="col-4 mr-1">Metric Display Type</label>
                <select id="metricDisplayType" name="metricDisplayType" class="custom-select required w-50" v-model="metricGroup.metricDisplayTypeId" @change="setMetricDisplayType">
                  <option value>Select</option>
                  <option v-for="(option, idxMetricDisplayTypeOptions) in this.metricDisplayTypeOptions" v-bind:value="option.id" v-bind:key="idxMetricDisplayTypeOptions">
                    {{ option.name }}
                  </option>
                </select>
              </div>
              <div class="form-group form-inline">
                <label for="metricType" class="col-4 mr-1">Metric Type</label>
                <select id="metricType" name="metricType" class="custom-select required w-50" v-model="metricGroup.metricTypeId" @change="setMetricType">
                  <option value>Select</option>
                  <option v-for="(option, idxMetricTypeOptions) in this.metricTypeOptions" v-bind:value="option.id" v-bind:key="idxMetricTypeOptions">
                    {{ option.name }}
                  </option>
                </select>
              </div>
          </div>            
          <div class="col-6">
            <div class="">
              <div class="metrics-header">
                <div class="metrics-header-divider"></div>
                <div class="metrics-header-label">Growth Calculations</div>
                <div class="metrics-header-divider"></div>
              </div>
              <div class="form-group no-gutters pt-2">
                <label for="calculatedGrowthRate" class=""><modal-link modal-id="Calculate Growth Rate" :content-list="pageContent"></modal-link>Calculate Growth Rate</label>
                <div class="d-flex pt-2">
                  <div class="form-group form-inline pr-2"  v-for="(calculate_growth_rate_option, idxCalculateGrowthRate) in calculate_growth_rate_options" v-bind:key="idxCalculateGrowthRate">
                    <input type="radio"
                      id="calculatedGrowthRate" name="calculatedGrowthRate"
                      v-model="metricGroup.calculatedGrowthRate"
                      :value="calculate_growth_rate_option.value"
                      @click="saveCalculatedGrowthRate"
                    />
                    <label class="pl-1">{{ calculate_growth_rate_option.name }}</label>
                  </div>
                </div>
              </div>
              <div class="d-flex pt-2">
                <div class="form-group form-inline"><label for="Outliers" class="mr-1">Rank Metrics Outliers</label></div>
                <div class="form-group form-inline pl-3">
                  <label for="minimumOutliers" class="mr-1 ml-1">Min</label>
                  <input v-model="metricGroup.minimumOutliers" id="minimumOutliers" name="minimumOutliers" type="text"
                    class="form-control"  />
                </div>
                <div class="form-group form-inline">
                  <label for="maximumOutliers" class="mr-1 ml-1">Max</label>
                  <input v-model="metricGroup.maximumOutliers" id="maximumOutliers" name="maximumOutliers" type="text"
                    class="form-control " />
                </div>
              </div>
            </div>
          </div>            
        </div>
        <div class="row no-gutters" style="border-bottom: 1px solid #e9ecef">
          <div class="col-2"></div>
          <div class="col-4">
            <div v-show="displayCurrencyDropdown" class="form-group form-inline p-1">
              <label for="currency" class="control-label col-3 pr-1"><modal-link modal-id="Currency" :content-list="pageContent"></modal-link>Currency </label>
              <select id="currency" name="currency" class="custom-select required" v-model="currency">
                <option v-for="(currency_option, idxCurrencyOptions) in currency_options" v-bind:value="currency_option.name" v-bind:key="idxCurrencyOptions">
                  {{ currency_option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row no-gutters align-items-center text-center pb-2 pt-2">
          <div class="col-2 section-label">Metric Group Name</div>
          <div class="col-1 section-label">Metric Type</div>
          <div class="col-1 section-label">Metric Display Type</div>
          <div class="col-1 section-label">Year</div>
          <div class="col-2 section-label">Period</div>
          <div class="col-2 section-label">Metric</div>
          <div class="col-1 section-label">Growth</div>
        </div>
        <div v-for="(metric, idxMetric) in this.metricsList" :key="'metric' + idxMetric" class="form-row no-gutters align-items-center text-center p-1">
          <metric-entry
            :new-metric-index="idxMetric"
            :metric-object="metric"
            :group-name="metricGroup.name"
            :metric-display-type-name="metricDisplayTypeName"
            :metric-type-name="metricTypeName"
            @update="saveIndividualMetric"
            @change="retrieveGrowthAll"
            @save="saveMetric"
            @remove="removeMetric"
          ></metric-entry>
        </div>
        <div class="col mt-3" role="group">
          <button type="submit" class="btn btn-primary float-right ml-2" @click="saveMetricGroup()">
            <span>Save</span>
            <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
        </div>
        <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
          <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

import CompanyMixins from "../../mixins/CompanyMixins.vue";

import GoogleService from "../../services/google.service";
import AdminService from "../../services/admin.service";
import ClientSettingsService from "../../services/client.settings.service";
import MetricsService from "../../services/metrics.service";
import FormService from "../../services/form.service";
import MetricEntry from "../../components/MetricEntry.vue";

export default {
  name: "EditMetricGroup",
  mixins: [CompanyMixins],
  components: {
    MetricEntry
  },
  props: {
    initialMode: {
      type: String,
      default: "add-metric-group"
    },
    initialMetricGroupId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      pageNumber: 1,
      title: "Metrics",
      mode: "",
      metricGroupForm: null,
      year_options: [],
      metricDisplayTypeOptions: [],
      metricTypeOptions: [],
      currency: "US Dollars",
      metricsList: [],
      metricGroup: MetricsService.getDefaultMetricGroup(),
      saveMetricAfterSaveMetricGroup: false,
      clientSettings: {},      
    };
  },
  computed: {
    useValueToCalculateGrowth: function () {
      return this.metricGroup.calculatedGrowthRate == "YoY-Value"|| this.metricGroup.calculatedGrowthRate == "Sequentially-Value";    
    },
    currency_options: function() {
      return AdminService.getCurrencyOptions();
    },
    calculate_growth_rate_options: function() {
      return AdminService.getCalculateGrowthRateOptions();
    },
    displayCurrencyDropdown: function() {
      return this.metricDisplayTypeName == "Currency";
    },
    metricDisplayTypeName: function() {
      let metricDisplayType = this.metricDisplayTypeOptions.find(x => x.metricDisplayTypeId == this.metricGroup.metricDisplayTypeId);
      if (typeof metricDisplayType != "undefined") {
        return metricDisplayType.name;
      } else {
        return "";
      }
    },
    metricTypeName: function() {
      let metricType = this.metricTypeOptions.find(x => x.metricTypeId == this.metricGroup.metricTypeId);
      if (typeof metricType != "undefined") {
        return metricType.name;
      } else {
        return "";
      }
    }
  },
  methods: {
    logButtonClickEvent(action){
  		GoogleService.logClickEvent(this.clientName, "Metrics - " + action,  0, this);
    },    
    goToMetricList(){
      this.logButtonClickEvent('Return to Metrics');
      this.$router.push({ name: 'metric-list' });
    },
    resetForm: function() {
      this.metricGroup = MetricsService.getDefaultMetricGroup();
      this.metricsList = [];
    },
    getMetricTypes: function () {
      MetricsService.getMetricTypes(this.getMetricTypesCallback, this);
    },
    getMetricTypesCallback: function (response) {
      this.metricTypeOptions = response.data;
      this.metricTypeOptions = this.metricTypeOptions.sort(this.compare);
    },
    compare(a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    },
    getMetricDisplayTypes: function() {
      MetricsService.getMetricDisplayTypes(this.getMetricDisplayTypesCallback, this);
    },
    getMetricDisplayTypesCallback: function(response) {
      this.metricDisplayTypeOptions = response.data;
    },
    loadData: function() {
      this.loadYearOptions();
      //      this.resetForm();
    },
    saveIndividualMetric: function(index, metricData) {
      let metric = this.metricsList.find(x => x.year == metricData.year && x.period == metricData.period);
      if (typeof metric != "undefined") {
        this.metricsList[index] = metricData;
      } else {
        this.metricsList.push(metricData);
      }
    },
    saveMetric: function() {
      if (this.metricGroup.metricGroupId == 0) {
        this.saveMetricGroup();
        this.saveMetricAfterSaveMetricGroup = true;
      } else {
        let stringMetricList = JSON.stringify(this.metricsList);
        MetricsService.saveMetricList(stringMetricList, this.metricGroup.metricGroupId, this.mode, this.saveMetricCallback, this);
      }
    },
    saveMetricCallback: function(response) {
      this.$root.processServerResponse();      
      let statusCode = response.data.statusCode;      
      if (statusCode == 200) {
        this.metricsList = response.data.data.metricsList;
        let addNewMetric = response.data.data.addNewMetric;
        if (addNewMetric) {
          this.metricsList.push(MetricsService.getDefaultMetric());
        }
        AdminService.displayInfoMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async removeMetric(index) {
      if (index != 0){
        MetricsService.deleteMetric(index, this.removeMetricCallback, this);
      } else {
        var metricToDelete = this.metricsList.find(x => x.metricId == index);
        if (typeof metricToDelete != "undefined") {
          this.metricsList.splice($.inArray(metricToDelete, this.metricsList), 1);
        }
      }
    },
    removeMetricCallback(response) {
      this.$root.processServerResponse();      
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        let indexToRemove = response.data.data;
        var metricToDelete = this.metricsList.find(x => x.id == indexToRemove);
        if (metricToDelete !== undefined) {
          this.metricsList.splice($.inArray(metricToDelete, this.metricsList), 1);
        }
        AdminService.displayInfoMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    saveCalculatedGrowthRate: function(event) {
      this.metricGroup.calculatedGrowthRate = event.target.value;
      this.retrieveGrowthAll();
    },
    retrieveGrowthAll: function() {
      this.metricsList.forEach(metric => {
        this.retrieveGrowth(this.getMetricObject(metric.year, metric.period));
      });
    },
    getMetricObject(year, period) {
      return this.metricsList.find(x => x.year == year && x.period == period);
    },
    getComparableMetricObject(metricData1) {
      let metricData2 = {};
      let period = metricData1.period;
      let year = metricData1.year;
      let comparePeriod;
      let compareYear;
      let lastPeriod = (typeof this.clientSettings != "undefined" && typeof this.clientSettings.numberOfPeriods != "undefined") ? this.clientSettings.numberOfPeriods : 4;
      if (this.metricGroup.calculatedGrowthRate == "Sequentially" || 
          this.metricGroup.calculatedGrowthRate == "Sequentially-Value") {
        if (period == 1) {
          compareYear = year - 1;
          comparePeriod = lastPeriod;
        } else {
          compareYear = year;
          comparePeriod = period - 1;
        }
      } else if (this.metricGroup.calculatedGrowthRate == "YoY" || 
                  this.metricGroup.calculatedGrowthRate == "YoY-Value") {
        compareYear = year - 1;
        comparePeriod = period;
      }
      metricData2 = this.getMetricObject(compareYear, comparePeriod);
      if (typeof metricData2 === "undefined") {
        metricData2 = {
          active: "Y",
          clientId: this.clientId,
          year: compareYear,
          period: comparePeriod
        };
      }
      return metricData2;
    },

    retrieveGrowth: function(metricData2) {
      let metricData1 = this.getComparableMetricObject(metricData2);
      metricData2.metricGrowth = this.calculateGrowth(metricData1.metric, metricData2.metric);
    },
    calculateGrowth: function(beforeData, afterData) {
      let growth = 0; 
      let difference = afterData - beforeData;
      let isPositive = afterData > beforeData;
      // Both numbers are negative
      if (afterData < 0 && beforeData < 0){
        let average = (afterData + beforeData) / 2
        growth = this.useValueToCalculateGrowth ? difference : (difference / Math.abs(average)) * 100;
      } else {
        growth = this.useValueToCalculateGrowth ? difference : (difference / beforeData) * 100;
      }
      if (!isNaN(growth) && isFinite(growth)) {
          growth = growth.toFixed(2);
          if (isPositive && growth < 0){
            growth = -growth;
          } 
      } else {
        growth = 0;
      }
      return growth;
    },
    async getMetricGroup() {
      this.setDisplayView(false);
      this.$root.processServerRequest("Retrieving metric data");
      MetricsService.getMetricGroup(this.initialMetricGroupId, this.mode, this.getMetricGroupCallback, this);
    },
    getMetricGroupCallback: function(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.setDisplayView(true);
        this.title = "Modify Edit Group";
        this.metricGroup.metricGroupId = response.data.data.metricGroup.metricGroupId;
        this.metricGroup.name = response.data.data.metricGroup.name;
        this.metricGroup.metricDisplayTypeId = response.data.data.metricGroup.metricDisplayTypeId;
        this.metricGroup.metricTypeId = response.data.data.metricGroup.metricTypeId;
        this.metricGroup.minimumOutliers = response.data.data.metricGroup.minimumOutliers;
        this.metricGroup.maximumOutliers = response.data.data.metricGroup.maximumOutliers;
        this.metricGroup.calculatedGrowthRate = response.data.data.metricGroup.calculatedGrowthRate;
        this.metricsList = response.data.data.metricsList;
        let addNewMetric = response.data.data.addNewMetric;
        if (addNewMetric) {
          this.metricsList.push(MetricsService.getDefaultMetric());
        }

        if (response.data.data.metricGroup.configuration != "") {
          this.metricGroup.configuration = JSON.parse(response.data.data.metricGroup.configuration);
          this.currency = this.metricGroup.configuration.currency;
        }
        if (response.data.data.settings != "") {
          this.clientSettings = ClientSettingsService.parseClientSettings(response.data.data.settings);
        }
        this.mode = "edit-metric-group";
        AdminService.displaySuccessMessage({ text: response.data.message });
        if (this.saveMetricAfterSaveMetricGroup == true) {
          this.saveMetric();
          this.saveMetricAfterSaveMetricGroup = false;
        }
        this.retrieveGrowthAll();
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
      this.metricGroupForm = $("#MetricGroupForm");
      this.setupForm();
    },
    saveMetricGroup() {
      if (!this.metricGroupForm.valid()) {
        return false;
      } else {
        this.$root.processServerRequest("Saving metric data");
        if (this.displayCurrencyDropdown) {
          $.extend(this.metricGroup.configuration, { currency: this.currency });
        }
        let metricGroup = {};
        //metricGroup.mode = this.mode;
        metricGroup.metricGroupId = this.metricGroup.metricGroupId;
        metricGroup.metricDisplayTypeId = this.metricGroup.metricDisplayTypeId;
        metricGroup.metricGroupName = this.metricGroup.name;
        metricGroup.calculatedGrowthRate = this.metricGroup.calculatedGrowthRate;
        metricGroup.minimumOutliers = this.metricGroup.minimumOutliers;
        metricGroup.maximumOutliers = this.metricGroup.maximumOutliers;

        let stringConfiguration = JSON.stringify(this.metricGroup.configuration);
        let stringMetricList = JSON.stringify(this.metricsList);
        MetricsService.saveMetricGroup(this.mode, this.metricGroup, stringConfiguration, stringMetricList, this.getMetricGroupCallback, this);
      }
    },
    setupForm() {
      FormService.setupForm(this.metricGroupForm);
      this.validator = this.metricGroupForm.validate(FormService.getBaseValidator());
    },
    setMetricDisplayType() {
      for (var i = 0; i < this.metricsList.length; i++) {
        this.metricsList[i].metricDisplayTypeId = this.metricGroup.metricDisplayTypeId;
      }
      if (this.metricGroup.metricDisplayTypeId != 1 && this.metricGroup.metricDisplayTypeId != 2) {
        this.metricGroup.calculatedGrowthRate = "None";
      }
    },
    setMetricType() {
      for (var i = 0; i < this.metricsList.length; i++) {
        this.metricsList[i].metricTypeId = this.metricGroup.metricTypeId;
      }
    },    
    setClientSettings(response){
      this.clientSettings = ClientSettingsService.parseClientSettings(response.data.data);
    }
  },
  created() {
    this.metricGroup.metricGroupId = this.initialMetricGroupId;
    this.mode = this.initialMode;
    if (this.mode == "edit-metric-group") {
      this.title = "Modify Metric Group";
      this.getMetricGroup();
    } else if (this.mode == "add-metric-group") {
      this.title = "Add Metric Group";
      this.metricsList.push(MetricsService.getCurrentDateDefaultMetric());
    }
    this.getMetricDisplayTypes();
    this.getMetricTypes();
    this.$root.setDisableSurveyDropdown(true);
  },
  mounted() {
    if (this.mode == "add-metric-group") {
      ClientSettingsService.getClientSettings(this.clientCode, this.setClientSettings, this);
      this.metricGroupForm = $("#MetricGroupForm");
      this.setupForm();
    }
  },
  beforeRouteLeave: function(to, from, next) {
    this.$root.setDisableSurveyDropdown(false);
    next();
  },
  watch: {
    /*
    clientId: function() {
      this.$router.push({ name: 'metric-list' });
    }
      */
  },  
};
</script>
