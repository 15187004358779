<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      <div class="pb-1"><modal-link modal-id="How to Use" :content-list="pageContent"></modal-link>{{ title }}</div>
    </div>
    <div class="library mx-auto">
        <div class="library-widgets mt-3 mb-3">
            <div class="col-3 form-group form-inline">
                <span class="saved-cards-label">View</span>
                <select id="sortBy" name="sortBy" class="custom-select required" v-model="sortBy">
                    <option value>Sort By</option>
                    <option v-for="(sort_by_option, idxSortByOptions) in this.sort_by_options" v-bind:value="sort_by_option.value" v-bind:key="idxSortByOptions">
                    {{ sort_by_option.name }}
                    </option>
                </select>
            </div>
            <div class="col">
                <div class="show-hide-wrapper">
                    <span class="saved-cards-label">Card View</span>
                    <toggle-button v-model="useDetailedView" color="rgb(191, 203, 217)" />
                    <span class="saved-cards-label">List View</span>
                </div>
            </div>
        </div>
        <div class="library-body mx-auto">
            <div v-show="!useDetailedView"  v-for="(report, idxReport) in reportList" v-bind:key="idxReport" class="library-report">
                <div class="library-border"></div>
                <div class="library-image" @click="downloadDocument(report.clientDocumentId, report.fileName)">
                    <div :class="report.class"></div>
                </div> 
                <div class="library-report-download">
                    <a href="#" @click="downloadDocument(report.clientDocumentId, report.fileName)">
                        Download PDF <span class="ml-auto"> >> </span>
                    </a>
                </div>  
                <div class="library-report-header" v-html="report.header"></div>
                <div class="library-report-description" v-html="report.description"></div>
            </div>
            <div v-show="useDetailedView" class="w-100">
                <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
                <thead></thead>
                <tbody></tbody>
                </table>
            </div>        
        </div>
    </div>
    <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
      <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
    </div>
  </div>
</template>
<script>
import ContentMixins from "../mixins/ContentMixins.vue";
import DataTableMixins from "../mixins/DataTableMixins.vue";
import Modal from "../components/Modal.vue";
import ModalLink from "../components/ModalLink.vue";
import AdminService from "../services/admin.service";
import ReportService from "../services/report.service";
import ClientDocumentService from "../services/client.document.service";
import UserSettingsService from "../services/user.settings.service";
import LibraryService from "../services/library.service";
import $ from "jquery";

export default {
  name: "Library",
  mixins: [ContentMixins,DataTableMixins],
  components: {
    Modal,
    ModalLink
  },
  props: {},
  data() {
    return {
      pageNumber: 1,
      title: "Library",
      libraryTableBuilt: false,
      clientDocumentId: 0,
      downloadDocumentName: "",
      sortBy: "date-added-newest",
      useDetailedView: UserSettingsService.getDetailedViewForSavedCards(),
      sort_by_options: LibraryService.getSortByOptions(),
      table: {
        id: "library-report-data",
        class: "ytable w-100 display wrap row-border dt-select-no-highlight",
        configuration: {
          dom: '<"top"if>rtp<"bottom"><"clear">',
          searching: true,
          paging: true,
          select: true,
          destroy: true,
          info: false,
          ordering: true,
          bSort: true,
          bSortable: true,
          keys: false,
          focus: false,
          autoWidth: false,
          processing: true,
          responsive: true,
        },
        columns: LibraryService.getLibraryTableColumnData()
      },
      tableSortBy: [4, "desc"],
      reportList : [
        //{id: 1, clientDocumentId: 9, header: "Most Competitive Companies 2024-Q1", class:"most-competitive-companies-2024-q1", description: "Our quarterly rankings of 30 well-known companies based on competitive strength.", fileName: "Most Competitive Companies 2024-Q1.pdf"},    
        {id: 1, clientDocumentId: 29, header: "Most Competitive Companies – Annual Report 2025",description: "Our annual ratings of the W-30 Index of companies based on how well each company performs against customer expectations. Chewy takes over the #1 spot using their customer-service first subscription model to turn their customer moats into economic moats.",fileName: "Most Competitive Companies – Annual Report 2025.pdf",class: "y2025-mcc",createdDate: new Date("1/21/2025")},        
        {id: 2, clientDocumentId: 28, header: "Most Competitive Companies 2024-Q3",description: "Our quarterly rankings of 30 well-known companies based on competitive strength. Revenue growth and pricing power continue to rise, due to companies closing the gaps on customer expectations.",fileName: "2024-Q3 The W Report - Most Competitive Companies.pdf",class: "y2024-q3-mcc",createdDate: new Date("11/05/2024")},        
        {id: 3, clientDocumentId: 26, header: "Most Competitive Companies 2024-Q2",description: "Our quarterly rankings of 30 well-known companies based on competitive strength. Chewy takes over the top spot, out-executing even e-commerce king Amazon by focusing on a unique customer experience.",fileName: "2024-Q2 The W Report - Most Competitive Companies.pdf",class: "y2024-q2-mcc",createdDate: new Date("09/24/2024")},
        {id: 4, clientDocumentId: 8, header: "Most Competitive Companies 2024-Q1",description: "Our quarterly rankings of 30 well-known companies based on competitive strength. We take a look at the impact of inflation on pricing power and sales growth.",fileName: "2024-Q1 The W Report - Most Competitive Companies.pdf",class: "y2024-q1-mcc",createdDate: new Date("04/16/2024")},
        {id: 5, clientDocumentId: 9, header: "Most Competitive Companies - Annual Report 2024",description: "Our annual rankings of 30 well-known companies based on competitive strength. New #1 Deere’s “smart, industrial strategy” brought improvement in two key customer areas.",fileName: "2024 The W Report - Most Competitive Companies.pdf",class: "y2024-mcc",createdDate: new Date("01/31/2024")},
        {id: 6, clientDocumentId: 10, header: "Most Competitive Companies - 2023-Q3",description: "Our quarterly rankings of 30 well-known companies based on competitive strength. Lululemon takes over the #1 spot usings its emotional appeal.",fileName: "2023-Q3 The W Report - Most Competitive Companies.pdf",class: "y2023-q3-mcc",createdDate: new Date("10/17/2023")},
        {id: 7, clientDocumentId: 11,header: "Most Competitive Companies - 2023-Q2",description: "Our quarterly rankings of 30 well-known companies based on competitive strength. Roku is #1, but Apple rediscovers its superpower.",fileName: "2023-Q2 The W Report - Most Competitive Companies.pdf",class: "y2023-q2-mcc",createdDate: new Date("07/14/2023")},
        {id: 8, clientDocumentId: 12,header: "The W Report - Comcast-Disney-Netflix-Roku 2023-Q1",description: "In this new streaming ecosystem, which business framework best delivers on consumer expectations? What are consumers willing to pay for?",fileName: "2023-Q1 The W Report - Comcast-Disney-Netflix-Roku.pdf",class: "y2023-q1-netflix-disney-roku",createdDate: new Date("06/1/2023")},
        {id: 9, clientDocumentId: 13,header: "The W Report - TollBrothers-UPS-WasteManagement 2023-Q1",description: "Which home industry company performs the best according to homeowners? If you only look at their NPS® or raw satisfaction scores, you’d be entirely misled.",fileName: "2023-Q1 The W Report - TollBrothers-UPS-WasteManagement.pdf",class: "y2023-q1-tollbrothers-ups-wastemanagement",createdDate: new Date("05/10/2023")},
        {id: 10, clientDocumentId: 14,header: "The W Report - Costco-Crocs-TractorSupply-Zoom 2023-Q1",description: "Four companies all growing in very different markets, yet all using one impressive similarity: They think outside the box. Or more accurately, they all created a new box.",fileName: "2023-Q1 The W Report - Costco-Crocs-TractorSupply-Zoom.pdf",class: "y2023-q1-costco-crocs-tractorsupply-zoom",createdDate: new Date("04/27/2023")},
        {id: 11, clientDocumentId: 15,header: "The W Report - Twitter-Facebook-Tesla 2023-Q1",description: "Three companies. Two in the same industry. Two with the same CEO. All seek growth in highly visible arenas under much public scrutiny.",fileName: "2023-Q1-The W Report - Twitter-Facebook-Tesla.pdf",class: "y2023-q1-twitter-facebook-tesla",createdDate: new Date("04/20/2023")},
        {id: 12, clientDocumentId: 16,header: "The W Report - Boeing-Chick-fil-A-Intel 2023-Q1" ,description: "Three well-known companies. Three different journeys. Three different trajectories.",fileName: "2023-Q1 The W Report - Boeing-Chick-fil-A-Intel.pdf",class: "y2023-q1-boeing-chick-fil-a-intel",createdDate: new Date("04/13/2023")},
        {id: 13, clientDocumentId: 17,header: "The W Report - Zillow-Google 2022-Q3" ,description: "Most analysts are saying the worst may be yet to come. Our competitive analysis on Zillow differs from “most” analysts though.",fileName: "2022-Q3 The W Report - Zillow-Google.pdf",class: "y2022-q3-zillow-google",createdDate: new Date("09/7/2022")},
        {id: 14, clientDocumentId: 18,header: "The W Report - Dominos-UberEats 2022-Q3",description: "What customers expect from Domino’s is quite different than what they expect from Uber Eats, and those expectations provide a substantial advantage for Domino’s and other food companies that opt to deliver their food direct",fileName: "2022-Q3 The W Report - Dominos-UberEats.pdf",class: "y2022-q3-dominos-ubereats",createdDate: new Date("07/16/2022")},
        {id: 15, clientDocumentId: 19,header: "The W Report - Tesla-Ford 2022-Q2" ,description: "We are going to look at a classic challenge in a highly competitive market, and one that most CEOs struggle with regardless of industry: How to shift customers to a new way of delivering value.",fileName: "2022-Q2 The W Report - Tesla-Ford.pdf",class: "y2022-q2-tesla-ford",createdDate: new Date("06/28/2022")},
        {id: 16, clientDocumentId: 20,header: "The W Report - Zoom-AmericanExpress 2022-Q2",description: "Whether due to amazing product development, adept management, lucky timing, or all of these, the Zoom phenomenon is a huge success . . . until recently as growth has slowed.",fileName: "2022-Q2 The W Report - Zoom-AmericanExpress.pdf",class: "y2022-q2-zoom-amex",createdDate: new Date("06/21/2022")},
        {id: 17, clientDocumentId: 21,header: "The W Report - Deere 2022-Q2",description: "What does an executive team do when its customers no longer want to buy your products?",fileName: "2022-Q2 The W Report - Deere.pdf",class: "y2022-q2-deere",createdDate: new Date("06/14/2022")},
        {id: 18, clientDocumentId: 22,header: "The W Report - Facebook-Google-Twitter 2022-Q2" ,description: "Facebook has a user value problem. And until FB executives recognize and confront the problem, the outlook appears dim.",fileName: "2022-Q2 The W Report - Facebook-Google-Twitter.pdf",class: "y2022-q2-facebook-google-twitter",createdDate: new Date("06/8/2022")},
        {id: 19, clientDocumentId: 23,header: "The W Report - Crocs-Apple-Lululemon 2022-Q2" ,description:  "The Crocs executive team managed its playbookthrough the pandemic crisis about as good as any company could. Simplicity helps.",fileName: "2022-Q2 The W Report - Crocs-Apple-Lululemon.pdf",class: "y2022-q2-crocs-apple-lululemon",createdDate: new Date("05/23/2022")},        
      ]
    };
  },
  methods: {
    loadData: function () {
      this.$root.processServerRequest("Loading library");
    },
    buildLibraryTable() {
      if (this.libraryTableBuilt == true) {
        return;
      }
      let tableName = "library-report-data" + " tbody";
      this.getTableDataWithDataCallback("", this.reportList);

      let vm = this;
      let table = $("#" + tableName);      
      table.on("click", 'a[class="download-report"]', function(e, dt, type, indexes) {
        var $row = $(this).closest("tr");
        var data = vm.table.dataTable.row($row).data();
        if (!$.isEmptyObject(data)) {
          vm.downloadDocument(data.clientDocumentId, data.fileName);
        }
      });
      this.libraryTableBuilt = true;
    },    
    downloadDocument: function (clientDocumentId, name) {
      this.clientDocumentId = clientDocumentId;
      this.downloadDocumentName = name;
      ClientDocumentService.downloadItem(this.clientDocumentId, this.downloadItemCallback, this);
    },
    getDownloadFilename() {
      return this.downloadDocumentName;
    },
    downloadItemCallback(response) {
      this.$root.processServerResponse();
      const blob = new Blob([response.data]);
      this.clickLink(blob);
      AdminService.displaySuccessMessage({ text: "Download Complete" });
    },
  },
  created() {},
  mounted() {
    this.buildLibraryTable();
  },
  watch: {
    sortBy: function() {
      if (this.sortBy == "name-ascending") {
        this.tableSortBy = [1, "asc"];
        this.reportList.sort(ReportService.compareValues("header", "asc"));
      } else if (this.sortBy == "name-descending") {
        this.tableSortBy = [1, "desc"];
        this.reportList.sort(ReportService.compareValues("header", "desc"));
      } else if (this.sortBy == "date-added-newest") {
        this.reportList.sort(ReportService.compareValues("createdDate", "desc"));
        this.tableSortBy = [4, "desc"];
      } else if (this.sortBy == "date-added-oldest") {
        this.reportList.sort(ReportService.compareValues("createdDate", "asc"));
        this.tableSortBy = [4, "asc"];
      } 
      if (this.useDetailedView == true) {
        this.table.dataTable.order(this.tableSortBy).draw();
      }
    },    
    useDetailedView: function() {
      if (this.useDetailedView == true) {
        this.table.dataTable.order(this.tableSortBy).draw();
      }        
    }
  }
};
</script>
