import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";
import ClientService from "./client.service";
import TableService from "./table.service";
import AlertService from "./alert.service";

import moment from "moment";
import Vue from "vue";
import $ from "jquery";
Vue.prototype.moment = moment;

const API_INTERVIEW_URL = AdminService.getBaseUrl() + "/api/v1/interview";

class SurveyTakerService {

    async processSurvey(surveyUrl,surveyTakerId,
            externalSurveyTakerId, uniqueCode,
            entryClientId, callback, app){
        const params = new URLSearchParams();
        params.append("surveyUrl", surveyUrl);
        params.append("surveyTakerId", surveyTakerId);
        params.append("externalSurveyTakerId", externalSurveyTakerId);
        params.append("uniqueCode", uniqueCode);
        params.append("clientId", entryClientId);

        await axios
            .post(API_INTERVIEW_URL + "/process-survey", params)
            .then(response => callback(response))
            .catch(error => {
            error.message += ErrorService.mergeError(error.response);
            error.message += app.surveyTakerMessage;
            ErrorService.processError("SurveyTakerService.processSurvey", error, "survey-taker", app);
        });
    }
    async submitForm(surveyId,surveyTakerId,externalSurveyTakerId,surveyTakerIpAddress,surveyTakerLocation,surveyResults,callback, app){
        const params = new URLSearchParams();
        params.append("surveyId", surveyId);
        params.append("surveyTakerId", surveyTakerId);
        params.append("externalSurveyTakerId", externalSurveyTakerId);
        params.append("surveyTakerIpAddress", surveyTakerIpAddress);
        params.append("surveyTakerLocation", JSON.stringify(surveyTakerLocation));
        params.append("surveyResults", JSON.stringify(surveyResults));
        await axios
          .post(API_INTERVIEW_URL + "/submit-survey", params)
          .then(response => callback(response))
          .catch(error => {
            error.message += ErrorService.mergeError(error.response);
            error.message += app.surveyTakerMessage;
            ErrorService.processError("SurveyTakerService.submitForm", error, "survey-taker", app);
            app.displayCompletedSurveyMessage = true;
            return false;
         });
    }
    async saveSurveySummary(surveyId, surveyTakerId, externalSurveyTakerId, surveyTakerIpAddress, surveyTakerLocation, status, defaultValues, app){
        const params = new URLSearchParams();
        params.append("surveyId", surveyId);
        params.append("surveyTakerId", surveyTakerId);
        params.append("externalSurveyTakerId", externalSurveyTakerId);
        params.append("surveyTakerIpAddress", surveyTakerIpAddress);
        params.append("surveyTakerLocation", JSON.stringify(surveyTakerLocation));
        params.append("status", status);
        if (status == "I" && defaultValues != null && typeof defaultValues != "undefined") {
            params.append("defaultValues", JSON.stringify(defaultValues));
        }
        await axios
          .post(API_INTERVIEW_URL + "/save-survey-summary", params)
          .then(response => app.$log.info("Survey Initialized"))
          .catch(error => {
            error.message += ErrorService.mergeError(error.response);
            error.message += app.surveyTakerMessage;
            ErrorService.processError("SurveyTakerService.saveSurveySummary", error, "survey-designer", app);
          });
    }
    async getSurvey(uniqueCode, clientId, surveyCode, callback, app){
        const params = new URLSearchParams();
        params.append("uniqueCode", uniqueCode);
        params.append("clientId", clientId);
        params.append("surveyCode", surveyCode);
        await axios
          .post(API_INTERVIEW_URL + "/get-survey", params)
          .then(response => callback(response))
          .catch(error => {
            error.message += ErrorService.mergeError(error.response);
            error.message += app.surveyTakerMessage;
            ErrorService.processError("SurveyTakerService.setupSurvey", error, "survey-taker", app);
          });

    }
    async getSurveyBySurvey(uniqueCode, clientId, surveyUrl, callback, app){
        const params = new URLSearchParams();
        params.append("uniqueCode", uniqueCode);
        params.append("clientId", clientId);
        params.append("surveyUrl", surveyUrl);

        await axios
        .post(API_INTERVIEW_URL + "/get-survey-by-survey", params)
        .then(response => callback(response))
        .catch(error => {
          AlertService.closeAlert();
          console.log("Error message from server: " + error.response.data.message); // eslint-disable-line no-console
          error.message += ErrorService.mergeError(error.response);
          error.message += app.surveyTakerMessage;
          ErrorService.processError("SurveyTakerService.setupSurvey", error, "survey-taker", app);
        });
    }

}

export default new SurveyTakerService();
