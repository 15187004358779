<template>
  <div class="dashboard-card-dropdown">
    <div v-show="displayLabel" class="control-label" :style="getCustomLabelColor()">{{ label }}</div>
    <select :id="labelId" :name="labelId" class="custom-select" :disabled="readOnly" :style="getCustomOptionsColor()" v-model="value" @change="sendValue($event)">
      <option value="">{{ defaultDropdownOption }}</option>
      <option v-for="(option, indexName) in options" v-bind:value="String(option.value)" v-bind:key="indexName">
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
import GoogleService from "../../services/google.service";
import SurveyService from "../../services/survey.service";

export default {
  name: "DashboardCardDropdown",
  mixins: [],
  components: {},
  data() {
    return {
      value: ""
    };
  },
  props: {
    defaultDropdownOption: {
      type: String,
      default: "Please Choose"
    },
    readOnly: {
      type: Boolean,
      default: false
    },    
    label: {
      type: String,
      default: "Performance"
    },
    displayLabel: {
      type: Boolean,
      default: true
    },
    defaultValue: {
      type: String,
      default: ""
    },
    inputLabelColor: {
      type: String,
      default: ""
    },
    inputOptionsColor: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    indexName: function() {
      return "idx" + this.label;
    },
    labelId: function() {
      return "idx" + this.label.toLowerCase();
    }
  },
  mounted() {
    this.value = this.defaultValue;
  },
  created() {},
  methods: {
    getCustomLabelColor: function(){
      if (this.inputLabelColor != "") {
        return "color: " + this.inputLabelColor;
      }
      return "";
    },
    getCustomOptionsColor: function(){
      if (this.inputOptionsColor != "") {
        return "color: " + this.inputOptionsColor;
      }
      return "";
    },
    sendValue: function(event) {
      let value = event.target.value;
      GoogleService.logActionEvent("change", "Dashboard", this.$parent.title, SurveyService.getSurveyName(), this.label, this);
      this.$emit("change", value);
    }
  },
  watch: {
    defaultValue: function() {
      this.value = this.defaultValue;
    }
  }
};
</script>
