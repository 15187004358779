import $, { type } from "jquery";
import CommonService from "./common.service";
import ScatterService from "./scatter.service";

class TrendsService extends CommonService{
    /***  Series Functions ***/
    getSeries(name,gridIndex,xAxisIndex,yAxisIndex,config = {},isMoat = false){
        let baseSeries = {
            name: name,
            type: 'line',
            gridIndex: gridIndex,
            xAxisIndex: xAxisIndex,
            yAxisIndex: yAxisIndex,
            data: []
        };    
        if (isMoat == true){
            baseSeries.markLine = this.getDefaultMarkLineForMoat();
        }   
        $.extend(true, baseSeries, config);
        return baseSeries;
    }
    /*** yAxis Functions ***/
    getYAxis(vAxis,displayType){
        
        let minPerformanceValue = 0;
        let maxPerformanceValue = 10;

        let metricConfiguration = vAxis.find(x=>x.type == "metric");
        let performanceConfiguration = vAxis.find(x=>x.type == "performance");

        if (typeof performanceConfiguration != "undefined" && performanceConfiguration != null){
            minPerformanceValue = performanceConfiguration.configuration.minValue;
            maxPerformanceValue = performanceConfiguration.configuration.maxValue;
        }
        if (displayType == "moat-trends"){
            maxPerformanceValue=100;
        }
        let yAxis = [];
        let yAxisForNeed = this.getYAxisForNeed(minPerformanceValue, maxPerformanceValue);
        let yYAxisForPricingPower = this.getYAxisForMetric(metricConfiguration);
        $.extend(true, yAxisForNeed.splitLine,{show: true});
        $.extend(true, yYAxisForPricingPower.splitLine,{show: false});
        yAxis.push(yAxisForNeed);
        yAxis.push(yYAxisForPricingPower);
        return yAxis;
    }     
    getYAxisForMetric(metricConfiguration){
        let minMetricValue = 0;
        let maxMetricValue = 0.5;
        let formatter = "{value}"
        if (typeof metricConfiguration != "undefined" && metricConfiguration != null){
            minMetricValue = metricConfiguration.configuration.minValue;
            maxMetricValue = metricConfiguration.configuration.maxValue;
            maxMetricValue *= 100.1;
            maxMetricValue = Math.ceil(maxMetricValue);
            maxMetricValue += 1;
            formatter = metricConfiguration.configuration.formatter;
        }

        let axisLabelForPricingPower = this.getAxisLabelForMetric(formatter);
        $.extend(true, axisLabelForPricingPower, ScatterService.getDefaultAxisTextStyle());           
        let yYAxisForPricingPower = this.getBaseYAxis();
        let yYAxisForPricingPowerAdditional = {
            boundaryGap: true,
            splitLine: {
                show: false
            },
            axisLine: {
                show: false,
            },
            axisLabel: axisLabelForPricingPower,
            min: minMetricValue,
       //     max: maxMetricValue
        };
        $.extend(true, yYAxisForPricingPower, yYAxisForPricingPowerAdditional);          
        return yYAxisForPricingPower;
    }

    /*** Parse Functions ***/
    parseAxisData(inputData, outputData){
        if (typeof inputData !== 'undefined' && inputData !== null) {
            if (typeof inputData.otherData !== 'undefined' && inputData.otherData !== null) {
                let otherData = inputData.otherData;
                outputData.vAxis = [];
                if (typeof otherData.vAxisForMetric !== 'undefined' && otherData.vAxisForMetric !== null){
                    let axis = {type: "metric", configuration: JSON.parse(JSON.stringify(otherData.vAxisForMetric))};
                    outputData.vAxis.push(axis);
                }
                if (typeof otherData.vAxisForPerformance !== 'undefined' && otherData.vAxisForPerformance !== null){
                    let axis = {type: "performance", configuration: JSON.parse(JSON.stringify(otherData.vAxisForPerformance))};
                    outputData.vAxis.push(axis);
                }
            }    
        }
        return outputData;
    }    
    toolTipForTrendsChart = (params) => {
        let returnValue = "";
        for (let i = 0; i < params.length; ++i) {
            let valueToDisplay = params[i].value;
            let data = params[i].data;
            let seriesName = params[i].seriesName;
            //returnValue += params[i].marker += " ";
            returnValue += params[i].seriesName += " ";
            returnValue += "<span style='text-align:right'><b>" 
            if (valueToDisplay != 0){
              let formatter = typeof data.formatter !== 'undefined' || data.formatter != null ? data.formatter : "{value}";
              let isPercent = "Willingness to Pay" == seriesName || seriesName.includes("Moat") || (formatter != null && formatter.includes("%")); 
              let isCurrency = formatter != null && formatter.includes("$");
              if (isPercent) {
                returnValue += Number(valueToDisplay).toFixed(2);
                returnValue += "%";
              } else if (isCurrency){
                returnValue += "$";
                returnValue += Number(valueToDisplay).toFixed(2);
              } else {
                returnValue += Number(valueToDisplay).toFixed(2);
              }
            } else {
                returnValue += "--";
            }
            returnValue += "</b></span>";
            returnValue += "<br>";
        }
        return returnValue;
      }     
}
export default new TrendsService();             

