<template>
  <div>
    <div id="chart-container" class="row">
      <div class="col-12">
        <div v-show="this.$parent.getDisplayView() && getDisplayView()" id="base-rank-data-container" class="chart-data">
          <chart-data-table
            v-show="this.$parent.getDisplayView() && getDisplayView()"
            ref="baseRankDataTable"
            :data-container-id="'base-rank-data-container'"
            :container-id="'base-rank'"
            :report-page-type-name="'Table - Base Rank'"
            :insight-modal-id="'base-rank-table'"
            :chart-data="this.table.data"
            v-bind:row-start-position="6"
            :column-defs="chart.columnDefs"
            :table-class="'table display row-border table-hover'"
            :table-width="'auto'"
            :table-title="this.table.title"
            :is-insider-user="this.$parent.isInsiderUser"
            :inputDownloadFileName="chartTypeTitle"
          ></chart-data-table>
        </div>
        <div v-show="this.$parent.getDisplayView() && getDisplayView() && !displayRankings && !$parent.isMoatRank" class="chart-data">There is not enough data for rankings</div>  
      </div>
    </div>
    <insights
      ref="insightsTable"
      :modal-name="'base-rank-table'"
      :report-page-type-name="'Table - Base Rank'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="$parent.updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
    
  </div>
</template>
<script>
import ChartCompanyMixins from "../../../mixins/ChartCompanyMixins.vue";
import $ from "jquery";

import TableService from "../../../services/table.service";
import ReportService from "../../../services/report.service";
import ErrorService from "../../../services/error.service";
import AdminService from "../../../services/admin.service";
import PrintAndCopyService from "../../../services/print.and.copy.service";

export default {
  name: "BaseRank",
  mixins: [ChartCompanyMixins],
  components: { },
  data() {
    return {
      title: "Moat Profile",
      pageNumber: 5,
      
      
      table: {
        id: "",
        data: {},
        title: "<div class='title'><h4 class='text-primary'>Moats Rank</h4></div>"
      },
      isMobile: false,
      displayRankings: false,
      redrawAdditionalWidgets: true,
      moatChartOptions: {},
      snapModalContent: PrintAndCopyService.getSnapMessage()
    };
  },
  props: {
    inputData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    clearTable(){
      this.table =  {
        id: "",
        data: {},
        title: "<h4 class='text-primary'>Moats Rank</h4>"
      };
      this.setDisplayView(false);
    },
    async snapChart(displayLegend, snapContainerId) {
      PrintAndCopyService.initializeSnapChart(displayLegend, snapContainerId, this); 
    },
    displayInsights(insightModalId, reportPageTypeName) {
      if (reportPageTypeName == "Table - Base Rank") {
        this.$refs.insightsTable.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsTable.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      }
    },
    async processChartData() {
      try {
        let returnData = JSON.parse(JSON.stringify(this.inputData));
        let containerId = returnData["containerId"];
        let columns = returnData["cols"];
        let rows = returnData["rows"];
        this.table.id = containerId;
        this.chart.columnDefs = this.buildColumns(columns);
        
        let otherData = returnData["otherData"];
        this.$parent.surveyGroupByTierList = otherData["surveyGroupByTierList"];
        this.$parent.baseGroupName = otherData["baseGroupName"];
        if (typeof otherData["tableTitle"] != "undefined"){
          this.table.title = otherData["tableTitle"];
        } else {
          this.table.title = "Rank";
        }
        this.table.data = returnData;

        this.$parent.$data.criteriaResultsList = AdminService.removeByName(returnData.criteriaList, "Competitor");        
        if (rows.length == 1){
          this.displayRankings = false;
          this.$parent.setDisplayView(false);
          this.setDisplayView(false);
        } else {
          this.$parent.setDisplayView(true);
          this.setDisplayView(true);
          this.displayRankings = true;
        }
        this.$root.processServerResponse();
        setTimeout(() => {
            if (containerId == "base-rank") {
                this.saveReportPage("base-rank");
            }        
        }, 1000);        
        this.buildClientChart();
      } catch (err) {
        ErrorService.processException("BaseRank.processChartData", err, "chart", this);
      }
    },
    async saveReportPage(containerId) {
      if (this.$parent.clientChart.clientChartId != 0 && this.isGenerateReportRunning()) {
/*        
        let vm = this;
        setTimeout(async function () {
          let tableContainerId = "base-rank-data-container";
          let imageReportPageChartContent = await vm.createTableImage(vm, tableContainerId, false, 700, 10); 

          await ReportService.saveReportPage(imageReportPageChartContent, "Table - Base Rank", vm.$parent.clientChart, vm.saveReportPageCallback, vm);
          await ReportService.saveReportPageFile(vm.tableImageUrl, "png", "Table - Base Rank", vm.$parent.clientChart, vm.saveReportPageFileCallback, vm);
        }, 1000);
*/        
        
        await ReportService.saveReportTableFile(
          this.$refs.baseRankDataTable.getTableData(),
          "Table - Base Rank", 
          this.$parent.clientChart, 
          this.saveReportTableFileCallback, 
          this
        );

        await ReportService.saveReportPage(
          this.getReportTableContent(containerId), 
          "Table - Base Rank", 
          this.$parent.clientChart, 
          this.saveReportPageCallback, 
          this
        );
      }
    },
    saveAllReportPages() {
      this.saveReportPage("base-rank");
    },
    buildColumns(columns){
      let returnColumns = [];
      returnColumns.push(TableService.getOrderSequence());
      
      let position = 0;
      columns.forEach(function(column) {
        if (column.label == "TIER"){
          returnColumns.push(TableService.getBaseColumn([position], column.className, 50));
        } else if (column.label == "Survey"){
          returnColumns.push(TableService.getBaseColumn([position], column.className, 150));
        } else if (column.type == "rank"){
          returnColumns.push(TableService.getRankTableAttribute([position], column.className, 50));
        } else if (column.type == "percent"){
          returnColumns.push(TableService.getPercentTableAttributeRanks([position], column.className, 50, 2, ""));
        } else if (column.type == "nps"){
          returnColumns.push(TableService.getNPSTableAttribute([position], column.className, 50));
        } else if (column.type == "number"){
          returnColumns.push(TableService.getNumericTableAttributeRanks([position], column.className, 50, ""));
        } else {
          returnColumns.push(TableService.getBaseTableAttribute([position], column.className, 50));
        }
        position++;
      });    
      TableService.getNumericTableAttribute(["_all"], "dt-right", 50) 
      return returnColumns;
    }
  },
  umnounted() {},
  computed: {
    chartTypeTitle() {
      const chartType = this.$parent.$data.criteriaResultsList.find(item => item.name === "Chart Type");
      return chartType ? `${chartType.displayValue} Rank` : "Base Rank";
    }
  },
  mounted() {},
  created() {},
  watch: {
    inputData: function() {
      this.clearTable();
      if (this.inputData.rows.length > 0) {
        this.processChartData();
      } 
    }
  }
};
</script>
