import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import SurveyService from "./survey.service";
import UserService from "./user.service";
import ClientSettingsService from "./client.settings.service";
import moment from "moment-timezone";
import Vue from "vue";
import $ from "jquery";
import jQuery from "jquery";

Vue.prototype.moment = moment;

const API_CLIENT_URL = AdminService.getBaseUrl() + "/api/v1/client";
const API_CLIENT_CHART_URL = AdminService.getBaseUrl() + "/api/v1/client-chart";

class ClientService {
  saveClient(client) {
    localStorage.setItem("client", JSON.stringify(client));
  }

  getClient() {
    if (localStorage.getItem("client") != null) {
      return JSON.parse(localStorage.getItem("client"));
    } else {
      return {};
    }
  }

  getClientId() {
    if (localStorage.getItem("client") != null) {
      return JSON.parse(localStorage.getItem("client")).clientId;
    } else {
      return 0;
    }
  }
  getSymbolTypeId() {
    if (localStorage.getItem("client") != null) {
      return JSON.parse(localStorage.getItem("client")).symbolTypeId;
    } else {
      return 4;
    }
  }
  getClientColor() {
    if (localStorage.getItem("client") != null) {
      return JSON.parse(localStorage.getItem("client")).color;
    } else {
      return "";
    }
  }
  getClientName() {
    if (localStorage.getItem("client") != null) {
      return JSON.parse(localStorage.getItem("client")).name;
    } else {
      return "";
    }
  }
  getClientCode() {
    if (localStorage.getItem("client") != null) {
      return JSON.parse(localStorage.getItem("client")).clientCode;
    } else {
      return "";
    }
  }
  getSurveyCodes() {
    if (localStorage.getItem("client") != null) {
      return JSON.parse(localStorage.getItem("client")).surveyCodes;
    } else {
      return "";
    }
  }
  
  async changeClient(baseUrl, clientCode, callback, chartApp) {
    let userId = UserService.getUserId();
    if (typeof clientCode != "undefined" && typeof userId != "undefined") {
      const params = new URLSearchParams();
      params.append("clientCode", clientCode);
      params.append("userId", userId);
      await axios
        .post(API_CLIENT_URL + "/get-client", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientService.changeClient", error, "survey-designer", chartApp);
        });
    }
  }

  clearCache() {
    localStorage.removeItem("get-all-clients-with-hierarchy");
  }
  async getClientList(callback, chartApp) {
    await axios
      .get(API_CLIENT_URL + "/get-client-list", { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.getClientList", error, "admin", chartApp);
      });
  }
  contains(clientId){
    let listOfClients = this.getAllClients();
    if (typeof clientId != "undefined" && typeof listOfClients != "undefined" && isNaN(clientId) && clientId != "undefined") {
      var match = listOfClients.find(x => x.id == clientId);
      if (typeof match !== "undefined") {
        return true;
      }
    }
    return false;
  }
  getAllClients() {
    if (!jQuery.isEmptyObject(localStorage.getItem("get-all-clients-with-hierarchy"))) {
      return JSON.parse(localStorage.getItem("get-all-clients-with-hierarchy"));
    }
    return [];
  }
  async getAllClientsFromDatabase(callback,useW30AsDefaultClient = false) {
    if (localStorage.getItem("get-all-clients-with-hierarchy") != null) {
      callback(JSON.parse(localStorage.getItem("get-all-clients-with-hierarchy")));
    } else {
      let userId = UserService.getUserId();
      if (typeof userId != "undefined") {
        const params = new URLSearchParams();
        params.append("userId", userId);
        params.append("userCode", UserService.getUserCode());
        params.append("userTypeId", UserService.getUserTypeId());
        params.append("useW30AsDefaultClient", useW30AsDefaultClient);
        await axios
          .post(API_CLIENT_URL + "/get-all-clients-with-hierarchy", params, { headers: await authHeader() })
          .then(response => {
            localStorage.setItem("get-all-clients-with-hierarchy", JSON.stringify(response.data));
            callback(response.data);
          })
          .catch(error => {
            throw error;
          });
      }
    }
  }
  async getPredictiveAnalyticsOptions(callback, chartApp) {
    let clientId = this.getClientId();
    let clientCode = this.getClientCode();
    let userId = UserService.getUserId();
    let surveyId = SurveyService.getSurveyId();
    if (surveyId == 0){
      chartApp.$root.processServerResponse();
    }
    if (clientId != 0 && surveyId != 0 && typeof userId != "undefined") {
      const params = new URLSearchParams();
      params.append("clientCode", clientCode);
      params.append("userId", userId);
      params.append("surveyId", surveyId);
      await axios
        .post(API_CLIENT_URL + "/get-predictive-analytics-options", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientService.getPredictiveAnalyticsOptions", error, "survey-designer", chartApp);
        });
    }
  }
  async getPredictiveAnalytics(metricGroupId, period, callback, chartApp) {
    let clientId = this.getClientId();
    let clientCode = this.getClientCode();
    if (clientId != 0) {
      const params = new URLSearchParams();
      params.append("clientCode", clientCode);
      params.append("surveyId", SurveyService.getSurveyId());
      params.append("userId", UserService.getUserId());
      params.append("period", period);
      params.append("metricGroupId", metricGroupId);
      await axios
        .post(API_CLIENT_URL + "/get-predictive-analytics", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientService.getPredictiveAnalytics", error, "survey-designer", chartApp);
        });
    }
  }
  async getDetailedPredictiveAnalytics(params, callback, chartApp) {
    let clientId = this.getClientId();
    if (clientId != 0) {
      await axios
        .post(API_CLIENT_URL + "/get-detailed-predictive-analytics", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientService.getDetailedPredictiveAnalytics", error, "survey-designer", chartApp);
        });
    }
  }
  async deleteClient(clientCode, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_CLIENT_URL + "/delete-client", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.deleteClient", error, "survey-designer", chartApp);
      });
  }
  async copyClient(clientCode, numberOfCopies, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("userId", UserService.getUserId());
    params.append("numberOfCopies", numberOfCopies);
    await axios
      .post(API_CLIENT_URL + "/copy-client", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.copyClient", error, "survey-designer", chartApp);
      });
  }
  async saveClientColor(clientCode, color, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("userId", UserService.getUserId());
    params.append("color", color);
    await axios
      .post(API_CLIENT_URL + "/save-client-color", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.saveClientColor", error, "admin", chartApp);
      });
  }
  async saveClientSymbol(clientCode, symbolTypeId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("userId", UserService.getUserId());
    params.append("symbolTypeId", symbolTypeId);
    await axios
      .post(API_CLIENT_URL + "/save-client-symbol", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.saveClientSymbol", error, "admin", chartApp);
      });
  }
  displayPinMessage(pinnedObject, name){
    let oldPin = pinnedObject.pin;
    let message = oldPin == "N" ? "Pinning " : "Unpinning ";
    message += name;
    if (oldPin == "N"){
      message += " to ";
    } else {
      message += " from ";
    }
    message += " the dashboard";
    return message;    
  }
  async savePinForClientChart(clientChartId, pin, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientChartId", clientChartId);
    params.append("userId", UserService.getUserId());
    params.append("pin", pin);
    await axios
      .post(API_CLIENT_CHART_URL + "/save-pin-for-client-chart", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.saveClientColor", error, "admin", chartApp);
      });
  }
  async savePinForClientReport(clientReportId, pin, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientReportId", clientReportId);
    params.append("userId", UserService.getUserId());
    params.append("pin", pin);
    await axios
      .post(API_CLIENT_URL + "/save-pin-for-client-report", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.saveClientColor", error, "admin", chartApp);
      });
  }  
  async saveClientToDatabase(mode, newSurveyName, formClientObject, clientCompetitors, clientSettings, callback, chartApp) {
    let parsedClientSettings = [];
    parsedClientSettings.push(ClientSettingsService.mapClientSetting(clientSettings.numberOfPeriods, "number-of-periods"));
    parsedClientSettings.push(ClientSettingsService.mapClientSetting(clientSettings.startPeriod, "start-period"));


    const params = new URLSearchParams();
    params.append("userId", UserService.getUserId());
    params.append("mode", mode);
    params.append("surveyName", newSurveyName);
    params.append("active", formClientObject.active);
    params.append("clientName", formClientObject.name);
    params.append("clientId", formClientObject.clientId);
    params.append("sectorId", formClientObject.sectorId);
    params.append("isParent", formClientObject.isParent);
    params.append("includeW50", formClientObject.includeW50);
    params.append("color", formClientObject.color);
    params.append("parentClientId", formClientObject.parentClientId);
    params.append("subscriptionEndDate", formClientObject.subscriptionEndDate);
    params.append("clientSettings", JSON.stringify(parsedClientSettings));
    params.append("clientCompetitors", JSON.stringify(clientCompetitors));
    await axios
      .post(API_CLIENT_URL + "/save-client", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.saveClientToDatabase", error, "admin", chartApp);
      });
  }

  async getClientWithDefaultCompetitors(clientCode, callback, chartApp) {
    let userId = UserService.getUserId();
    if (typeof clientCode != "undefined" && typeof userId != "undefined") {
      const params = new URLSearchParams();
      params.append("clientCode", clientCode);
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_CLIENT_URL + "/get-client-with-default-competitors", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientService.getClientWithDefaultCompetitors", error, "admin", chartApp);
        });
    }
  }

  async getClientFromDatabase(initialClientCode, callback, chartApp) {
    let userId = UserService.getUserId();
    if (typeof initialClientCode != "undefined" && typeof userId != "undefined") {
      const params = new URLSearchParams();
      params.append("clientCode", initialClientCode);
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_CLIENT_URL + "/get-client", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientService.getClientFromDatabase", error, "admin", chartApp);
        });
    }
  }
  async getAllParentClients(callback, chartApp) {
    const params = new URLSearchParams();
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_CLIENT_URL + "/get-parent-clients", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.getAllParentClients", error, "admin", chartApp);
      });
  }
  async isClientHierarchy() {}
  async saveClientHierarchy(clientCode, stringClientHierarchyList, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("userId", UserService.getUserId());
    params.append("stringClientHierarchyList", stringClientHierarchyList);
    await axios
      .post(API_CLIENT_URL + "/save-client-hierarchy", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.saveClientHierarchy", error, "admin", chartApp);
      });
  }
  async getClientHierarchy(clientCode, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("userId", UserService.getUserId());
    if (clientCode != ""){
      await axios
        .post(API_CLIENT_URL + "/get-client-hierarchy", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientService.getClientFromDatabase", error, "admin", chartApp);
        });
    }      
  }
  getSavedCharts(clientChartsList, surveyId) {
    if (typeof clientChartsList != "undefined") {
      return clientChartsList.filter(clientChartInput => Number(clientChartInput.surveyId) == Number(surveyId) && Number(clientChartInput.chartTypeId) != 5);
    } else {
      return {};
    }
  }
  getAllSavedChartsByChartType(clientChartsList, chartTypeId) {
    if (typeof clientChartsList != "undefined") {
      return clientChartsList.filter(clientChartInput => Number(clientChartInput.chartTypeId) == Number(chartTypeId) && Number(clientChartInput.chartTypeId) != 5);
    } else {
      return {};
    }
  }
  getSavedChartsByChartType(clientChartsList, surveyId, chartTypeId) {
    return this.getSavedCharts(clientChartsList, surveyId).filter(clientChartInput => Number(clientChartInput.chartTypeId) == Number(chartTypeId));
  }
  getDefaultClientReport(){
    return {
      clientId: this.getClientId(),
      title: "",
      subTitle: "",
      includeCoverPage: "Y",
      includeEndPage: "Y",
      includeContentPage: "Y",
      clientChartReportPages: []
    }    
  }
  getTableColumnData() {
    return [
      {
        data: "clientId",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
        className: "",
        name: "id",
        width: "5rem"
      },
      { data: "name", name: "name", width: "15rem" },
      { data: "parentClientName", name: "parentClientName", width: "14rem" },
      { data: "isParent", name: "isParent", width: "5rem" },
      { data: "includeW50", name: "includeW50", width: "6rem" },
      {
        data: "active",
        name: "status",
        width: "5rem",
        render: function(data, type, row) {
          var statusMap = { N: { label: "Inactive", style: "warning" }, Y: { label: "Active", style: "success" } };
          if (type === "display") {
            return "<p class='m-0 p-0' style='width: 3rem;'><span class='badge badge-" + statusMap[data].style + "'>" + statusMap[data].label + "</span></p>";
          } else {
            return data;
          }
        }
      },
      {
        data: "lastWaveEndDate",
        type: "date",
        name: "lastWaveEndDate",
        width: "6rem",
        className: "text-left",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      }, 
      {
        data: "subscriptionEndDate",
        type: "date",
        name: "subscriptionEndDate",
        width: "6rem",
        className: "text-left",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      },
      {
        data: "dateCreated",
        type: "date",
        name: "dateCreated",
        width: "6rem",
        className: "text-left",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      },
      {
        data: "dateModified",
        type: "date",
        name: "dateModified",
        width: "6rem",
        className: "text-left",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      }
    ];
  }
}

export default new ClientService();
