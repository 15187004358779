<template>
  <div>
    <div class="card mb-3 shadow chart-card mx-auto">
      <div class="chart-card-top card-body p-1">
        <div class="d-flex">
          <div class="side-icons">
            <div @click="savePin">
              <font-awesome-icon :icon="['fas', 'thumb-tack']" :class=" this.chartCardObject.pin == 'Y' ? 'clicked-icon' : 'unclicked-icon'"/> 
            </div>
            <div v-if="!isDashboardView" @click="updateCardList(chartCardObject, $event)" :class=" isClientChartPartOfReportSetup(chartCardObject) == false ? 'unclicked-icon' : 'clicked-icon'">
              <font-awesome-icon v-tooltip="tooltipReportSetup" :icon="['fas', 'images']" style="height: 28px;" />
            </div>
          </div>
          <div v-if="!isDashboardCard()" :class="getChartCardClass()">
            <router-link :to="{ name: chartType.shortName, params: { autoGenerateChart: true, saveClientChartId: this.chartCardObject.clientChartId } }">
              <!-- <font-awesome-icon v-if="isCompetitiveProfileByMoat()" :icon="['fab', 'fort-awesome']" /> -->
              <i v-if="isCompetitiveProfileByMoat()" class="fa-duotone fa-castle" style="color: #e6af00; font-size: 40px;"></i>
              <!-- <font-awesome-icon v-if="isResponseRates()" :icon="['fa', 'users']" />-->
              <i v-if="isResponseRates()" class="fa-duotone fa-poll-people icons" style="color: #2f5eb3; font-size: 40px;"></i>
              <i v-if="isCompetitiveProfileByValue()" class="fa-duotone fa-scale-unbalanced" style="color: #68afff; font-size:40px"></i>              
              <i v-if="isCompetitiveProfileByNeed()" class="fa-duotone fa-arrow-down-wide-short" aria-hidden="true" style="color: #379660; font-size: 40px;"></i>
              <i v-if="isCompetitiveProfileByWinners()" class="fa-duotone fa-road" style="color: #2f5eb3; font-size: 40px;"></i>              
              <i v-if="isCompetitiveTrends()" class="fa-duotone fa-display-chart-up" style="color: #1F4923; font-size: 40px;"></i>              
              <i v-if="isProfileMatch()" class="fa-duotone fa-code-compare" style="color: #960000; font-size: 40px;"></i>              
              <i v-if="isMoatRank()" class="fa-duotone fa-fort" style="color: #b48d00; font-size: 40px;"></i>
              <i v-if="isMoatTrends()" class="fa-duotone fa-display-chart-up" style="color: #1F4923; font-size: 40px;"></i>              
              <i v-if="isPredictive()" class="fa-duotone fa-hand-holding-circle-dollar" style="color: #1F4923; font-size: 40px;"></i>              
              <img v-if="isWScoresRank()" src="/img/wRatings-2021-W-support.png" class="w-support-card" alt="wRatings" />
              <span v-if="isWScoresRank()">&#174;</span>
              <i v-if="isEmotionsRank()" class="fa-duotone fa-heart-pulse" style="color: #960000; font-size: 40px;"></i>
              <i v-if="isSatisfactionRank()" class="fa-duotone fa-arrow-up-9-1" style="color: #C76829; font-size: 40px;"></i>
              <i v-if="isNPS()" class="fa-duotone fa-face-smile" aria-hidden="true"  style="font-size:42px;color: #379660"></i>
              <i v-if="isNPSRank()" class="fa-duotone fa-face-viewfinder" aria-hidden="true"  style="font-size:42px;color: #379660"></i>              
            </router-link>
            <font-awesome-icon v-if="isDashboardCard()" :icon="['fa', 'flag-checkered']" />
            <i v-if="isDashboardCard()" class="fa-duotone fa-flag-checkered" aria-hidden="true"  style="font-size:40px;"></i>
          </div>
          <div v-else :class="getChartCardClass()" @click="openSavedDashboardCard">
            <i v-if="isDashboardCard()" class="fa-duotone fa-flag-checkered" aria-hidden="true"  style="font-size:40px;"></i>
          </div>
          <div class="ml-auto">
            <a load-on-starup="true" href="javascript:;" class="reload" @click="displayDeleteConfirmation">
              <font-awesome-icon :icon="['fas', 'window-close']" style="width: 20px;height: 20px;" />
            </a>
          </div>
        </div>
      </div>
      <div v-if="!isDashboardCard()" class="chart-card-row chart-card-name">
        <router-link :to="{ name: chartType.shortName, params: { autoGenerateChart: true, saveClientChartId: this.chartCardObject.clientChartId } }">
          <span v-if="this.chartCardObject.name.length <= this.maximumCharacterCount"> {{ this.chartCardObject.name }} </span>
          <span v-else data-placement="bottom" :title="this.chartCardObject.name"> {{ this.chartCardObject.name.substring(0, this.maximumCharacterCount) + "...." }} </span>
        </router-link>
      </div>
      <div v-else class="chart-card-row chart-card-name saved-dashboard-card" @click="openSavedDashboardCard">
          <span v-if="this.chartCardObject.name.length <= this.maximumCharacterCount"> {{ this.chartCardObject.name }} </span>
          <span v-else data-placement="bottom" :title="this.chartCardObject.name"> {{ this.chartCardObject.name.substring(0, this.maximumCharacterCount) + "...." }} </span>
      </div>
      <dashboard-moneyball-stick-modal 
          :modal-id="getModalId('dashboard-moneyball-stick')"
          :modal-title="chartCardObject.name"
          :client-object="$parent.$data.clientObject" 
          :save-client-chart-object="chartCardObject"/>
      <dashboard-moat-comps-modal 
          :modal-id="getModalId('dashboard-moat-comps')"
          :modal-title="chartCardObject.name"
          :client-object="$parent.$data.clientObject" 
          :save-client-chart-object="chartCardObject"/>
      <dashboard-needs-profile-modal 
      :modal-id="getModalId('needs-profile')"
          :modal-title="chartCardObject.name"
          :client-object="$parent.$data.clientObject" 
          :save-client-chart-object="chartCardObject"/>
      <dashboard-growth-sequence-modal 
          :modal-id="getModalId('growth-sequence')"
          :modal-title="chartCardObject.name"
          :client-object="$parent.$data.clientObject" 
          :save-client-chart-object="chartCardObject"/>
      <dashboard-winners-map-modal 
          :modal-id="getModalId('winners-map')"
          :modal-title="chartCardObject.name"
          :client-object="$parent.$data.clientObject" 
          :save-client-chart-object="chartCardObject"/>
      <dashboard-nps-trends-modal 
          :modal-id="getModalId('nps-trends')"
          :modal-title="chartCardObject.name"
          :client-object="$parent.$data.clientObject" 
          :save-client-chart-object="chartCardObject"/>
      <dashboard-competitive-trends-modal 
          :modal-id="getModalId('dashboard-competitive-trends')"
          :modal-title="chartCardObject.name"
          :client-object="$parent.$data.clientObject" 
          :save-client-chart-object="chartCardObject"/>
      <dashboard-competitive-rank-modal 
          :modal-id="getModalId('competitive-rank')"
          :modal-title="chartCardObject.name"
          :client-object="$parent.$data.clientObject" 
          :save-client-chart-object="chartCardObject"/>
      <dashboard-respondent-map-modal 
          :modal-id="getModalId('respondent-map')"
          :modal-title="chartCardObject.name"
          :client-object="$parent.$data.clientObject" 
          :save-client-chart-object="chartCardObject"/>
      <dashboard-performance-trends-modal 
          :modal-id="getModalId('performance-trends')"
          :modal-title="chartCardObject.name"
          :client-object="$parent.$data.clientObject" 
          :save-client-chart-object="chartCardObject"/>
      <dashboard-rank-modal 
          :modal-id="getModalId('dashboard-nps-rank')"
          :modal-title="chartCardObject.name"
          :client-object="$parent.$data.clientObject" 
          :save-client-chart-object="chartCardObject"/>
      <dashboard-rank-modal 
          :modal-id="getModalId('dashboard-moat-score-rank')"
          :modal-title="chartCardObject.name"
          :client-object="$parent.$data.clientObject" 
          :save-client-chart-object="chartCardObject"/>
      <dashboard-rank-modal 
          :modal-id="getModalId('dashboard-w-score-rank')"
          :modal-title="chartCardObject.name"
          :client-object="$parent.$data.clientObject" 
          :save-client-chart-object="chartCardObject"/>
          
<!--          
      <dashboard-saved-card-predictive-analytics-modal 
          :modal-id="getModalId('dashboard-predictive-analytics')"
          :modal-title="chartCardObject.name"
          :client-object="$parent.$data.clientObject" 
          :save-client-chart-object="chartCardObject"/>
-->
      <dashboard-saved-card-predictive-analytics-contributions-modal 
          :modal-id="getModalId('dashboard-predictive-analytics')"
          :modal-title="chartCardObject.name"
          :client-object="$parent.$data.clientObject" 
          :save-client-chart-object="chartCardObject"/>

      <dashboard-saved-card-predictive-analytics-contributions-modal 
          :modal-id="getModalId('dashboard-metric-contributions')"
          :modal-title="chartCardObject.name"
          :client-object="$parent.$data.clientObject" 
          :save-client-chart-object="chartCardObject"/>

    </div>
  </div>
</template>
<script>
import $ from "jquery";
import ContentMixins from "../../mixins/ContentMixins.vue";
import DashboardNeedsProfileModal from "../../modals/DashboardNeedsProfileModal.vue";
import DashboardGrowthSequenceModal from "../../modals/DashboardGrowthSequenceModal.vue";
import DashboardWinnersMapModal from "../../modals/DashboardWinnersMapModal.vue";
import DashboardNpsTrendsModal from "../../modals/DashboardNpsTrendsModal.vue";
import DashboardCompetitiveTrendsModal from "../../modals/DashboardCompetitiveTrendsModal.vue";
import DashboardCompetitiveRankModal from "../../modals/DashboardCompetitiveRankModal.vue";
import DashboardRespondentMapModal from "../../modals/DashboardRespondentMapModal.vue";
import DashboardRankModal from "../../modals/DashboardRankModal.vue";
import DashboardPerformanceTrendsModal from "../../modals/DashboardPerformanceTrendsModal.vue";
import DashboardSavedCardPredictiveAnalyticsContributionsModal from "../../modals/DashboardSavedCardPredictiveAnalyticsContributionsModal.vue";
import DashboardMoatCompsModal from "../../modals/DashboardMoatCompsModal.vue";
import DashboardMoneyballStickModal from "../../modals/DashboardMoneyballStickModal.vue";
import ChartService from "../../services/chart.service";
import AdminService from "../../services/admin.service";
import ReportService from "../../services/report.service";
import ClientChartService from "../../services/client.chart.service";
import SurveyService from "../../services/survey.service";
import ClientService  from "../../services/client.service";
import AlertService from "../../services/alert.service";

export default {
  name: "ChartCard",
  mixins: [ContentMixins],
  components: {DashboardNeedsProfileModal,DashboardGrowthSequenceModal,DashboardWinnersMapModal,
    DashboardNpsTrendsModal,DashboardCompetitiveTrendsModal,DashboardCompetitiveRankModal,DashboardRankModal,
    DashboardRespondentMapModal,DashboardPerformanceTrendsModal,DashboardSavedCardPredictiveAnalyticsContributionsModal,
    DashboardMoatCompsModal,DashboardMoneyballStickModal},
  data() {
    return {
      maximumCharacterCount: 45,
      tooltipReportSetup: {
        placement: "right",
        content: "Click to include in printed reports",
        trigger: "hover",
        delay: { show: 500, hide: 500 },
      },
      updatedClientChartIds : this.clientChartIds,
    };
  },
  props: {
    chartCardObject: {
      type: Object,
      default: function() {
        return {};
      }
    },
    clientChartIds: {
      type: Array,
      default: function() {
        return [];
      }
    },
    setType: {
      type: String,
      default: ""
    },
    viewType: {
      type: String,
      default: "cards"
    },       
  },
  computed: {
    isDashboardView: function() {
      return this.viewType ==  "dashboard"
    },    
    dashboardCardModalId: function(){
      return "dashboardCardId_"+this.setType+"_"+this.chartType.shortName+"_"+this.chartCardObject.clientChartId;
    },
    deleteConfirmationTitle: function() {
      return "Delete Confirmation for " + this.chartCardObject.name;
    },
    chartType: function() {
      return ChartService.findChartTypeById(this.chartCardObject.chartTypeId);
    },
    surveyName() {
      return SurveyService.getNameBySurveyId(this.chartCardObject.surveyId);
    }
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip();
  },
  created() {},
  methods: {
    savePin: function(){
      let message = ClientService.displayPinMessage(this.chartCardObject, this.chartCardObject.name);
      let newPin = this.chartCardObject.pin == "N" ? "Y" : "N";
      this.$root.processServerRequest(message);
      ClientService.savePinForClientChart(this.chartCardObject.clientChartId, newPin, this.savePinCallback, this);
    },
    savePinCallback: function(response){
      this.$root.processServerResponse();      
      let message = response.data.message;
      AdminService.displaySuccessMessage({ text: message, duration: -1 });
      let clientCharts = response.data.data;
      this.$parent.entryClientObject.clientCharts = [...clientCharts];
      ClientService.saveClient(this.$parent.entryClientObject);
      this.$root.setClientObject(this.$parent.entryClientObject);
    },
    getModalId: function(shortName){
      return "dashboardCardId_"+this.setType+"_"+shortName+"_"+this.chartCardObject.clientChartId;
    },
    openSavedDashboardCard(){
      this.$modal.show(this.dashboardCardModalId);
    },
    isClientChartPartOfReportSetup(clientChart) {
      var match = this.clientChartIds.find(x => x.clientChartId === clientChart.clientChartId);
      if (typeof match !== "undefined") {
        return true;
      } 
      return false;
    },
    updateCardList(clientChart) {
      if (this.isClientChartPartOfReportSetup(clientChart)) {
        this.updatedClientChartIds.splice($.inArray(clientChart, this.clientChartIds), 1);
      } else {
        this.updatedClientChartIds.push(clientChart);
      }
      this.$emit('update', this.updatedClientChartIds);
    },
    displayDeleteConfirmation() {
      AlertService.showConfirmationMessageWithParameter(this.deleteClientChart,this.chartCardObject.clientChartId,this.deleteConfirmationTitle,'Are you sure you want delete ' + this.chartCardObject.name + ' ?');
    },
    deleteClientChartCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.$emit("delete", this.chartCardObject.clientChartId);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async deleteClientChart(result) {
      let clientChartId = result;
      this.$root.processServerRequest("Removing saved card");
      ClientChartService.deleteClientChart(clientChartId,this.deleteClientChartCallback,this);
    },
    getChartCardClass() {
      let returnClassName = "main-chart-icon ml-auto ";
      if (this.isCompetitiveProfileByNeed()) {
        returnClassName += "competitive-profile-by-need-card";
      } else if (this.isCompetitiveProfileByMoat()) {
        returnClassName += "competitive-profile-by-moat-card";
      } else if (this.isCompetitiveProfileByValue()) {
        returnClassName += "competitive-profile-by-value-card";
      } else if (this.isCompetitiveProfileByWinners()) {
        returnClassName += "competitive-profile-by-winners-card";
      } else if (this.isCompetitiveProfileByNps()) {
        returnClassName += "competitive-profile-by-word-cloud-card";
      } else if (this.isCompetitiveTrends()) {
        returnClassName += "competitive-trends-card";
      } else if (this.isProfileMatch()) {
        returnClassName += "profile-match-card";
      } else if (this.isMoatRank()) {
        returnClassName += "moat-rank";
      } else if (this.isMoatTrends()) {
        returnClassName += "moat-trends-card";
      } else if (this.isPredictive()) {
        returnClassName += "predictive-card";
      } else if (this.isWScoresRank()) {
        returnClassName += "w-scores-rank-card";
      } else if (this.isEmotionsRank()) {
        returnClassName += "emotions-rank-card";
      } else if (this.isSatisfactionRank()) {
        returnClassName += "satisfaction-rank-card";
      } else if (this.isNPSRank()) {
        returnClassName += "nps-rank-card";
      }  else if (this.isDashboardCard()) {
        returnClassName += "dashboard-saved-card";
      }
      return returnClassName;
    },
    isResponseRates() {
      return ChartService.isResponseRates(this.chartType.shortName);
    },
    isCompetitiveProfileByNeed() {
      return ChartService.isCompetitiveProfileByNeed(this.chartType.shortName, this.chartCardObject.displayType);
    },
    isCompetitiveProfileByMoat() {
      return ChartService.isCompetitiveProfileByMoat(this.chartType.shortName, this.chartCardObject.displayType);
    },
    isCompetitiveProfileByValue() {
      return ChartService.isCompetitiveProfileByValue(this.chartType.shortName, this.chartCardObject.displayType);
    },
    isCompetitiveProfileByWinners() {
      return ChartService.isCompetitiveProfileByWinners(this.chartType.shortName, this.chartCardObject.displayType);
    },
    isCompetitiveProfileByNps() {
      return ChartService.isCompetitiveProfileByNPS(this.chartType.shortName, this.chartCardObject.displayType);
    },
    isDashboardCard() {
      return ReportService.isSavedDashboardCard(this.chartType.chartTypeId);
    },
    isProfileMatch() {
      return ChartService.isProfileMatch(this.chartType.shortName);
    },
    isCompetitiveTrends() {
      return ChartService.isCompetitiveTrends(this.chartType.shortName);
    },
    isMoatRank() {
      return ChartService.isMoatRank(this.chartType.shortName, this.chartCardObject.displayType);
    },
    isMoatTrends() {
      return ChartService.isMoatTrends(this.chartType.shortName, this.chartCardObject.displayType);
    },
    isPredictive() {
      return ChartService.isPredictive(this.chartType.shortName, this.chartCardObject.displayType);
    },
    isWScoresRank() {
      return ChartService.isWScoresRank(this.chartType.shortName, this.chartCardObject.displayType);
    },
    isEmotionsRank() {
      return ChartService.isEmotionsRank(this.chartType.shortName, this.chartCardObject.displayType);
    },
    isSatisfactionRank() {
      return ChartService.isSatisfactionRank(this.chartType.shortName, this.chartCardObject.displayType);
    },
    isNPSRank() {
      return ChartService.isNPSRank(this.chartType.shortName, this.chartCardObject.displayType);
    },
    isNPS() {
      return ChartService.isNPS(this.chartType.shortName, this.chartCardObject.displayType);
    },    
  },
  watch: {}
};
</script>
