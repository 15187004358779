<template>
  <div id="word-cloud-container">
    <div id="by_word_cloud_nps_data_container" v-show="this.$parent.getDisplayView() && getDisplayView()" class="w-100">
      <net-promoter-score 
      :draw-chart="drawNPSChart"
      :input-e-chart-criteria="inputEChartCriteria"
      :nps-data="this.npsData" 
      :is-match="!includeWordCloud"
      :nps-chart-data="npsChartData" 
      :container-id="'by_word_cloud_nps_chart'"
      :data-container-id="'nps_data_container'"
      :report-page-type-name="'Net Promoter Score'"
      :insight-modal-id="'nps-table'"
      container-class="row">
      </net-promoter-score>      
    </div>
    <div v-if="includeWordCloud" class="word-cloud row no-gutters chart-wrapper">
      <div v-show="this.$parent.getDisplayView() && getDisplayView()" id="word-cloud-canvas" class="col-7">
        <div class="title">Word Cloud</div>
        <div  class="title-and-chart row pt-4">
          <div id="word-cloud-title-and-chart" class="title-and-chart-left d-none"></div>
          <div id="word-cloud-display">
            <div data-html2canvas-ignore="true" v-if="displayWordCloud && drawWordCloud">
              <button class="btn ml-1" type="button" @click="downloadComments">
                <i class='fa-duotone fa-download fa-lg'></i>
                <span class="pl-2">Download Comments</span>
              </button>
            </div>
            <div class="buttons-and-chart d-flex">
                <chart-buttons
                :display="true"
                :snap-label="'Snap'"
                :include-advisor-button="$parent.clientChart.clientChartId != 0"           
                :tooltip-configuration="snapChartTooltipConfig"
                snap-container-id="word-cloud-display"
                container-id="word-cloud-display"
                :report-page-type-name="'Chart - Word Cloud'"
                insight-modal-id="profile-by-word-cloud-chart"
              >
              </chart-buttons>
              <div class="print-word-cloud">
                <vue-word-cloud
                  v-if="displayWordCloud && drawWordCloud"
                  class="mx-auto"
                  :style="baseWordCloudClass"
                  :words="wordList"
                  :color="determineColor"
                  :fontSizeRatio="1 / 5"
                  :spacing="0.25"
                  :rotation="0"
                  font-family="Franklin Gothic Medium Cond"
                />
              </div>
            </div>
          </div>
          <div class="w-100 pl-3" v-show="!displayWordCloud">No comments to display</div>          
        </div>
      </div>
      <div v-show="this.$parent.getDisplayView() && getDisplayView()" class="col-5">
        <div class="title">Counts</div>
        <div id="word-cloud-count-title-and-chart" class="title-and-chart">
          <div id="word-cloud-data-container" :class="isInsiderUser ? 'chart-data hide-table-data' : 'chart-data'">
            <div v-show="isInsiderUser" class="overlay">
              <img class="overlay-inner" v-show="isInsiderUser" src="../../../assets/img/overlap.png"  style="width: 50%"/>
            </div>
            <table :id="table.id" :class="table.class" style="display: none" cellspacing="0" width="auto">
              <thead>
                <tr>
                  <th style="text-align: center; width: 1rem">Word/Phrase</th>
                  <th style="text-align: center; width: 1rem">Count</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    
    <insights
      ref="insightsChart"
      :modal-name="'profile-by-word-cloud-chart'"
      :report-page-type-name="'Chart - Word Cloud'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="$parent.updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
    <insights
      ref="insightsTable"
      :modal-name="'profile-by-word-cloud-table'"
      :report-page-type-name="'Table - Word Cloud'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="$parent.updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
  </div> 
</template>
<script>
import $ from "jquery";

import ErrorService from "../../../services/error.service";
import AdminService from "../../../services/admin.service";
import ReportService from "../../../services/report.service";
import TableService from "../../../services/table.service";
import WordCloudService from "../../../services/word.cloud.service";
import PrintAndCopyService from "../../../services/print.and.copy.service";
import NetPromoterScore from "../../../components/chart/NetPromoterScore.vue";
import ChartButtons from "../../../components/chart/ChartButtons.vue";
import ChartCompanyMixins from "../../../mixins/ChartCompanyMixins.vue";
import ContentMixins from "../../../mixins/ContentMixins.vue";

import Vue from "vue";
import VueWordCloud from "vuewordcloud";
Vue.component(VueWordCloud.name, VueWordCloud);
// https://openbase.com/js/vuewordcloud
// https://seregpie.github.io/VueWordCloud/
// Potential REPLACEMENT https://www.npmjs.com/package/echarts-wordcloud
export default {
  name: "ByWordCloud",
  mixins: [ChartCompanyMixins, ContentMixins],
  components: {
    VueWordCloud,
    ChartButtons,
    NetPromoterScore
  },
  data() {
    return { 
      columnDefsNps: [],
      wordList: [],
      snapChartTooltipConfig: {
        content: "Click to take snapshot of the chart. ",
        trigger: "hover",
        delay: { show: 500, hide: 500 },
      },
      baseWordCloudClass: "width: 640px; position: relative",
      drawWordCloud: false,
      npsData: {},
      npsChartData: [],
      drawNPSChart: false,      
      wordCloudTiers: {
        high: 5,
        low:  3
      },
      table: {
        id: "word-count-list",
        class: "table display wrap row-border table-hover",
        configuration: {
          dom: '<"title-and-table-left"<"table-header"><B>>frtip',             
          searching: false,
          paging: true,
          pageLength: 25,
          select: true,
          processing: false,
          responsive: false,
          destroy: true,
          info: false,
          ordering: true,
          keys: true,
          sort: false,
          bSortable: false,
          bSort: false,
          buttons: [],
        },
        columns: WordCloudService.getWordListTableAttributes(),
      },
    };
  },
  props: {
    chartData: {
      type: Array,
      default: function () {
        return [];
      }
    },
    includeWordCloud: {
      type: Boolean,
      default: true
    },
    contentList: {
      type: Array,
      default: function() {
        return [];
      }
    },    
  },
  computed: {
    displayWordCloud: function () {
      if (typeof this.wordList != "undefined"){
        return this.wordList.length > 0
      }
      return false;
    },
  },
  methods: {
    async snapChart(displayLegend, snapContainerId) {
      PrintAndCopyService.initializeSnapChart(displayLegend, snapContainerId, this); 
    },
    displayInsights(insightModalId, reportPageTypeName) {
      if (reportPageTypeName == "Chart - Word Cloud") {
        this.$refs.insightsChart.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsChart.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      } else if (reportPageTypeName == "Table - Word Cloud") {
        this.$refs.insightsTable.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsTable.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      } 
    },    
    saveAllReportPages() {
      this.saveReportPage("word-cloud-display");
    },
    async saveReportPage(containerId, table) {
      if (this.$parent.clientChart.clientChartId != 0 && this.isGenerateReportRunning()) {
        let vm = this;
        setTimeout(async function () {
          let reportPageChart = $("#word-cloud-display");
          reportPageChart.addClass("word-cloud-display-container");
          let reportPageChartObject = reportPageChart[0];
          let imageReportPageChartContent = await vm.createChartImage(reportPageChartObject, false, true, 4,"pdf-content-image-word-cloud-width");
          await ReportService.saveReportPage(imageReportPageChartContent, "Chart - Word Cloud", vm.$parent.clientChart, vm.saveReportPageCallback, vm);
          await ReportService.saveReportPageFile(vm.imageUrl, "png", "Chart - Word Cloud", vm.$parent.clientChart, vm.saveReportPageCallback, vm);
          await ReportService.saveReportPage(
            vm.getReportTableContentByTable("word-count-list_wrapper"),
            "Table - Word Cloud",
            vm.$parent.clientChart,
            vm.saveReportPageCallback,
            vm
          );   
                    
          await ReportService.saveReportTableFile(
          TableService.getTableData(table),
          "Table - Word Cloud",
          vm.$parent.clientChart,
          vm.saveReportTableFileCallback,
          vm
        );            
        }, 1500);
      }
    },
    determineColor: function ([, weight]) {
      return weight > this.wordCloudTiers.high ? "DeepPink" : weight > this.wordCloudTiers.low ? "RoyalBlue" : "Indigo";
    },
    getWordCountBySurveyIdCallback: function (response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.wordList = response.data.data;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async processChartData() {
      try {
        this.drawWordCloud = false;
        this.drawNPSChart = false;

        let chartData = JSON.parse(JSON.stringify(this.chartData[0]));
        let otherData = chartData["otherData"];
        this.wordList = otherData["wordList"];
        this.$parent.setChartResults(chartData.companyList);
        this.$parent.$data.criteriaResultsList = AdminService.removeByName(chartData.criteriaList, "Competitor");
        this.npsData = otherData["npsDataTable"];
        this.npsChartData = otherData["npsChartData"];        
        if (typeof otherData["wordCloudTiers"] != "undefined"){
          this.wordCloudTiers = otherData["wordCloudTiers"];
        }

        this.setDisplayView(true);
        this.$parent.setDisplayView(true);

        let wordCloudCanvas = $("#word-cloud-canvas");
        let wordCloudTitleAndChart = $("#word-cloud-title-and-chart.title-and-chart-left");
        let wordCloudWidth = wordCloudCanvas.width();
        let wordCloudTitleWidth = wordCloudTitleAndChart.width();
        let finalWidth = wordCloudWidth - wordCloudTitleWidth;
        finalWidth -= 30;

        this.baseWordCloudClass = "width: " + finalWidth+ "px; height: 580px; position: relative";

        this.drawWordCloud = true;
        this.drawNPSChart = true;
        this.table.data = this.wordList;
        $.extend(this.table.configuration, { columns: this.table.columns });
        $.extend(this.table.configuration, { data: this.table.data });
        let isSavedCard = this.$parent.clientChart.clientChartId != 0;
        $.extend(this.table.configuration, TableService.getTableButtons(this, "word-cloud-data-container","profile-by-word-cloud-table","Table - Word Cloud",isSavedCard, "NPS Word Cloud"));
        this.table.configuration.order = [[1, "desc"]];
        this.table.dataTable = $("#" + this.table.id).DataTable(this.table.configuration);
        $("#" + this.table.id).show();

        const vm = this;
        setTimeout(function () {
          if (vm.includeWordCloud){
            vm.saveReportPage("word-cloud-display",vm.table.dataTable);
          }
        }, 1500);
      } catch (err) {
        ErrorService.processException("ByWordCloud.displayChartCallback", err, "chart", this);
      }
    },
    downloadComments() {
      let params = this.getChartCriteriaParams(this.$parent.clientChart, this.$parent.$refs.chartCriteria);
      WordCloudService.getDownloadComments(params, this.downloadItemCallback);
    },
    getDownloadFilename() {
      return "Survey Verbatims - " + this.$parent.survey.name;
    },
    downloadItemCallback(response) {
      this.$root.processServerResponse();
      this.setModalLoading(false);
      const blob = new Blob([response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = this.getDownloadFilename(); 
      link.download += ".xlsx";
      link.click();
      URL.revokeObjectURL(link.href);
      this.setModalLoading(false);
      AdminService.displaySuccessMessage({ text: "Download Complete" });
    },
  },
  watch: {
    chartData: function () {
      if (this.$parent.isByWordCloud || this.$parent.isMatchByWordCloud) {
        this.processChartData();
      } else {
        this.setDisplayView(false);
      }
    },
  },
  created() {
    this.columnDefsNps = TableService.getNpsTableColumnAttributes();
  },
  mounted() {},
};
</script>
