<template>
      <div class="respondent-download">
        <div>
          <a
            href="#"
            @click.prevent="downloadItem()"
            id="DownloadCompleteSurveyData"
            :class="[this.inputDisableDownloadButton ? 'btn btn-primary float-right mb-4' : 'btn btn-primary float-right mb-4 disabled']"
            role="button"
            aria-disabled="true"
          >
            <span class="align-text-bottom">Download Survey Data <font-awesome-icon :icon="['fa', 'download']"/></span>
            <span v-show="$parent.getModalLoading()" class="mb-1 align-text-bottom spinner-border spinner-border-sm"></span>
          </a>
          <div class="form-group p-2 float-right">
            <input
              type="checkbox"
              class="form-check-input"
              id="includeQualityGrades"
              name="includeQualityGrades"
              v-model="includeQualityGrades"
            />
            <label class="custom-checkout" for="includeQualityGrades">Include Quality Grades</label>
          </div>            
        </div>      
      </div>
</template>
<script>
import UserSettingsService from "../services/user.settings.service";
import UserService from "../services/user.service";
import AdminService from "../services/admin.service";
import ErrorService  from "../services/error.service";
import SurveySummaryService from "../services/survey.summary.service";

export default {
  name: "DownloadSurveyData",
  data() {
    return {
        includeQualityGrades: true,
    };
  },
  methods: {
    processUserSettings: function (response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        let userSettings = response.data.data;
        var inputIncludeQualityGrades = userSettings.find((x) => x.userSettingTypeName == "include-quality-grades");
        if (typeof inputIncludeQualityGrades != "undefined") {
          this.includeQualityGrades = inputIncludeQualityGrades.userSettingValue == "Y" ? true : false;
        }        
      }
    },    
    async downloadItem() {
      this.processingComplete = false;
      this.$parent.setupDownloadItem();
      const timer = setTimeout(() => {
        if (this.processingComplete == false || this.processingComplete == "false") {
          this.processingComplete = true;
          AdminService.displayInfoMessage({ text: "Processing is taking more than usual. The file will be sent via email.", duration: -1 });
		      this.$parent.setModalLoading(false);
          this.$root.setLoading(false);
        }
      }, 60000);
      await SurveySummaryService.getDownloadSurveyDataUrlMultiple(
			this.inputDownloadSets, this.includeQualityGrades, this.getDownloadSurveyDataUrlMultipleCallback, this);
      clearTimeout(timer);
    },
    getDownloadSurveyDataUrlMultipleCallback(response) {
      // If processing time is less than a 1 minute, file will be downloaded. 
      // Else it'll be sent through email from server side.
      if (this.processingComplete == false || this.processingComplete == "false") {
        this.processingComplete = true;
        this.$parent.downloadItemCallback(response);
      }
    },
    downloadItemError(error) {
      AdminService.displayErrorMessage({ text: "Error Downloading the file" });
      ErrorService.processError("DownloadSurveyData.downloadItem", error, "survey-status", this);
      this.$gtag.exception("Error Downloading the file");
    },
  },
  umnounted() {},
  computed: {},
  mounted() {
    UserSettingsService.getUserSettingsFromDatabase(UserService.getUserId(),  UserService.getUserCode(), this.processUserSettings, this);
  },
  watch: {},
  props: {
    inputDownloadSets: {
      type: Array,
      default() {
            return []
        }
    },
    inputDisableDownloadButton: {
      type: Boolean,
      default: false,
    },
  }
};
</script>
