<template>
  <div :class="classString" v-show="drawChart">
    <div :id="titleAndChartContainer" class="col-12 chart-data">    
      <div class="row" style="padding: 1rem;">
        <div class="col-2">
            <div class="d-flex"><h4 class="response-rates-title text-primary mr-1">Chart Types</h4><modal-link modal-id="Chart Types" :content-list="pageContentForNPS"></modal-link></div>
            <NPS-chart-buttons ref="chartButtons" 
                :input-show-labels="eChartCriteria.showLabels"
                :input-show-legend="eChartCriteria.showLegend"
                :input-angle-text="eChartCriteria.angleText"
                :input-e-chart-type="eChartCriteria.eChartType" 
                :input-show-goal-line="eChartCriteria.showGoalLine"
                @set-show-goal-line="setShowGoalLine"
                @set-angle-text="setAngleText"
                @set-chart="setChartType"
                @set-show-legend="setShowLegend"
                @set-show-labels="setShowLabels"
            />
        </div>    
        <NPS-chart ref="npsChart"
            :e-chart-mini-id="'nps-chart-mini'"
            :e-chart-id="'nps-chart'"
            :is-match="isMatch"
            :input-report-type-name="'Chart - Net Promoter Score'"
            :modal-id="chartInsightModalId"
            :raw-data="{data: npsChartData}" 
            :input-client-chart="this.$parent.$parent.clientChart"
            :is-saved-card="$parent.$parent.clientChart.clientChartId != 0"
            @save-table="saveNpsReportTable"
            @save-criteria="buildClientChart"/>        
      </div>
    </div>
    <div :id="dataContainerId" :class="isInsiderUser ? 'col-12 hide-table-data chart-data' : 'col-12 chart-data'">
      <div v-show="isInsiderUser" class="overlay">
            <img class="overlay-inner" v-show="isInsiderUser" src="../../assets/img/overlap.png" style="width: 250px"/>
      </div>
      <chart-data-table
        ref="npsDataTable"
        :chart-data="npsData"
        :is-saved-card="$parent.$parent.clientChart.clientChartId != 0"
        :display-direction="'vertical'"
        :data-container-id="dataContainerId"
        :container-id="containerId"
        :column-defs="this.columnDefsNps"
        :table-class="'table display row-border table-hover'"
        table-title='<h4 class="text-primary">Net Promoter Score</h4>'
        :table-width="'auto'"
        :report-page-type-name="tableReportPageTypeName"
        :insight-modal-id="tableInsightModalId"
        :inputDownloadFileName="'Net Promoter Score'"
      ></chart-data-table>
    </div>
    <insights
      ref="insightsNPSTable"
      :modal-name="tableInsightModalId"
      :report-page-type-name="tableReportPageTypeName"
      :entry-page-content="this.$parent.pageContentForReport"
      @saveReportPage="updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="saveClientChart"
    />
    <insights
      ref="insightsNPSChart"
      :modal-name="chartInsightModalId"
      :report-page-type-name="chartReportPageTypeName"
      :entry-page-content="this.$parent.pageContentForReport"
      @saveReportPage="updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="saveClientChart"
    />
  </div>
</template>
<script>
import $ from "jquery";

import ChartCompanyMixins from "../../mixins/ChartCompanyMixins.vue";
import ContentMixins from "../../mixins/ContentMixins.vue";
import ChartDataTable from "../../components/chart/ChartDataTable.vue";
import ChartButtons from "../../components/chart/ChartButtons.vue";

import ContentService from "../../services/content.service";
import TableService from "../../services/table.service";
import ChartService from "../../services/chart.service";
import ReportService from "../../services/report.service";
import Insights from "../../modals/Insights.vue";
import NPSChart from "../../views/echarts/NPSChart.vue";
import NPSChartButtons from "../../components/echarts/NPSChartButtons.vue";

export default {
  name: "NetPromoterScore",
  mixins: [ContentMixins, ChartCompanyMixins],
  components: { ChartDataTable, Insights, NPSChart, NPSChartButtons},
  data() {
    return {
      tableInsightModalId: "",
      chartInsightModalId: "",
      tableReportPageTypeName: "Table - Net Promoter Score",
      chartReportPageTypeName: "Chart - Net Promoter Score",
      npsChart: {},
      pageContentForNPS: []
    };
  },
  props: {
    drawChart: {
      type: Boolean,
      default: false
    },
    containerClass: {
      type: String,
      default: "col"
    },
    npsData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    npsChartData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    containerId: {
      type: String,
      default: "nps_chart"
    },
    dataContainerId: {
      type: String,
      default: "nps_data_container"
    },
    isMatch: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    titleAndChartContainer: function(){
      return this.containerId + "-title-and-chart";
    },
    classString: function() {
      return "nps-container " + this.containerClass;
    }
  },
  mounted() {},
  created() {
    this.columnDefsNps = TableService.getNpsTableColumnAttributes();
    this.chartInsightModalId = "nps-chart-" + this.dataContainerId;
    this.tableInsightModalId = "nps-table-" + this.dataContainerId;
    this.chart = ChartService.getChartAttributes("Net Promoter Score");
    if (!this.$parent.$parent.clientChart.clientChartId != 0){
          this.eChartCriteria.eChartType = "stacked-bar-horizontal";
    }
    ContentService.getPageContent("NPS", this.getPageContentByNPSCallBack, this);    
  },
  methods: {
    getPageContentByNPSCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.pageContentForNPS = response.data.data;
      }
    },      
    buildClientChart: function(){
      this.rebuildClientChart(this.$parent.$parent.clientId );
    },
    rebuildClientChart(client_id) {
      let useSaveParameters = typeof this.$parent.$parent.clientChart.criteria != undefined && this.$parent.$parent.clientChart.criteria != "" && this.$parent.$parent.autoGenerateChartRun;
      const params = useSaveParameters ? new URLSearchParams(this.$parent.$parent.clientChart.criteria) : this.$parent.$parent.$refs.chartCriteria.buildUrlParams(client_id);
      this.$parent.$parent.clientChart.criteria = params.toString();
    },

    setChartType(inputEChartType, inputRecalculate){
      this.eChartCriteria.eChartType = inputEChartType;
      this.$parent.$parent.$refs.chartCriteria.eChartCriteria.eChartType = inputEChartType;
      this.$refs.npsChart.processChart(this.drawChart,this.npsChartData,this.eChartCriteria);
    },
    setAngleText(inputAngleText){
      this.eChartCriteria.angleText = inputAngleText;
      this.$parent.$parent.$refs.chartCriteria.eChartCriteria.angleText = inputAngleText;
      this.$refs.npsChart.processChart(this.drawChart,this.npsChartData,this.eChartCriteria);
    },
    setShowGoalLine(inputShowGoalLine){
      this.eChartCriteria.showGoalLine = inputShowGoalLine;
      this.$parent.$parent.$refs.chartCriteria.eChartCriteria.showGoalLine = inputShowGoalLine;
      this.$refs.npsChart.processChart(this.drawChart,this.npsChartData,this.eChartCriteria);
    },
    setShowLegend(inputShowLegend){
      this.eChartCriteria.showLegend = inputShowLegend;
      this.$parent.$parent.$refs.chartCriteria.eChartCriteria.showLegend = inputShowLegend;
      this.$refs.npsChart.processChart(this.drawChart,this.npsChartData,this.eChartCriteria);
    },
    setShowLabels(inputShowLabels){
      this.eChartCriteria.showLabels = inputShowLabels;
      this.$parent.$parent.$refs.chartCriteria.eChartCriteria.showLabels = inputShowLabels;
      this.$refs.npsChart.processChart(this.drawChart,this.npsChartData,this.eChartCriteria);
    },
    saveClientChart(value, message) {
      this.$parent.saveClientChart(value, message);
    },
    updateReportPages(value) {
      this.$parent.updateReportPages(value);
    },
    saveAllReportPages() {
      this.$parent.saveAllReportPages();
    },
    displayInsights(modalId, reportPageTypeName) {
      if (reportPageTypeName == "Table - Net Promoter Score") {
        this.$refs.insightsNPSTable.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.$parent.reportPagesList);
        this.$refs.insightsNPSTable.initializeInsights(this.$parent.$parent.clientChart);
        this.$modal.show(modalId);
      } else if (reportPageTypeName == "Chart - Net Promoter Score") {
        this.$refs.insightsNPSChart.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.$parent.reportPagesList);
        this.$refs.insightsNPSChart.initializeInsights(this.$parent.$parent.clientChart);
        this.$modal.show(modalId);
      } 
    },
    saveNpsReportTable() {
       if (this.$parent.$parent.clientChart.clientChartId != 0 && this.isGenerateReportRunning()) {
        const vm = this;
        setTimeout(function() {    
          ReportService.saveReportPage(vm.$parent.getReportTableContent(vm.containerId), "Table - Net Promoter Score", vm.$parent.$parent.clientChart, vm.$parent.saveReportPageCallback, vm);
          ReportService.saveReportTableFile(
            vm.$refs.npsDataTable.getTableData(), "Table - Net Promoter Score", vm.$parent.$parent.clientChart, vm.saveReportTableFileCallback, vm
          );        
        }, 1500);
       }
    },
    setEChartCriteria: function(inputEChartCriteria){
      this.eChartCriteria = JSON.parse(JSON.stringify(inputEChartCriteria));
      if (!Object.prototype.hasOwnProperty.call(this.eChartCriteria, "eChartType")) {
		    this.eChartCriteria.eChartType = "stacked-bar-horizontal";
        this.$parent.$parent.$refs.chartCriteria.eChartCriteria.eChartType = this.eChartCriteria.eChartType;        
	    }
      if (!Object.prototype.hasOwnProperty.call(this.eChartCriteria, "angleText")) {
        this.eChartCriteria.angleText = false;  
        this.$parent.$parent.$refs.chartCriteria.eChartCriteria.angleText = this.eChartCriteria.angleText;        
      }	        
      if (!Object.prototype.hasOwnProperty.call(this.eChartCriteria, "showGoalLine")) {
        this.eChartCriteria.showGoalLine = true;
        this.$parent.$parent.$refs.chartCriteria.eChartCriteria.showGoalLine = this.eChartCriteria.showGoalLine;
      }	        
      if (!Object.prototype.hasOwnProperty.call(this.eChartCriteria, "showLegend")) {
		    this.eChartCriteria.showLegend = true;
        this.$parent.$parent.$refs.chartCriteria.eChartCriteria.showLegend = this.eChartCriteria.showLegend;
	    }
      if (!Object.prototype.hasOwnProperty.call(this.eChartCriteria, "showLabels")) {
        this.eChartCriteria.showLabels = true;  
        this.$parent.$parent.$refs.chartCriteria.eChartCriteria.showLabels = this.eChartCriteria.showLabels;
      }	        
      if (!Object.prototype.hasOwnProperty.call(this.eChartCriteria, "colorPaletteId")) {
        this.eChartCriteria.colorPaletteId = 0;
        this.$parent.$parent.$refs.chartCriteria.eChartCriteria.colorPaletteId = this.eChartCriteria.colorPaletteId;
      }	        
      this.buildClientChart();
    },    
  },
  watch: {
    npsChartData: function(){
      if (this.drawChart == true){
        this.setEChartCriteria(this.$parent.$parent.$refs.chartCriteria.eChartCriteria);
        let period = this.$parent.$parent.$refs.chartCriteria.$data.period_options.find(x => x.value == this.$parent.$parent.$refs.chartCriteria.$data.period);
        if (typeof period != "undefined") {
          this.eChartCriteria.periodName =  period.name;
        } else {
          this.eChartCriteria.periodName = "";
        }
        this.$refs.npsChart.processChart(this.drawChart,this.npsChartData,this.eChartCriteria);
      }
    },
  }
};
</script>
