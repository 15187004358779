import BaseChartService from "./base.chart.service";
import ProfileService from "./profile.service";

import $ from "jquery";

class ScatterService extends BaseChartService {
    constructor() {
        super();
    }
    grid = {};
    xAxis = [];
    yAxis = [];
    getGrid() {
        return this.grid;
    }
    setGrid(grid) {
        this.grid = grid;
    }
    getXAxis() {
        return this.xAxis;
    }
    setXAxis(xAxis) {
        this.xAxis = xAxis;
    }
    getYAxis() {
        return this.yAxis;
    }
    setYAxis(yAxis) {
        this.yAxis = yAxis;
    }
    getSeries() {
        return this.getSeriesData().map((seriesData, sid) => {
            let label = {
                formatter: function(param) {
                    return param.value == 0 ? '' : param.value + '%'
                },
                show: this.getShowLabel()
            };
            let labelTextStyle = this.getDefaultLabelTextStyle();
            $.extend(true, label, labelTextStyle);                 
            let series = {
                name: seriesData.name,
                type: seriesData.type,
                label: label,
                avoidLabelOverlap: true,
                xAxisIndex: seriesData.xAxisIndex,
                yAxisIndex: seriesData.yAxisIndex,
                gridIndex: seriesData.gridIndex,
                data: seriesData.data
            };
            $.extend(true, series, seriesData);
            return series;
        });        
    }
    setData(inputTimePeriods,selectedQuestion) {
        let legendData = [];
        let seriesData = [];
        let axisData = {
            name: "",
            data: []
        };           
        for (let i = 0; i < selectedQuestion.options.length; i++) {
            let option = selectedQuestion.options[i];
            axisData = {
                name: option.label,
                data: []
            };          
            legendData.push(option.label);    
            for (let j = 0; j < inputTimePeriods.length; j++) {
                let selectedTimePeriod = inputTimePeriods[j];
                let countItem = option.counts.find(item => item.timePeriod === selectedTimePeriod);
                if (typeof countItem != 'undefined' && countItem.count != null) {
                    axisData.data.push(countItem.count);
                } 
            } 
            seriesData.push(axisData);      
        }
        this.setLegendData(legendData);
        this.setSeriesData(seriesData);
    }
    getOptions(app,modalId,reportPageTypeName,inputDownloadFileName) {
        let legend;
        legend = ProfileService.getDefaultLegendProfileChart();
        let data = {
            data: this.getLegendData()
        }
        $.extend(true, legend, data);            
        let tooltip = {
            axisPointer: {
                type: 'shadow' 
            },
            trigger: "axis",
            formatter: this.toolTipForStackedAreaChart,
            textStyle: this.getDefaultLabelTextStyle()
        };        
        let option = {
            legend: legend,
            toolbox: this.getDefaultToolBox(app,modalId,reportPageTypeName,inputDownloadFileName),
            tooltip: tooltip,            
            grid: this.getGrid(),
            yAxis: this.getYAxis(),
            xAxis: this.getXAxis(),
            series: this.getSeries(),
            color: this.getColorPalette()
        };
        return option;
    }
}
export default new ScatterService();
