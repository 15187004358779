<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div v-if="getDisplayView()" class="add-client">
      <div class="p-2">
        <router-link :to="{ name: 'client-list' }">
          <button type="button" class="btn btn-success" id="ReturnToContentListButton">Return to Client List</button>
        </router-link>
      </div>
      <div class="client-row">
        <label class="col-3">Not Active</label>
        <toggle-button v-model="isActive" :value="isActive" :sync="true" color="rgb(191, 203, 217)" :speed="100" @change="setActive"/>
        <label for="active" class="ml-1">Active</label>
      </div>  
      <div class="client-row">
        <label for="clientName" class="col-3">Client Name </label>
        <input v-model="formClientObject.name" id="clientName" name="clientName" class="form-control col-6" type="text" @blur="setSurveyName" />
        <div class="ml-1"></div>

        <button class="fg-color-sample" @click="pickColorForMainCompany()" :style="{ background: formClientObject.color }"></button>
        <color-picker ref="colorPickerForMainCompany" modal-name="main-company-color-picker" @save="updateMainCompanyColor" />
      </div>
      <div class="client-row">      
          <label for="comp5" class="col-3 text-right">Subscription End Date</label>
          <datepicker
            format="MMM dd yyyy"
            v-model="formClientObject.subscriptionEndDate"
            wrapper-class=""
            input-class="form-control required w-100 bg-white"
            name="subscriptionEndDate"
          ></datepicker>
          <label for="includeW50" class="col-2 text-right">Show W-30 as Rank Group</label>
          <input type="checkbox" id="includeW50" name="includeW50" v-model="formClientObject.includeW50" @click="setIncludeW50($event)" true-value="Y" false-value="N" />
          <benchmark-exclusion 
            :input-client-code="initialClientCode"    
          />
      </div>
      <div class="divider"></div>
      <div class="client-row">
        <label for="isParent" class="col-3">Is Parent</label>
        <input type="checkbox" id="isParent" name="isParent" v-model="formClientObject.isParent" @click="setIsParent($event)" true-value="Y" false-value="N" :disabled="this.formClientObject.clientId == 0"/>
        <div v-show="formClientObject.isParent == 'Y'">
          <div v-show="this.formClientObject.clientId == 0" class="ml-2 mt-2 font-italic float-right">Please save the client before configuring the hierarchy</div>
          <button :disabled="this.formClientObject.clientId == 0" type="submit" class="btn btn-primary float-right ml-2" @click="toggleDisplayClientHierarchy()">
            <span>Edit Hierachy</span>
            <span v-show="false" class="spinner-border spinner-border-sm"></span>
          </button>
        </div>
        <div class="d-flex col-7">
          <label for="parentClientId" class="w-25 pr-2">Parent Client</label>
          <select id="parentClientId" v-model="formClientObject.parentClientId" class="form-control w-100" :disabled="this.formClientObject.clientId == 0">
            <option v-for="(parent_client_option, idxParentClientOptions) in parent_client_options" v-bind:value="parent_client_option.clientId" v-bind:key="idxParentClientOptions">
              <span>{{ parent_client_option.name }}</span>
            </option>
          </select>
        </div>
      </div>
      <div v-show="displayClientHierarchy" class="client-row">
        <div class="col-12">
          <edit-client-hierarchy :initial-client-id="formClientObject.clientId" :initial-client-code="formClientObject.clientCode"></edit-client-hierarchy>
        </div>
      </div>
      <div class="divider"></div>
      <div class="client-row">
        <div class="col-3"></div>
        <div class="message">Altering the settings below requires you to manually re-configure Functional Needs and Waves.</div>
      </div>
      <div class="client-row">
        <label for="sectorId" class="col-3">Sector</label>
        <select id="section_options" v-model="formClientObject.sectorId" class="form-control col-9" :disabled="mode == 'edit'">
          <option v-for="(sector_option, idxSectorOptions) in sector_options" v-bind:value="sector_option.sectorId" v-bind:key="idxSectorOptions">
            <span>{{ sector_option.name }}</span>
          </option>
        </select>
      </div>
      <div v-if="mode != 'edit'" class="client-row">
        <label for="newSurveyName" class="col-3 text-right">Survey Name</label>
        <input v-model="newSurveyName" id="newSurveyName" name="newSurveyName" type="text" class="form-control col-9" />
      </div>
      <div class="client-row">
        <label for="numberOfPeriodsOptions" class="options-column text-right">Number of Periods for a Year</label>
        <div class="options-column d-flex flex-column flex-wrap">
          <div class="w-100" v-for="(radioOption, idxRadio) in this.number_of_periods_options" v-bind:key="idxRadio">
            <input
              v-model="clientSettings.numberOfPeriods"
              type="radio"
              :class="'form-check-input'"
              :id="'numberOfPeriodsLabel_'+idxRadio"
              name="numberOfPeriodsLabel"
              :value="radioOption.value"
            />{{ radioOption.name }}
          </div>        
        </div>        
        <label for="periodOptions" class="options-column col-2 text-right">New Start Period for Gridlines in a Chart Display</label>
        <div class="options-column d-flex flex-column flex-wrap">
          <div class="w-30" v-for="(radioOption, idxRadioPeriod) in this.periodList" v-bind:key="idxRadioPeriod">
            <input
              v-model="clientSettings.startPeriod"
              type="radio"
              :class="'form-check-input'"
              :id="'periodLabel_'+idxRadioPeriod"
              name="periodLabel"
              :value="radioOption.value"
            />Period {{ radioOption.value }}
          </div>        
        </div>        
      </div>
      <div class="client-row">
      </div>
      <div class="client-row">
        <div class="col" role="group">
          <router-link :to="{ name: 'include-sections' }" class="btn btn-success float-right ml-2" v-show="mode == 'edit'"> Configure Survey </router-link>
          <button type="submit" :disabled="disableButton" class="btn btn-primary float-right ml-2" @click="saveClient()">
            <span>Submit</span>
            <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
        </div>
      </div>
      <client-note v-if="displayClientNote" :client-code="formClientObject.clientCode"></client-note>
      <client-document v-if="displayClientDocument" :client-code="formClientObject.clientCode"></client-document>
    </div>
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

require("jquery-validation"); 

import AdminMixins from "../../mixins/AdminMixins.vue";

import ClientService from "../../services/client.service";
import ClientSettingsService from "../../services/client.settings.service";
import ClientTimePeriodService from "../../services/client.time.period.service";
import ChartService from "../../services/chart.service";
import AdminService from "../../services/admin.service";
import EditClientHierarchy from "./EditClientHierarchy.vue";
import ColorPicker from "../../modals/ColorPicker.vue";
import ClientNote from "../../components/ClientNote.vue";
import ClientDocument from "../../components/ClientDocument.vue";
import BenchmarkExclusion from "../../components/BenchmarkExclusion.vue";

export default {
  name: "AddClient",
  mixins: [AdminMixins],
  components: {
    Datepicker,
    EditClientHierarchy,
    ColorPicker,
    BenchmarkExclusion,
    ClientNote,
    ClientDocument
  },
  data() {
    return {
      mode: "",
      title: "Add New Client",
      clientSettings: {
        numberOfPeriods: 4,
        startPeriod: 1
      },      
      periods_options: ClientTimePeriodService.getPeriodOptions(),
      number_of_periods_options : ClientTimePeriodService.getNumberOfPeriodOptions(),
      clientCompetitors: [
        { name: "", clientCompetitorsId: 0, competitorColor: "#A80000" },
        { name: "", clientCompetitorsId: 0, competitorColor: "#948A54" },
        { name: "", clientCompetitorsId: 0, competitorColor: "#F29700" },
        { name: "", clientCompetitorsId: 0, competitorColor: "#84E2E0" },
        { name: "", clientCompetitorsId: 0, competitorColor: "#B3A2C7" }
      ],
      baseCompetitors: [
        { name: "", clientCompetitorsId: 0, competitorColor: "#A80000" },
        { name: "", clientCompetitorsId: 0, competitorColor: "#948A54" },
        { name: "", clientCompetitorsId: 0, competitorColor: "#F29700" },
        { name: "", clientCompetitorsId: 0, competitorColor: "#84E2E0" },
        { name: "", clientCompetitorsId: 0, competitorColor: "#B3A2C7" }
      ],
      sector_options: [],
      parent_client_options: [],
      newSurveyName: "",
      displayClientHierarchy: false,
      defaultSubscriptionEndDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      isActive: true,
      formClientObject: {
        active: "Y",
        clientId: 0,
        sectorId: 0,
        parentClientId: 0,
        isParent: "N",
        includeW50: "Y",
        color: "#1c408e",
        clientCode: "",
        subscriptionEndDate: this.defaultSubscriptionEndDate
      },
      disableButton: false
    };
  },
  methods: {
    pickColorForMainCompany() {
      this.$refs.colorPickerForMainCompany.$data.currentColor.hex = this.formClientObject.color;
      this.$modal.show("main-company-color-picker");
    },
    updateMainCompanyColor(result) {
      this.formClientObject.color = result;
    },
    setIsParent: function(e) {
      if (e.target.checked) {
        this.formClientObject.isParent = "Y";
      } else {
        this.formClientObject.isParent == "N";
      }
    },
    setIncludeW50: function(e) {
      if (e.target.checked) {
        this.formClientObject.includeW50 = "Y";
      } else {
        this.formClientObject.includeW50 == "N";
      }
    },
    toggleDisplayClientHierarchy() {
      this.displayClientHierarchy = !this.displayClientHierarchy;
    },
    loadParentClients(response) {
      this.parent_client_options = response.data;
      this.parent_client_options = AdminService.removeByName(this.parent_client_options, this.formClientObject.name);
    },
    setActive() {
      this.formClientObject.active = this.isActive ? "Y" : "N";
    },
    setSurveyName() {
      if (this.newSurveyName == "") {
        this.newSurveyName = this.formClientObject.name + " - Default Survey";
      }
    },
    setCompetitors() {
      this.clientCompetitors = this.formClientObject.competitors
        .filter(function(clientCompetitor) {
          if (clientCompetitor.map.surveyId == 0) {
            return clientCompetitor.map;
          }
        })
        .map(finalClientCompetitor => finalClientCompetitor.map);
      for (let index = this.clientCompetitors.length; index < 5; index++) {
        this.clientCompetitors.push(this.baseCompetitors[index]);
      }
    },
    async getClient() {
      this.$root.processServerRequest("Retrieving client");
      await ClientService.getClientWithDefaultCompetitors(this.initialClientCode, this.getClientCallback, this);
      await ClientService.getAllParentClients(this.loadParentClients, this);
    },
    getClientCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.formClientObject = JSON.parse(response.data.data.client);
        if (this.mode == "duplicate") {
          this.formClientObject.clientId = 0;
          this.formClientObject.name = this.formClientObject.name + " 1";
          this.setSurveyName();
        }
        if (typeof this.formClientObject.subscriptionEndDate == "undefined") {
          this.formClientObject.subscriptionEndDate = this.defaultSubscriptionEndDate;
        }
        this.clientSettings = ClientSettingsService.parseClientSettings(response.data.data.settings);
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.setCompetitors();
        this.isActive = this.formClientObject.active == "Y";
        this.setDisplayView(true);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async saveClient() {
      this.$root.processServerRequest("Saving client");
      ClientService.saveClientToDatabase(this.mode, this.newSurveyName, this.formClientObject, this.clientCompetitors, this.clientSettings, this.saveClientCallback, this);
    },
    saveClientCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        ClientService.clearCache();
        ClientService.getAllClientsFromDatabase(this.$parent.$parent.getAllClientsCallback);
        this.formClientObject = JSON.parse(response.data.data);
        if (this.mode == "add" || this.mode == "duplicate") {
          this.$parent.$parent.changeClientCodeValue(this.formClientObject.clientCode);
        }
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.mode = "edit";
        this.setCompetitors();
        this.isActive = this.formClientObject.active == "Y";
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    loadSectors(response) {
      this.sector_options = response;
    }
  },
  computed: {
    periodList(){
      return this.periods_options.slice(0,this.clientSettings.numberOfPeriods);
    },
    displayClientNote: function(){
      return this.mode == "edit";
    },
    displayClientDocument: function(){
      return this.mode == "edit";
    }
  },
  created() {
    ChartService.getAllSectors(this.baseUrl, this.loadSectors);
    this.mode = this.initialMode;
    if (this.mode == "edit" || this.mode == "duplicate") {
      this.getClient();
      this.title = this.mode == "edit" ? "Modify Client" : "Copy Client";
    } else {
      if (typeof this.formClientObject.subscriptionEndDate == "undefined") {
        this.formClientObject.subscriptionEndDate = this.defaultSubscriptionEndDate;
      }
      this.setDisplayView(true);
    }
  },
  props: {
    initialMode: {
      type: String,
      default: "add"
    },
    initialClientId: {
      type: Number,
      default: 0
    },
    initialClientCode: {
      type: String,
      default: ""
    }
  },
  mounted() {}
};
</script>
