<template>
  <div :class="getClass(dashboardLayoutCard.dashboardCardTypeCode, dashboardLayoutCard.sequenceNumber)">
    <div class="dashboard-header">
      <div class="dashboard-title">
        <div class="dashboard-title-details">
          <a href="/respondent/rates" @click="$parent.logViewDetailsEvent(title)">                      
            {{ title }}
          </a>
          <smart-center-link v-if="displayDashboard" page="Competitive Dashboard" location="Respondents" />
        </div>
        <div class="dashboard-save-dashboard-card"  @click="$modal.show('respondents-map-save-chart-confirmation')"> 
            Save for Report
            <span class="m-1">
              <font-awesome-icon :icon="['fa', 'floppy-disk']" />
            </span>
        </div>
      </div>
      <div class="dashboard-widgets dashboard-respondents-map">
        <dashboard-card-dropdown label="View by" :read-only="readOnly" :options="view_by_options" :default-value="viewBy" @change="saveViewBy"></dashboard-card-dropdown>
      </div>
      <dashboard-card-expand :dashboard-id="dashboardId" />
    </div>
    <div class="dashboard-divider"></div>
    <dashboard-card-skeleton v-if="!displayDashboard" />
    <div v-if="displayDashboard" class="dashboard-card-body">
      <div id="respondents-mini" class="respondent-count" :style="dashboardChartStyle">
        <div class="original-dashboard-display row">
          <div id="todayVisitUserPieChart" style="width: 100%">
            <respondent-map v-if="displayDashboard" :map-data="respondentsByLocation" :min-height="'200'" :map-type="viewBy" @save-image="saveImage"/>
          </div>
        </div>
        <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
        <respondent-counts :respondent-counts="respondentCounts"></respondent-counts>
      </div>
    </div>
    <!-- :updatedModalChartOptions.sync="respondentMapOptions.series[0].data" -->
    <dashboard-modal 
      :modal-id="dashboardId" 
      :chart="this.chart" 
      :client-chart="this.clientChart" 
      :dashboard-card-report-page-type-name="reportTypeName" 
      :modal-title="title" 
      :modal-chart-options="respondentMapOptions" 
      :modal-chart-data="respondentsByLocation" 
      :respondent-counts="respondentCounts" 
      :show-respondent-counts="true"
      />
    
    <vue-modal :ref="modalName" :name="modalName" :width="350" :height="200">
      <save-chart :modal-name="modalName" button-label="Save for Report" :chart-title="chartTitle" :initial-client-chart="this.clientChart" @save="this.updateClientCharts" @closeModal="closeModal(this.modalName)"></save-chart>
    </vue-modal>
  </div>
</template>

<script>
import DashboardMixins from "../../mixins/DashboardMixins.vue";
import DashboardService from "../../services/dashboard.service";
import AdminService from "../../services/admin.service";
import RespondentMap from "../RespondentMap.vue";
import RespondentCounts from "../RespondentCounts.vue";
import SaveChart from "../chart/SaveChart.vue";
import DashboardCardSkeleton from "../../components/skeleton/DashboardCardSkeleton.vue";

import DashboardCardDropdown from "./DashboardCardDropdown";

export default {
  name: "DashboardRespondents",
  mixins: [DashboardMixins],
  components: {
    RespondentMap,
    RespondentCounts,
    DashboardCardDropdown,
    DashboardCardSkeleton,
    SaveChart
  },
  data() {
    return {
      title: "Respondent Map",
      modalName: "respondents-map-save-chart-confirmation",
      viewBy: "USA",
      view_by_options: [
        { name: "USA", value: "USA" },
        { name: "Argentina", value: "Argentina" },
        { name: "Australia", value: "Australia" },
        { name: "Brazil", value: "Brazil" },
        { name: "Canada", value: "Canada" },
        { name: "China", value: "China" },
        { name: "Columbia", value: "Columbia" },
        { name: "France", value: "France" },
        { name: "Germany", value: "Germany" },
        { name: "India", value: "India" },
        { name: "Italy", value: "Italy" },
        { name: "Indonesia", value: "Indonesia" },
        { name: "Mexico", value: "Mexico" },
        { name: "Peru", value: "Peru" },
        { name: "Portugal", value: "Portugal" },
        { name: "Romania", value: "Romania" },
        { name: "Spain", value: "Spain" },
        { name: "Thailand", value: "Thailand" },
        { name: "UK", value: "UK" },        
        { name: "World", value: "World" }
      ],
      respondentCounts: {
        total: 0,
        completes: 0,
        partials: 0,
        completionRate: 0
      },
      respondentMapOptions: {},
      respondentsByLocation: [],
    };
  },
  props: {},
  computed: {},
  mounted() {
    // eslint-disable-next-line no-console
    //console.log("DashboardRespondents::mounted");
    this.chartTitle = "Respondent Map";
    this.containerId = "respondents-mini"
	  this.reportTypeName = "Dashboard - Respondent Map"
    this.chartTypeName = "respondent-map";
    this.resetChart();
  },
  created() {},
  methods: {
    resetData(){
      this.respondentsByLocation = [];
      this.respondentMapOptions = {};
      this.respondentCounts = {
        total: 0,
        completes: 0,
        partials: 0,
        completionRate: 0
      };
    },
    saveImage(url){
      this.imageUrl = url;
    },
    processSavedChartCriteria(criteria) {
      let criteriaParameters = criteria.split("=");
      let isViewBy = criteriaParameters[0] == "viewBy" ? true : false;
  		if (isViewBy){
        this.viewBy = criteriaParameters[1];
  		}
    },
    buildUrlParams(client_id) {
      let params = this.buildBaseUrlParams();
      params.append("viewBy",this.viewBy);
      return params;
    },   
    loadData: function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardRespondents::loadData");
      this.displayDashboard = false;
      if (this.autoGenerateChartRun){
        this.processAutoGenerateChart();
      } else {
        this.viewBy = DashboardService.getDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_RESPONDENTS_MAP_VIEW_SETTING_TYPE, this.viewBy, this.view_by_options);
      }
      DashboardService.getRespondents(this.timePeriodId, this.viewBy, this.getRespondentsCallback, this);
    },
    saveViewBy: function(value) {
      this.viewBy = value;
      DashboardService.setDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_RESPONDENTS_MAP_VIEW_SETTING_TYPE, this.viewBy);
      this.updateCard();
    },
    updateCard: function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardRespondents::updateCard");
      this.displayDashboard = false;
      this.viewBy = DashboardService.getDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_RESPONDENTS_MAP_VIEW_SETTING_TYPE, this.viewBy, this.view_by_options);
      DashboardService.getRespondents(this.timePeriodId, this.viewBy, this.getRespondentsCallback, this);
    },
    getRespondentsCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.respondentCounts = response.data.data.respondentCounts;
        this.respondentsByLocation = response.data.data.respondentsByLocation;
        this.displayDashboard = true;
        this.respondentMapOptions = DashboardService.getRespondentMapOptions();
        if (!this.autoGenerateChartRun){
          this.setupClientChart();
        }  
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    }
  },
  watch: {
    entrySurveyCode: function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardRespondents::entrySurveyCode");
      if (this.entrySurveyCode == ""){
        this.resetData();
      }
      this.resetChart();
    },
    // when location is changed, update the related map data (part of vue migration)
    respondentsByLocation: function() {
      this.respondentMapOptions.series[0].data = this.respondentsByLocation;
    }
  }
};
</script>
