<template>
  <div>
    <div class="dashboard-period">
      <div class="dashboard-column d-flex pl-0">
        <div class="form-group form-inline mb-0">        
          <div class="control-label">Period</div>
          <div class="pl-1">
            <select id="dashboardPeriod" name="dashboardPeriod" class="custom-select required" :disabled="this.$root.getLoading()" v-model="currentTimePeriod" @change="logChangeEvent()">
              <option value="">Choose A Time Period</option>
              <option v-for="(option, idxPeriodOptions) in client_time_periods" v-bind:value="String(option.clientTimePeriodId)" v-bind:key="idxPeriodOptions">
                {{ option.chartDisplayName }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group form-inline mb-0 pl-4">
          <div class="control-label ">Trends Start Period</div>
          <div class="pl-1">
            <select id="dashboardPeriod" name="dashboardPeriod" class="custom-select required" :disabled="this.$root.getLoading()" v-model="startTimePeriod" @change="logChangeEventForStartTimePeriod()">
              <option value="">Choose A Time Period</option>
              <option v-for="(option, idxPeriodOptions) in client_time_periods" v-bind:value="String(option.clientTimePeriodId)" v-bind:key="idxPeriodOptions">
                {{ option.chartDisplayName }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="dashboard-column"></div>
      <div class="dashboard-column">
        <div class="float-right pt-3 pr-3" @click="goToDashboardLayout">
          <i v-tooltip="tooltipConfig" class="fa-duotone fa-gear-complex" style="cursor: pointer;"></i>
        </div>
      </div>
    </div>
    <div v-if="displayCards" class="dashboard-card-container">
        <dashboard-respondents ref="respondents" dashboard-id="respondents" :time-period-id="currentTimePeriod" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'respondents-map',false)"
          :load-content="false"
          :entry-survey-code="entrySurveyCode" 
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'respondents-map',false)"
        />
        <dashboard-expectations-map ref="expectationsMap" dashboard-id="expectationsMap" :time-period-id="currentTimePeriod" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'expectations-map',false)"
          :load-content="false"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'expectations-map',false)"
        />
        <dashboard-performance-trends ref="performanceTrends" dashboard-id="performanceTrends" 
          :time-period-id="currentTimePeriod" 
          :input-start-time-period-id="startTimePeriod"
          :entry-survey-code="entrySurveyCode" 
          :input-metric-id="metricId" 
          :input-metric-options="metric_options"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'performance-trends',false)"
          :load-content="false"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'performance-trends',false)"
        />
        <dashboard-competitive-trends ref="competitiveTrends" dashboard-id="competitiveTrends" :time-period-id="currentTimePeriod" 
          :input-start-time-period-id="startTimePeriod"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'competitive-trends')"
          :entry-survey-code="entrySurveyCode" 
          :load-content="false"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'competitive-trends',false)"
        />
        <dashboard-competitive-rank ref="competitiveRank" dashboard-id="competitiveRank" :time-period-id="currentTimePeriod" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'competitive-rank',false)"
          :entry-survey-code="entrySurveyCode" 
          :load-content="false"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'competitive-rank',false)"
        />
        <dashboard-nps-trends ref="npsTrends" dashboard-id="npsTrends" :time-period-id="currentTimePeriod" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'nps-trends',false)"
          :input-start-time-period-id="startTimePeriod"
          :load-content="false"
          :entry-survey-code="entrySurveyCode" 
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'nps-trends',false)"
        />
        <dashboard-needs-profile ref="needsProfile" dashboard-id="needsProfile" :time-period-id="currentTimePeriod" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'needs-profile',false)"
          :load-content="false"
          :load-competitor-data="false"
          :entry-survey-code="entrySurveyCode" 
          :initial-competitors-data="competitor_options"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'needs-profile',false)"
        />
        <dashboard-growth-sequence ref="growthSequence" dashboard-id="growthSequence" :time-period-id="currentTimePeriod" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'growth-sequence',false)"
          :load-content="false"
          :load-competitor-data="false"
          :entry-survey-code="entrySurveyCode" 
          :initial-competitors-data="competitor_options"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'growth-sequence',false)"
        />
        <dashboard-winners-map ref="growthSequence" dashboard-id="winnersMap" :time-period-id="currentTimePeriod" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'winners-map',false)"
          :entry-survey-code="entrySurveyCode" 
          :load-content="false"
          :load-competitor-data="false"
          :initial-competitors-data="competitor_options"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'winners-map',false)"
        />
        <dashboard-predictive-analytics-contributions ref="predictiveAnalytics" 
            dashboard-id="predictiveAnalytics" 
            :input-metric-options="metric_options"
            :time-period-id="currentTimePeriod" 
            :input-is-pricing-power="true"
            :input-metric-id="metricId" 
            :entry-survey-code="entrySurveyCode" 
            :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'predictive-analytics',false)"
            :load-content="false"
            :initial-page-content-for-report="pageContentForReport"
            v-if="displayDashboardCard(dashboardLayoutCards,'predictive-analytics',false)"
        />
        <dashboard-predictive-analytics-contributions ref="metricContributions" 
            dashboard-id="metricContributions" 
            :input-metric-options="metric_options"
            :time-period-id="currentTimePeriod" 
            :input-is-pricing-power="false"
            :input-metric-id="metricId" 
            :entry-survey-code="entrySurveyCode" 
            :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'metric-contributions',false)"
            :load-content="false"
            :initial-page-content-for-report="pageContentForReport"
            v-if="displayDashboardCard(dashboardLayoutCards,'metric-contributions',false)"
        />
        <dashboard-welcome :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'welcome',false)"
          :load-content="false"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'welcome',false)"
        />
        <dashboard-rank ref="npsRank" 
          dashboard-id="npsRank" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'nps-rank',false)"
          :load-content="false"
          rank-type="nps-rank"
          :entry-survey-code="entrySurveyCode" 
          :time-period-id="currentTimePeriod" 
          :input-group-options="surveyGroupList"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'nps-rank',false)"
        />
        <dashboard-rank ref="moatScoreRank"
          dashboard-id="moatScoreRank" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'moat-score-rank',false)"
          :load-content="false"
          rank-type="moat-score-rank"
          :entry-survey-code="entrySurveyCode" 
          :time-period-id="currentTimePeriod" 
          :input-group-options="surveyGroupList"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'moat-score-rank',false)"
        />
        <dashboard-rank ref="wScoreRank"
          dashboard-id="wScoreRank" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'w-score-rank',false)"
          :load-content="false"
          rank-type="w-score-rank"
          :entry-survey-code="entrySurveyCode" 
          :input-group-options="surveyGroupList"
          :time-period-id="currentTimePeriod" 
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'w-score-rank',false)"
        />
        <dashboard-moat-comps ref="moatComps" 
          dashboard-id="moatComps" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'moat-comps',false)"
          :time-period-id="currentTimePeriod" 
          :input-group-options="surveyGroupList"
          :load-competitor-data="true"
        />
        <dashboard-moneyball-stick ref="moneyballStick" 
          dashboard-id="moneyballStick" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'moneyball-stick',false)"
          :time-period-id="currentTimePeriod" 
          :load-competitor-data="true"
        />

    </div>  
  </div>
</template>

<script>
import ContentService from "../services/content.service";
import ClientCompetitorService  from "../services/client.competitor.service";
import AdminService from "../services/admin.service";
import UserSettingsService from "../services/user.settings.service";
import ClientTimePeriodService from "../services/client.time.period.service";
import GoogleService from "../services/google.service";
import MetricsService from "../services/metrics.service";
import SurveyService from "../services/survey.service";
import UserService from "../services/user.service";
import SurveyGroupService from "../services/survey.group.service";
import DashboardService from "../services/dashboard.service";
import DashboardRespondents from "./dashboard/DashboardRespondents.vue";
import DashboardCompetitiveRank from "./dashboard/DashboardCompetitiveRank.vue";
import DashboardCompetitiveTrends from "./dashboard/DashboardCompetitiveTrends.vue";
import DashboardExpectationsMap from "./dashboard/DashboardExpectationsMap.vue";
import DashboardPerformanceTrends from "./dashboard/DashboardPerformanceTrends.vue";
import DashboardPredictiveAnalyticsContributions from "./dashboard/DashboardPredictiveAnalyticsContributions.vue";
import DashboardNpsTrends from "./dashboard/DashboardNpsTrends.vue";
import DashboardNeedsProfile from "./dashboard/DashboardNeedsProfile.vue";
import DashboardGrowthSequence from "./dashboard/DashboardGrowthSequence.vue";
import DashboardWinnersMap from "./dashboard/DashboardWinnersMap.vue";
import DashboardWelcome from "./dashboard/DashboardWelcome.vue";
import DashboardMoatComps from "./dashboard/DashboardMoatComps.vue";
import DashboardMoneyballStick from "./dashboard/DashboardMoneyballStick.vue";

import DashboardRank from "./dashboard/DashboardRank.vue";
import $ from "jquery";

export default {
  name: "DashboardCards",
  mixins: [],
  components: {
    DashboardRespondents,
    DashboardCompetitiveRank,
    DashboardCompetitiveTrends,
    DashboardExpectationsMap,
    DashboardPerformanceTrends,
    DashboardPredictiveAnalyticsContributions,
    DashboardNpsTrends,
    DashboardNeedsProfile,
    DashboardGrowthSequence,
    DashboardWinnersMap,
    DashboardWelcome,
    DashboardMoatComps,
    DashboardMoneyballStick,
    DashboardRank
  },
  data() {
    return {
      currentTimePeriod: "",
      startTimePeriod: "",
      pageContentForReport: [],
      competitor_options: [],
      client_time_periods: [],
      defaultDashboardValues: {},
      dashboardLayoutCards: [],
      surveyGroupList: [],
      displayCards: false,
      metricId: "",
      metric_options: [],
      tooltipConfig: {
        placement: "right",
        content: "Click to configure the dashboard layout ",
        trigger: "hover",
        delay: { show: 500, hide: 500 }
      },     
      defaultGroup: { dashboardCardId: 0, id: 0, sequenceNumber: 100, dashboardCardTypeCode: "" },
    };
  },
  props: {
    entrySurveyCode: {
      type: String,
      default: ""
    },
    clientObject: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  computed: {},
  mounted() {},
  created() {
    ContentService.getPageContent("Report", this.getPageContentByReportCallBack, this);
    this.loadData();
  },
  methods: {
    getPageContentByReportCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.pageContentForReport = response.data.data;
      }
    },
    displayDashboardCard: function(layoutCards, code) {
      return DashboardService.displayDashboardCard(layoutCards, code);
    },
    getDashboardLayoutCard: function(layoutCards, code) {
      return DashboardService.getDashboardLayoutCard(layoutCards, code);
    },
    goToDashboardLayout: function() {
      GoogleService.logClickEvent("Dashboard", "Go to Dashboard Layout", 0, this);
      this.$router.replace({ name: "dashboard-layout" });
    },
    saveUserSettingCallback: function (response) {
      UserSettingsService.saveAllUserSettings(response.data.data.userSettings);
    },    
    logChangeEventForStartTimePeriod(){
      let periodName = ClientTimePeriodService.getTimePeriodName(this.client_time_periods, this.startTimePeriod);
      if (periodName != "") {
        UserSettingsService.saveUserSetting("dashboard-start-period", periodName, 0, this.saveUserSettingCallback, this);
      }
      GoogleService.logActionEvent("change", "Dashboard", "", SurveyService.getSurveyName(), "Start Period", this);
    },    
    logChangeEvent(){
      let periodName = ClientTimePeriodService.getTimePeriodName(this.client_time_periods, this.currentTimePeriod);
      if (periodName != "") {
        UserSettingsService.saveUserSetting("dashboard-period", periodName, 0, this.saveUserSettingCallback, this);
      }
      GoogleService.logActionEvent("change", "Dashboard", "", SurveyService.getSurveyName(), "Period", this);
    },   
    logViewDetailsEvent(title){
      GoogleService.logActionEvent("click", "Dashboard", title, SurveyService.getSurveyName(), "View Details", this);
    },
    getClientTimePeriodsBySurveyCodeCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.defaultDashboardValues = response.data.data.defaultDashboardValues;
        this.client_time_periods = response.data.data.clientTimePeriods;
        if (typeof response.data.data.startTimePeriod != "undefined") {
          this.startTimePeriod = String(response.data.data.startTimePeriod);
        }                
        if (typeof response.data.data.currentTimePeriod != "undefined") {
          this.currentTimePeriod = String(response.data.data.currentTimePeriod);
        }
        if (this.currentTimePeriod == ""){
          AdminService.displayErrorMessage({ text: "Please select a time period from the dropdown. If none exist go to Respondent->Waves to create them." });
        } 
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    getDashboardCardsCallback: function (response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.dashboardLayoutCards = response.data.data;
        let currentSequenceNumber = this.dashboardLayoutCards.length;
        while (this.dashboardLayoutCards.length < 6) {
          let cardToAdd = JSON.parse(JSON.stringify(this.defaultGroup));
          currentSequenceNumber += 1;
          cardToAdd.sequenceNumber = currentSequenceNumber;
          this.dashboardLayoutCards.push(cardToAdd);
        }
        this.displayCards = true;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    loadData: async function() {
      await DashboardService.getDashboardCards(UserService.getUserId(),this.getDashboardCardsCallback, this);
      await ClientTimePeriodService.getClientTimePeriodsBySurveyCode(this.entrySurveyCode, this.getClientTimePeriodsBySurveyCodeCallback, this);
      await MetricsService.getMetricGroupBySurveyCode(this.entrySurveyCode, this.getMetricGroupByClientIdCallback, this);
      await ClientCompetitorService.getCompetitors(this.$parent.clientId, this.currentTimePeriod, this.loadCompetitors);
      await SurveyGroupService.getSurveyGroupsByClientId(this.clientObject.clientCode,this.getSurveyGroupsByClientIdCallback, this);
      if (this.displayDashboardCard(this.dashboardLayoutCards,'metric-contributions',false)){
        this.$refs.metricContributions.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'predictive-analytics',false)){
        this.$refs.predictiveAnalytics.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'nps-rank',false)){
        this.$refs.npsRank.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'moat-score-rank',false)){
        this.$refs.moatScoreRank.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'w-score-rank',false)){
        this.$refs.wScoreRank.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'moat-comps',false)){
        this.$refs.moatComps.initialLoadData();
      }
    },

    loadCompetitors(data) {
      this.competitor_options = [];
      for (var i = 0; i < data.length; i++) {
        this.competitor_options.push({ value: data[i].companyId, name: data[i].name });
      }
    },
    getSurveyGroupsByClientIdCallback(response) {
      this.surveyGroupList = [];
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        let surveyGroups = response.data.data;
        this.surveyGroupList =  SurveyGroupService.getSurveyGroupOptions(surveyGroups,"Y",this.$parent.clientId);

      } else if (statusCode == 201) {
        //        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    getMetricGroupByClientIdCallback(response) {
      this.metric_options = [];
      this.metricId = "";
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        MetricsService.setMetricDropdownOptions(response.data.data,this);
      } else if (statusCode == 201) {
        //        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    }
  },
  watch: {
    entrySurveyCode: function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::entrySurveyCode");
      this.metricId = "";
      this.currentTimePeriod = "";
      this.startTimePeriod = "";
      this.loadData();
    },
    startTimePeriod: function(){
      if (this.startTimePeriod == "") {
        return;
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'performance-trends',false)){
        this.$refs.performanceTrends.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'competitive-trends',false)){
        this.$refs.competitiveTrends.initialLoadData();
      }
    },
    currentTimePeriod: async function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::currentTimePeriod");
      if (this.currentTimePeriod != "") {
        try {
          await ClientCompetitorService.getCompetitors(this.$parent.clientId, this.currentTimePeriod, JSON.stringify(this.loadCompetitors));
        } catch (e) {
          AdminService.displayErrorMessage("DashboardCards.currentTimePeriod getCompetitors", e, "admin", this);
        }
      }
    },    
    metric: function() {
      //this.$refs.predictiveAnalytics.initTable();
    }
  }
};
</script>
