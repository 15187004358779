<template>
    <div v-if="isAdmin" class="panel-admin-body">
      <div class="title">{{ title }}</div>
      <div class="container performance-summary">
        <div class="button-container">
          <div class="button-left"></div>
          <div class="button-right">
            <a class="pr-2" href="#" @click="$modal.show('upload-file')">
              <button type="button" class="btn btn-primary">
                <span class="align-text-bottom">Upload Files <font-awesome-icon :icon="['fas', 'upload']"/></span>
              </button>
            </a>
            <a class="pr-2" href="#" @click="$modal.show('download-file')">
              <button type="button" class="btn btn-primary">
                <span class="align-text-bottom">Download Error Files <font-awesome-icon :icon="['fa', 'download']"/></span>
              </button>
            </a>
            <a class="pr-2" href="#" @click="$modal.show('delete-error-file')">
              <button type="button" class="btn btn-primary">
                <span class="align-text-bottom">Delete Error Files <font-awesome-icon :icon="['fa', 'ban']"/></span>
              </button>
            </a>
          </div>
        </div>
        <div>
          <import-type />
          <div class="row pt-3">
            <h4>Imports Log</h4>
          </div>
          <div class="row pt-1">
            <div class="col-2">
              <div class="form-group form-inline">
                <label for="startDate" class="mr-1">Start Date</label>
                <datepicker
                  format="MMM dd yyyy"
                  v-model="startDate"
                  :typeable="true"
                  wrapper-class=""
                  input-class="form-control required w-100 bg-white"
                  name="startDate"
                ></datepicker>
              </div>
            </div>
            <div class="col-2">
              <div class="form-group form-inline ml-2">
                <label for="endDate" class="mr-1">End Date</label>
                <datepicker format="MMM dd yyyy" v-model="endDate" :typeable="true" wrapper-class="" input-class="form-control required w-100 bg-white" name="endDate"></datepicker>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group form-inline ml-2">
                <label for="import_type_name" class="mr-1">Import Type</label>
                <select id="import_type_name" name="import_type_name" class="custom-select required" v-model="importTypeName">
                  <option v-for="(import_type_name_options, idx_import_type_name_options) in this.import_type_name_options" v-bind:value="import_type_name_options.value" v-bind:key="idx_import_type_name_options">
                    {{ import_type_name_options.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group form-inline">
                <label for="status" class="mr-1">Status</label>
                <select id="status" name="status" class="custom-select required" v-model="status">
                  <option v-for="(status_option, idxStatusOptions) in this.status_options" v-bind:value="status_option.value" v-bind:key="idxStatusOptions">
                    {{ status_option.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="pr-2">
              <button type="button" class="btn btn-primary ml-2" id="AddNewContentButton" @click="loadData()">Run</button>
            </div>
            <div class="pr-2">
              <button type="button" class="btn btn-primary ml-2" id="AddNewContentButton" @click="reset()">Reset</button>
            </div>
          </div>
          <div class="row w-75">
            <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
              <thead></thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
      <vue-modal ref="upload-file" name="upload-file" :key="refreshModal" :clickToClose="false" :scrollable="true" :width="400" :height="'auto'" :minHeight="400">
        <div class="upload-files-container" >
          <div class="form-group form-row p-2">
            <h5 class="font-weight-bold">Upload Files</h5>
          </div>
          <div class="form-group form-row p-2 text-success">
            <span :class="modalReturnMessageClass"> {{ modalReturnMessage }}</span>
          </div>
          <div class="form-row form-group form-inline">
            <span class="pr-2">Select Import Type</span>
              <select id="importTypeName" name="importTypeName" class="w-75 custom-select required" v-model="importTypeName">
                <option value="">Select</option>  
                <option v-for="(inputType, idxInputTypeOptions) in this.importTypeList" v-bind:value="String(inputType.name)" v-bind:key="idxInputTypeOptions">
                      {{ inputTypeDisplayNameMapping[inputType.name] }} 
                  </option>
              </select>
          </div>
          <div class="form-group form-row">
            <span class="pr-2">Select file(s) to upload</span>
            <input ref="importFilesUpload" type="file" @change="onFileChange" multiple />
          </div>
          <div class="form-group form-row d-flex justify-content-end">
            <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="this.uploadFiles(importTypeName, selectedFiles)">
              <span>Upload</span>
              <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
            </button>
            <button
              type="button"
              class="btn btn-primary ml-4"
              @click="closeModal('upload-file')">
              Close
            </button>
          </div>
          <div v-if="selectedFiles.length">
            <h4>Selected Files:</h4>
            <ul>
              <li v-for="(file, index) in selectedFiles" :key="index">{{ file.name }}</li>
            </ul>
          </div>
          <div v-if="uploadResults" class="text-success">
            <div v-if="successUploadedFiles.length">
              <h4>Successfully Uploaded Files:</h4>
              <ul>
                <li v-for="(file, index) in successUploadedFiles" :key="index">{{ file }}</li>
              </ul>
            </div>
            <div v-if="errorUploadedFiles.length" class="text-danger">
              <h4>Files Not Uploaded:</h4>
              <ul>
                <li v-for="(file, index) in errorUploadedFiles" :key="index">{{ file }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div><!-- no content here --></div>
      </vue-modal>
      <vue-modal ref="download-file" name="download-file" :clickToClose="false" :width="400" :height="300">
        <div class="upload-files-container" >
          <div class="form-group form-row p-2">
            <h5 class="font-weight-bold">Download Error Files</h5>
          </div>
          <div class="form-group form-row p-2 text-success">
            <span :class="modalDownloadReturnMessageClass"> {{ modalDownloadReturnMessage }}</span>
          </div>
          <div class="form-row form-group form-inline">
            <span class="pr-2">Select Import Type</span>
              <select id="downloadImportTypeName" name="downloadImportTypeName" class="w-75 custom-select required" v-model="downloadImportTypeName">
                <option value="">Select</option>  
                <option v-for="(inputType, idxInputTypeOptions) in this.importTypeList" v-bind:value="String(inputType.name)" v-bind:key="idxInputTypeOptions">
                      {{ inputTypeDisplayNameMapping[inputType.name] }} 
                  </option>
              </select>
          </div>
          <div class="form-group form-row d-flex justify-content-end">
            <button type="button" class="btn btn-primary float-right ml-2" v-on:click="this.downloadFiles(downloadImportTypeName)">
              <span>Download</span>
              <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
            </button>
            <button type="button" class="btn btn-primary ml-4" @click="closeModal('download-file')">Close</button>
          </div>
        </div>
        <div><!-- no content here --></div>
      </vue-modal>
      <vue-modal ref="delete-error-file" name="delete-error-file" :clickToClose="false" :width="400" :height="300">
        <div class="upload-files-container" >
          <div class="form-group form-row p-2">
            <h5 class="font-weight-bold">Delete Error Files</h5>
          </div>
          <div class="form-group form-row p-2 text-success">
            <span :class="modalDeleteReturnMessageClass"> {{ modalDeleteReturnMessage }}</span>
          </div>
          <div class="form-row form-group form-inline">
            <span class="pr-2">Select Import Type</span>
              <select id="deleteImportTypeName" name="deleteImportTypeName" class="w-75 custom-select required" v-model="deleteImportTypeName">
                <option value="">Select</option>  
                <option v-for="(inputType, idxInputTypeOptions) in this.importTypeList" v-bind:value="String(inputType.name)" v-bind:key="idxInputTypeOptions">
                      {{ inputTypeDisplayNameMapping[inputType.name] }} 
                  </option>
              </select>
          </div>
          <div class="form-group form-row d-flex justify-content-end">
            <button type="button" class="btn btn-primary float-right ml-2" v-on:click="this.deleteErrorFiles(deleteImportTypeName)">
              <span>Delete Error Files</span>
              <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
            </button>
            <button type="button" class="btn btn-primary ml-4" @click="closeModal('delete-error-file')">Close</button>
          </div>
        </div>
        <div><!-- no content here --></div>
      </vue-modal>
    </div>
    <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
  </template>
  
  <script>
  import Vue from "vue";
  import $ from "jquery";
  import axios from "axios";
  
  import moment from "moment";
  Vue.prototype.moment = moment;
  import Datepicker from "vuejs-datepicker";
  import ImportType from "./ImportType.vue";
  import AdminMixins from "../../mixins/AdminMixins.vue";
  import DataTableMixins from "../../mixins/DataTableMixins.vue";
  import AdminService from "../../services/admin.service";
  import TableService from "../../services/table.service";
  import AlertService from "../../services/alert.service";
  import authHeader from "../../services/auth-header";
  import ErrorService from "../../services/error.service";
  
  export default {
    name: "Import",
    mixins: [AdminMixins, DataTableMixins],
    components: { ImportType, Datepicker },
    data() {
      return {
        title: "Import",
        table: TableService.getImportLogTableAttributes(),
        messageTable: TableService.getImportTypeAttributes(),
        startDate: new Date(2020, 0, 1),
        endDate: new Date(),
        jobName: "",
        status: 0,
        job_name_options: [],
        status_options: [
          { name: "Select Status", value: "" },
          { name: "Success", value: "C" },
          { name: "Incomplete", value: "I" },
          { name: "Failed", value: "E" }
          
        ],
        import_type_name_options: [],
        importTypeList: [],
        selectedFiles: [],
        uploadResults: {
          type: Boolean,
          default: false
        },
        successUploadedFiles: [],
        errorUploadedFiles: [],
        importTypeName: "",
        downloadImportTypeName: "",
        modalDownloadReturnMessage: "",
        modalDownloadReturnMessageClass: "",
        modalDeleteReturnMessage: "",
        modalDeleteReturnMessageClass: "",
        isImportTypesTableLoaded: {
          type: Boolean,
          default: false
        },
        deleteImportTypeName: "",
        refreshModal: true
      };
    },
    methods: {
      reset() {
        this.startDate = new Date(2020, 0, 1);
        this.endDate = new Date();
        this.status = "";
        this.importTypeName = "";
      },
      async getTableData() {
        this.$root.processServerRequest("Loading table data");
        if(typeof this.importTypeName == "undefined") {
            this.importTypeName = "";
        }
        if(this.status == "0") {
            this.status = "";
        }
        if(this.isImportTypesTableLoaded) {
          AdminService.getImportLogList(this.startDate, this.endDate, this.status, this.importTypeName, this.getImportLogListCallback, this);
        }
      },
      getImportLogListCallback(response) {
        this.$root.processServerResponse();
        this.table.data = response.data.data.importList;
        this.import_type_name_options = response.data.data.importTypeNameOptions;
        this.import_type_list = response.data.data.importTypeList;
        $.extend(this.table.configuration, { columns: this.table.columns });
        $.extend(this.table.configuration, { data: this.table.data });
        this.table.dataTable = $("#" + this.table.id).DataTable(this.table.configuration);
        const vm = this;
        this.table.dataTable
          .on("click", "tr", function(e, dt, type, indexes) {
            vm.table.rowSelected = true;
            var rowData = vm.table.dataTable
              .rows(this)
              .data()
              .toArray();
            $("#" + vm.table.id + " .selected").removeClass("selected");
            $(this).toggleClass("selected");
            if (!$.isEmptyObject(rowData)) {
              vm.table.rowSelectedId = rowData[0].id;
              vm.postRowSelect(rowData[0], true);
            }
          })
          .on("deselect", function(e, dt, type, indexes) {
            vm.table.rowSelected = false;
            vm.table.rowSelectedId = 0;
            if ($(this).hasClass("selected")) {
              $(this).removeClass("selected");
            }
            var rowData = vm.table.dataTable
              .rows(this)
              .data()
              .toArray();
            if (!$.isEmptyObject(rowData)) {
              vm.postRowSelect(rowData[0], false);
            }
          });
        AlertService.closeAlert();
        this.buildResponsiveTable();
      },
      loadData() {
        this.getTableData();
        this.table.configuration.order = [[0, "desc"]];
        this.table.configuration.dom = "frtpB";
        this.table.configuration.responsive = false;
      },
      closeModal: function(modalName) {
        if (this.$refs[modalName] != null) {
          this.$refs[modalName].visible = false;
        }
        this.selectedFiles = [];
        this.uploadResults = false;
        this.importTypeName = "",
        this.downloadImportTypeName = "",
        this.deleteImportTypeName = "",
        this.refreshModal = !this.refreshModal;
      },
      onFileChange(event) {
        const files = event.target.files;
        this.selectedFiles = Array.from(files);
        this.uploadResults = false;
      },
      async uploadFiles(typeName, files) {
        let formData = new FormData();
        formData.append('importTypeName', typeName);
        files.forEach(file => {
          formData.append('importFiles', file);
        });
        let headers = { "Content-Type": "multipart/form-data" };
        $.extend(headers, await authHeader());
        if (typeof typeName === "undefined" || typeName == "" || files.length == 0) {
          this.modalReturnMessage = "Please select Import Type and Files";
          this.modalReturnMessageClass = "text-danger";
          this.setModalLoading(false);
        } else {
          this.$root.processServerRequest("Uploading import files");
          this.modalReturnMessage = "Uploading import files please stand by....";
          this.setModalLoading(true);
          await axios
          .post(this.baseUrl + "/api/v1/admin/upload-import-files", formData, { headers: headers })
          .then(response => this.uploadImportFileCallback(response))
          .catch(error => {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("Import.uploadFiles", error, "admin", this);
          });
        }
      },
      uploadImportFileCallback: function(response) {
        this.$root.processServerResponse();
        this.selectedFiles = [];
        this.modalReturnMessage = "";
        let statusCode = response.status;
        if (statusCode == 200) {
          this.uploadResults = true;
          this.successUploadedFiles = response.data.data.successFiles;
          this.errorUploadedFiles = response.data.data.errorFiles;
        }
        this.setModalLoading(false);
      },
      async downloadFiles(typeName) {
        let formData = new FormData();
        formData.append('importTypeName', typeName);
      
        if (typeof typeName === "undefined" || typeName == "") {
          this.modalDownloadReturnMessage = "Please select Import Type";
          this.modalDownloadReturnMessageClass = "text-danger";
          this.setModalLoading(false);
        } else {
          this.modalDownloadReturnMessage = "";
          this.$root.processServerRequest("Downloading Error files");
          
          try {
            const response = await axios.post(this.baseUrl + "/api/v1/admin/download-auto-import-error-files", formData, {
                responseType: 'blob'
            });
            const blob = response.data;
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', typeName + "-error-files.zip");  // Set the default filename
            document.body.appendChild(link);
            link.click();
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Download Files Error:', error);
            this.modalDownloadReturnMessage = "Error occurred while downloading.";
            this.modalDownloadReturnMessageClass = "text-danger";
          } finally {
            this.setModalLoading(false);
            this.closeModal('download-file');
            this.$root.setLoading(false);
          } 
        }
      },
      async deleteErrorFiles(typeName) {
        let formData = new FormData();
        formData.append('importTypeName', typeName);
      
        if (typeof typeName === "undefined" || typeName == "") {
          this.modalDeleteReturnMessage = "Please select Import Type";
          this.modalDeleteReturnMessageClass = "text-danger";
          this.setModalLoading(false);
        } else {
          this.modalDeleteReturnMessage = "";
          this.$root.processServerRequest("Deleting Error files");
          this.setModalLoading(true);
          try {
            await axios.post(this.baseUrl + "/api/v1/admin/delete-auto-import-error-files", formData, {
            });
          } catch (error) {
            this.modalDownloadReturnMessage = "Error occurred while deleting error files.";
            this.modalDownloadReturnMessageClass = "text-danger";
          } finally {
            this.modalDownloadReturnMessage = "Successfully deleted error files.";
            this.modalDownloadReturnMessageClass = "text-success";
            this.setModalLoading(false);
            this.$root.setLoading(false);
            this.$router.go(0);
          } 
        }
      },
    },
    computed: {
      inputTypeDisplayNameMapping() {
        return {
          'respondent': 'Respondent',
          'dashboard-card': 'Dashboard Card',
          'metric': 'Metric',
          'metric-group': 'Metric Group'
        };
      }
    },
    watch: {
      isImportTypesTableLoaded: function() {
        if(this.isAdmin && this.$data.isImportTypesTableLoaded) {
          this.loadData();
        }
      }
    },
    props: {},
    mounted() {}
  };
  </script>
  